@font-face {
  font-family: 'Circe';
  src: url('/assets/files/fonts/Circe-Regular.eot');
  src: url('/assets/files/fonts/Circe-Regular.eot') format('embedded-opentype'),
  url('/assets/files/fonts/Circe-Regular.woff') format('woff'),
  url('/assets/files/fonts/Circe-Regular.ttf') format('truetype'),
  url('/assets/files/fonts/Circe-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/assets/files/fonts/Circe-Light.eot');
  src: url('/assets/files/fonts/Circe-Light.eot') format('embedded-opentype'),
  url('/assets/files/fonts/Circe-Light.woff') format('woff'),
  url('/assets/files/fonts/Circe-Light.ttf') format('truetype'),
  url('/assets/files/fonts/Circe-Light.svg') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/assets/files/fonts/Circe-Bold.eot');
  src: url('/assets/files/fonts/Circe-Bold.eot') format('embedded-opentype'),
  url('/assets/files/fonts/Circe-Bold.woff') format('woff'),
  url('/assets/files/fonts/Circe-Bold.ttf') format('truetype'),
  url('/assets/files/fonts/Circe-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('/assets/files/fonts/OpenSans-Regular.eot');
  src: url('/assets/files/fonts/OpenSans-Regular.eot') format('embedded-opentype'),
  url('/assets/files/fonts/OpenSans-Regular.woff') format('woff'),
  url('/assets/files/fonts/OpenSans-Regular.ttf') format('truetype'),
  url('/assets/files/fonts/OpenSans-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
