// ЭКСТРЕННЫЙ ПОПАП С ТЕКСТОМ

.popup-fade:before {
	content: '';
	background: #000;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0.7;
	z-index: 9999;
}
.popup {
	position: fixed;
	top: 20%;
	left: 50%;
	padding: 2rem;
	width: 85rem;
	background: #fff;
	transform: translateX(-50%);
	z-index: 99999;
	opacity: 1;

	h1 {
		font-weight: 500;
	}
	p {
		font-size: 1.9em;
		font-weight: 300;
		line-height: 1.6;
		color: #343738;
	}
}
.popup-close {
	position: absolute;
	top: 10px;
	right: 10px;
}


/* ---------------------- Construction-video --------------------- */

.construction-video_frame .pade__header{
	background-color: #f2f7fb;
}

.construction-video_frame .construction__item-title {
	display: flex;
	align-items: center;
	font-size: 2em;
	width: 100%;
	height: 4em;
	text-align: center;
	color: rgba(255,255,255, 0.8);
	background-color: #343637;
}

.construction-video_frame .construction__item-title span{
	padding-left: 4rem;
}

.construction-video_frame .construction__item {
	position: relative;
	margin-right: 30.5%;
	cursor: pointer;
	box-shadow: 0px 16px 45px 15px rgba(52, 54, 55, 0.2);
}

.construction__item:hover:before{
	transform: scale(0.8);
}
.construction__item:before {
	content: '';
    position: absolute;
    top: 40%;
	right: 50%;
	border-radius: 50%;
    z-index: 1;
	width: 11em;
    height: 11em;
    margin: -3.5em -5.8em 0 0;
	background-color:#1aa0e0;
	transition: 0.4s ease-in-out;
}
.construction__item:after {
	content: '';
    position: absolute;
    top: 40%;
	right: 50%;
	margin: 1.5em -1.9em;
	z-index: 2;
	width: 2em;
	height: 2em;
	background: url(/assets/i/play.svg);
	background-repeat: no-repeat;
	font-size: 1em;
}
.construction__item-image {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.construction__item-image img {
	width: 100%;
	height: 100%;
	transition: 0.4s all ease-in-out;
}
.construction__item-image:hover img {
	-webkit-transform: scale(1.1);
 	-moz-transform: scale(1.1);
 	-o-transform: scale(1.1);
}
.construction-video_frame .v-align {
	overflow: hidden;
}
.construction-video_frame .carousel_dots_frame{
	left: 50%;
    transform: translateX(-67%);
	bottom: 10.5%;
	width: 20%;
	background-color: unset;
}
.construction-video_frame .carousel_dots:after {
	background-color: #c1c5c9;
	width: 1em;
	height: 1em;
}
.construction-video_frame .carousel_dots {
	margin-right: 4.5em;
}

.construction-video_frame .carousel_frame {
	position: relative;
    height: 100%;
    margin: 0 5em 0 0;
}
.construction-video_frame .carousel_dots:hover:after, .construction-video_frame .carousel_dots.active:after {
	opacity: 1;
	background-color: #1aa0e0;
}
.construction-video_frame .carousel_arrow {
	right: -12.2em;
}
.construction-video_frame .carousel_over.video_interv {
	/* margin-bottom: -2.4em;
	overflow: hidden; */
	margin-bottom: 19.6em;
    padding-top: 12%;
    padding-left: 14.5em;
    margin-right: -4em;
    height: 100%;
}
.construction-video_frame .carousel_line {
	font-size: 1em;
}
.construction__carousel-frame {
	background-color: #fff;
}
.construction__content-frame {
    bottom: 0;
    left: 7.2em;
    position: absolute;
    right: 7.2em;
    top: 10em;
}
.construction__content_image {
    /* height: 100%; */
    background-color: #e4e9ed;
}
.construction-video .video_popup {
	background: rgba(0,0,0,0.8);
}




/* ------------------------------------------------------ */

*, a:focus {
	outline: none;
}
img {
	display:block;
}
a {
	color: inherit;
}
a:hover {
	text-decoration: none;
}
h1, h2, h3 {
	margin: 0;
	padding: 0;
	font-weight: normal;
	line-height: 110%;
}
ul, ol {
	padding-left: 20px;
}
.textScroll__size>*:first-child {
	margin-top: 0;
}
html {
	width: 100%;
	height: 100%;
	overflow: hidden;
	font-size: 0;
}
body {
	position: relative;
	width: 100%;
	height: 100%;
	background: #ffffff;
	font-family: 'Circe', Helvetica, Arial, sans-serif;
	font-weight: 200;
	color: #000;
}
.test {
	border: 1px solid #F00;
}
.upper {
	text-transform: uppercase;
}
.z-1 {
	z-index: 1;
}
.events-none {
	pointer-events: none;
}
.tdu {
	text-decoration: underline;
	cursor: pointer;
}
.tdu:hover {
	text-decoration: none;
}
.css_ani {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.css_opacity {
    -webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
    -moz-transition: opacity 0.3s ease, visibility 0.3s ease;
    -ms-transition: opacity 0.3s ease, visibility 0.3s ease;
    -o-transition: opacity 0.3s ease, visibility 0.3s ease;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}
html.svg .svg-bg {
	background-image: url('../i/sprite.svg') !important;
}
#body_frame, #centrer1, #centrer2, .load_frame, .scroll_frame, .bg_video, #preloader_frame, .pano_place.off:after, .map_place, .popup_overlay, .floor_frame, .floor_map, .floor_map_cont, .apart_img, .korp_det_val, .div_100 {
	position:absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}
#body_frame, .load_frame {
	overflow: hidden;
}

.load_frame {
	background-color: #ffffff;
}

.bg_video {
	display: none;
}

.bg_video {
	width: 100% !important;
	height: 100% !important;
}

#preloader_frame, #svg_preloader_frame {
	z-index: 10000;
	background: url('../i/blank.gif');
	display: none;
}

.preloader_img, .search_preloader_circle {
	position: absolute;
	width: 40px;
	height: 40px;
	padding: 5px;
	margin: -25px;
	top: 50%;
	left: 50%;
	border-radius: 3px;
	background-color: #ffffff;
}

.popup_overlay, .rotate_help_frame {
	background: rgba(0,0,0,0.8);
}

.favorites_popup {
	background-color: rgba(0, 0, 0, 0.05);
}

.commercial_concept_popup{
	background-color: rgba(0, 0, 0, 0.5);
}

.gallery_popup,
.video_popup{
	background-color: #ffffff;
}

.gallery_popup_wrapper{
	position: absolute;
	top: 7.2em;
	right: 7.2em;
	bottom: 0;
	left: 7.2em;
}

.gallery_popup_wrapper .g_btn.left {
	background-color: #27292a;
	left: auto;
	right: 0;
}

.popup_overlay {
	display: none;
	z-index: 8010;
}

.v-align {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    white-space: nowrap;
}

.v-align>div, .text_scroll.center .textScroll__size {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
    white-space: normal;
}

.v-align:before, .text_scroll.center .textScroll__content:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
}

.text_scroll.visible .textScroll__frame {
	overflow: visible;
}

.page404_frame {
	background: #fff;
}

.page404_frame h1 {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	margin-top: -1em;
	font-size: 2.5em;
	line-height: 1;
	color: #000;
}

.rotate_help_frame {
	z-index: 50000;
	cursor: pointer;
	display: none;
}

.rotate_help {
	width: 80%;
	margin: 0 auto;
}

.share__container{
	bottom: 13.3em;
	position: absolute;
	right: 3.6em;
	-webkit-transform: translate(50%, 0);
	-moz-transform: translate(50%, 0);
	-ms-transform: translate(50%, 0);
	-o-transform: translate(50%, 0);
	transform: translate(50%, 0);
	-webkit-transition: -webkit-transform 300ms ease;
	-moz-transition: -moz-transform 300ms ease;
	-ms-transition: -ms-transform 300ms ease;
	-o-transition: -o-transform 300ms ease;
	transition: transform 300ms ease;
	z-index: 2;
}

body.search .share__container,
body.infrastructure .share__container,
body.plans .share__container{
	-webkit-transform: translate(10em, -50%);
	-moz-transform: translate(10em, -50%);
	-ms-transform: translate(10em, -50%);
	-o-transform: translate(10em, -50%);
	transform: translate(10em, -50%);
}

.share-buttons-list{
	display: block;
	margin: 0;
	padding: 0;
	width: 2.6em;
}

.share-button{
	background: url(../i/sprite.svg) no-repeat;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	cursor: pointer;
	display: block;
	height: 2.6em;
	margin: 1em 0;
	opacity: 0.32;
	position: relative;
	-webkit-transition: opacity 300ms linear;
	-moz-transition: opacity 300ms linear;
	-ms-transition: opacity 300ms linear;
	-o-transition: opacity 300ms linear;
	transition: opacity 300ms linear;
	width: 2.6em;
}

.share-facebook{background-position: -22.1em -11.5em;}
.share-vkontakte{background-position: -22.1em -14.4em;}
.share-twitter{background-position: -22.1em -17.3em;}
.share-instagram{background-position: -22.2em -20.3em;}

.share__container.dark .share-facebook{background-position: -25em -11.5em;}
.share__container.dark .share-vkontakte{background-position: -25em -14.4em;}
.share__container.dark .share-twitter{background-position: -25em -17.3em;}
.share__container.dark .share-instagram{background-position: -25.1em -20.3em;}

.share__container.dark .share-facebook:hover{background-position: -27.9em -11.5em;}
.share__container.dark .share-vkontakte:hover{background-position: -27.9em -14.4em;}
.share__container.dark .share-twitter:hover{background-position: -27.9em -17.3em;}
.share__container.dark .share-instagram:hover{background-position: -28.0em -20.3em;}

.share-button:hover{opacity: 1;}

/* --------------------------------------------------------- */


/* ------------------------ Logo --------------------------- */

.logo {
	background-color: #009fe3;
	height: 23.1em;
	left: 7.2em;
	position: absolute;
	top: 2.4em;
	-webkit-transition: background-color .3s, opacity .7s ease-in-out .5s, -webkit-transform .7s ease-in-out .5s;
	-moz-transition: background-color .3s, opacity .7s ease-in-out .5s, -moz-transform .7s ease-in-out .5s;
	-ms-transition: background-color .3s, opacity .7s ease-in-out .5s, -ms-transform .7s ease-in-out .5s;
	-o-transition: background-color .3s, opacity .7s ease-in-out .5s, -o-transform .7s ease-in-out .5s;
	transition: background-color .3s, opacity .7s ease-in-out .5s, transform .7s ease-in-out .5s;
	width: 22.8em;
	z-index: 22;
}

.logo:before{
	background-color: #000;
	content: "";
	display: block;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	-webkit-transition: opacity 300ms linear;
	-moz-transition: opacity 300ms linear;
	-ms-transition: opacity 300ms linear;
	-o-transition: opacity 300ms linear;
	transition: opacity 300ms linear;
	width: 100%;
}

.logo:hover:before{
	opacity: 0.2;
}

.logo.hidden, .header.hidden {
	opacity: 0;
	-webkit-transform: translateX(-22.8em);
	-moz-transform: translateX(-22.8em);
	-ms-transform: translateX(-22.8em);
	-o-transform: translateX(-22.8em);
	transform: translateX(-22.8em);
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none;
}

.logo:after {
	content: '';
	position: absolute;
	width: 16.3em;
	height: 11.1em;
	top: 50%;
	left: 50%;
	margin: -5.55em 0 0 -8.15em;
	background: url('../i/sprite.svg') -0.1em -0.1em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}

/* --------------------------------------------------------- */


/* ----------------------- Header -------------------------- */

.header {
	position: absolute;
	width: 22.8em;
	height: 22.8em;
	top: 25.5em;
	left: 7.2em;
	z-index: 22;
	background-color: #27292a;
	-webkit-transition: opacity .7s ease-in-out .6s, -webkit-transform .7s ease-in-out .6s;
	-moz-transition: opacity .7s ease-in-out .6s, -moz-transform .7s ease-in-out .6s;
	-ms-transition: opacity .7s ease-in-out .6s, -ms-transform .7s ease-in-out .6s;
	-o-transition: opacity .7s ease-in-out .6s, -o-transform .7s ease-in-out .6s;
	transition: opacity .7s ease-in-out .6s, transform .7s ease-in-out .6s;
}

.header__content {
	position: absolute;
	right: 3.6em;
	bottom: 3.2em;
	left: 3.6em;
}

.header__address {
	color: #cad3d8;
	font-size: 1.6em;
	line-height: 1.1;
	font-weight: 200;
}

.header__address > span {
	color: #fff;
	display: inline-block;
	font-weight: bold;
	margin-bottom: 0.5em;
	text-transform: uppercase;
}

.header__afi{
	background: url(/assets/i/sprite.svg) -31.3em -18.4em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	cursor: pointer;
	display: block;
	font-size: 0.58em;
	left: 50%;
	position: relative;
	height: 4.5em;
	margin-left: -14.75em;
	top: 4.5em;
	width: 29.5em;
}

.header__contacts{
	position: absolute;
	right: 7.2em;
	top: 1.8em;
	z-index: 25;
}

.header__phone {
	color: #27292a;
	display: block;
	font-size: 3em;
	font-weight: normal;
	letter-spacing: -0.02em;
	line-height: 1;
	text-decoration: none;
	white-space: nowrap;
}

.header__phone strong{
	font-weight: normal;
}

.header__callback-button {
	color: #10b7fe;
	cursor: pointer;
	font-size: 1.3em;
	font-weight: bold;
	display: inline-block;
	position: absolute;
	right: 0;
	/*text-decoration: underline;*/
	text-transform: uppercase;
	top: 2.5em;
}

/*.header__callback-button:hover {*/
	/*text-decoration: none;*/
/*}*/

/* --------------------------------------------------------- */


/* ------------------------ Menu --------------------------- */

.menu__frame {
	position: absolute;
	width: 100%;
	/*height: 8.3em;*/
	height: 10em;
	top: 0;
	left: 0;
	z-index: 21;
	background-color: #FFFFFF;
	font-family: 'Circe', Helvetica, Arial, sans-serif;
}

.menu__list {
	position: absolute;
	top: 0;
	/*right: 35.7em;*/
	right: 28em;
	bottom: 0;
	left: 30em;
	text-align: center;
}

.menu__item {
	display: inline-block;
	position: relative;
	height: 100%;
}

.menu__link {
	display: block;
	height: 6.3em;
	padding: 0 3.3em;
	text-decoration: none;
	white-space: nowrap;
	color: #6d777b;
	-webkit-transition: color .2s;
	-moz-transition: color .2s;
	-ms-transition: color .2s;
	-o-transition: color .2s;
	transition: color .2s;
}

.menu__link:hover, .menu__link.active {
	color: #009fe3;
}

.menu__link:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

/*
.menu__link:after {
	content: '';
	position: absolute;
	height: 0.2em;
	top: 100%;
	right: 3.3em;
	left: 3.3em;
	margin-top: -0.1em;
	opacity: 0;
	background-color: #009fe3;
	-webkit-transition: opacity .1s;
	-moz-transition: opacity .1s;
	-ms-transition: opacity .1s;
	-o-transition: opacity .1s;
	transition: opacity .1s;
}

.menu__item:hover .menu__link:after {
	opacity: 1;
}
*/

.menu__link > span {
	display: inline-block;
	position: relative;
	margin-top: 1.5em;
	vertical-align: middle;
	font-size: 1.3em;
	font-weight: bold;
	text-transform: uppercase;
}

/* .menu__submenu {
	visibility: hidden;
	position: absolute;
	bottom: 0;
	left: 2.5em;
	z-index: 1;
	padding: 0 1em;
	opacity: 0;
	background-color: #4cbceb;
	white-space: nowrap;
	-webkit-transition: visibility .1s, opacity .1s;
	-moz-transition: visibility .1s, opacity .1s;
	-ms-transition: visibility .1s, opacity .1s;
	-o-transition: visibility .1s, opacity .1s;
	transition: visibility .1s, opacity .1s;
}

.menu__item:hover .menu__submenu {
	visibility: visible;
	opacity: 1;
}

.menu__submenu-item {
	display: inline-block;
	position: relative;
	height: 4.2em;
	padding: 0 1.45em;
	vertical-align: middle;
	cursor: pointer;
	color: #ffffff;
	-webkit-transition: color .2s;
	-moz-transition: color .2s;
	-ms-transition: color .2s;
	-o-transition: color .2s;
	transition: color .2s;
}

.menu__submenu-item:hover, .menu__submenu-item.active {
	color: rgba(255, 255, 255, 0.7);
}

.menu__submenu-item:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.menu__submenu-item > span {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	font-size: 1.3em;
}

.menu__submenu-item > span.menu_new:after {
	content: 'New';
	position: absolute;
	bottom: 100%;
	left: 100%;
	padding: 0.15em 0.5em 0;
	margin: 0 0 -0.3em -0.5em;
	background-color: #ffffff;
	font-size: 0.6em;
	text-transform: uppercase;
	color: #009fe3;
} */



.menu__submenu {
	visibility: hidden;
	position: absolute;
	top: 100%;
	left: -2.2em;
	padding: 1em 5.5em 5em;
	opacity: 0;
	background-color: #FFFFFF;
	text-align: left;
	-webkit-transition: visibility .1s, opacity .1s;
	-moz-transition: visibility .1s, opacity .1s;
	-ms-transition: visibility .1s, opacity .1s;
	-o-transition: visibility .1s, opacity .1s;
	transition: visibility .1s, opacity .1s;
}

.menu__item:hover .menu__submenu {
	visibility: visible;
	opacity: 1;
}

.menu__submenu-item {
	display: block;
	position: relative;
	height: 7em;
	cursor: pointer;
	text-decoration: none;
	white-space: nowrap;
	color: #6d777b;
	-webkit-transition: color .2s;
	-moz-transition: color .2s;
	-ms-transition: color .2s;
	-o-transition: color .2s;
	transition: color .2s;
}

.menu__submenu-item:hover, .menu__submenu-item.active {
	color: #009fe3;
}

.menu__submenu-item:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.menu__submenu-item:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 1px;
	top: 100%;
	left: 0;
	background-color: #e2e4ec;
}

.menu__submenu-item > span {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	font-size: 1.8em;
	font-weight: normal;
}

.menu_new:before {
	content: 'New';
	position: absolute;
	width: 7rem;
	top: 50%;
	right: 100%;
	margin-right: -2.5em;
	padding: 0.5em 0;
	font-size: 1.2rem;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	background: #009fe3;
	color: #d3f2ff;
	-webkit-transform: translate(-50%, -50%) rotate(-90deg);
	-moz-transform: translate(-50%, -50%) rotate(-90deg);
	-ms-transform: translate(-50%, -50%) rotate(-90deg);
	-o-transform: translate(-50%, -50%) rotate(-90deg);
	transform: translate(-50%, -50%) rotate(-90deg);
}



@media only screen and (max-width: 1650px) {

	.menu__link {
		padding: 0 2.3em;
	}

	.menu__submenu {
		left: -3em;
	}

}

@media only screen and (max-width: 1400px) {

	.menu__link {
		padding: 0 1.5em;
	}

	.menu__submenu {
		left: -3.8em;
	}

}

/*
.menu__frame {
	background-color: #ffffff;
	left: 0;
	max-height: 8.3em;
	overflow: hidden;
	position: absolute;
	top: 0;
	-webkit-transition: max-height 700ms ease-in-out;
	-moz-transition: max-height 700ms ease-in-out;
	-ms-transition: max-height 700ms ease-in-out;
	-o-transition: max-height 700ms ease-in-out;
	transition: max-height 700ms ease-in-out;
	width: 100%;
	z-index: 21;
}

.menu__frame.hover{
	max-height: 100%;
}

.menu__center{
	display: block;
	margin: 0 35.7em 0 20%;
	padding-bottom: 10em;
	position: relative;
	vertical-align: top;
}

.menu__list{
	line-height: 0;
	text-align: justify;
}

.menu__mark{
	position: relative;
	top:-1.2em;
	left:-1em;
	display: inline-block;
	width:2.8em;
	height:2.8em;
	line-height: 2.8em;
	text-align:center;
	vertical-align: middle;
	color:#18A0E1;
}
.menu__mark:before{
	position: absolute;
	top:-0.15em;
	right:0.05em;
	width:2.9em;
	height:2.9em;
	background: url('../i/sprite.svg') -94.6em -0.1em;
	background-size: 100em;
	content:'';
}
.menu__mark span{
	font-size:1.4em;
	vertical-align: middle;
}

.menu__line{
	background-color: #009fe3;
	content: "";
	display: block;
	height: 0.5em;
	left: 0;
	position: absolute;
	top: 8.3em;
	-webkit-transition: width 1000ms linear;
	-moz-transition: width 1000ms linear;
	-ms-transition: width 1000ms linear;
	-o-transition: width 1000ms linear;
	transition: width 1000ms linear;
	-webkit-transition-delay: 500ms;
	-moz-transition-delay: 500ms;
	-ms-transition-delay: 500ms;
	-o-transition-delay: 500ms;
	transition-delay: 500ms;
	width: 0;
}

.menu__frame.hover .menu__line{
	width: 100%;
}

.menu__list:after{
	content: "";
	display: inline-block;
	height: 0;
	width: 100%;
}

.menu__col{
	display: inline-block;
	line-height: normal;
	position: relative;
	vertical-align: top;
	text-align: left;
}

.menu__submenu{
	display: block;
	opacity: 0;
	-webkit-transform: translateY(7em);
	-moz-transform: translateY(7em);
	-ms-transform: translateY(7em);
	-o-transform: translateY(7em);
	transform: translateY(7em);
	-webkit-transition: opacity 100ms 300ms, -webkit-transform 100ms ease-in-out 300ms;
	-moz-transition: opacity 100ms 300ms, -moz-transform 100ms ease-in-out 300ms;
	-ms-transition: opacity 100ms 300ms, -ms-transform 100ms ease-in-out 300ms;
	-o-transition: opacity 100ms 300ms, -o-transform 100ms ease-in-out 300ms;
	transition: opacity 100ms 300ms, transform 100ms ease-in-out 300ms;
}

.menu__frame.hover .menu__submenu {
	opacity: 1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: opacity 1500ms 300ms, -webkit-transform 600ms ease-in-out 300ms;
	-moz-transition: opacity 1500ms 300ms, -moz-transform 600ms ease-in-out 300ms;
	-ms-transition: opacity 1500ms 300ms, -ms-transform 600ms ease-in-out 300ms;
	-o-transition: opacity 1500ms 300ms, -o-transform 600ms ease-in-out 300ms;
	transition: opacity 1500ms 300ms, transform 600ms ease-in-out 300ms;
}

.menu__frame.hover .menu__submenu.n1 {
	-webkit-transition-delay: 500ms;
	-moz-transition-delay: 500ms;
	-ms-transition-delay: 500ms;
	-o-transition-delay: 500ms;
	transition-delay: 500ms;
}

.menu__frame.hover .menu__submenu.n2 {
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.menu__frame.hover .menu__submenu.n3 {
	-webkit-transition-delay: 700ms;
	-moz-transition-delay: 700ms;
	-ms-transition-delay: 700ms;
	-o-transition-delay: 700ms;
	transition-delay: 700ms;
}

.menu__frame.hover .menu__submenu.n4 {
	-webkit-transition-delay: 800ms;
	-moz-transition-delay: 800ms;
	-ms-transition-delay: 800ms;
	-o-transition-delay: 800ms;
	transition-delay: 800ms;
}

.menu__frame.hover .menu__submenu.n5 {
	-webkit-transition-delay: 900ms;
	-moz-transition-delay: 900ms;
	-ms-transition-delay: 900ms;
	-o-transition-delay: 900ms;
	transition-delay: 900ms;
}

.menu__frame.hover .menu__submenu.n6 {
	-webkit-transition-delay: 1000ms;
	-moz-transition-delay: 1000ms;
	-ms-transition-delay: 1000ms;
	-o-transition-delay: 1000ms;
	transition-delay: 1000ms;
}

.menu__item{
	color: #6d777b;
	display: inline-block;
	font-size: 1.3em;
	font-weight: bold;
	line-height: 6.38em;
	padding: 0 1em;
	text-decoration: none;
	text-transform: uppercase;
}

.menu__item:hover {
	color: #009fe3;
}

.menu__submenu {
	padding-top: 0.5em;
	position: relative;
}

.menu__submenu-item {
	color: #4d6a76;
	display: block;
	min-width: 10.6em;
	padding: 0 1.3em;
	position: relative;
	text-decoration: none;
	white-space: nowrap;
}

.menu__submenu-item:after{
	content: "";
	display: inline-block;
	height: 8.2em;
	vertical-align: middle;
	width: 0;
}

.menu__submenu-item span{
	display: inline-block;
	font-size: 1.7em;
	line-height: 1.3;
	-webkit-transition: -webkit-transform 300ms ease, color 300ms linear;
	-moz-transition: -moz-transform 300ms ease, color 300ms linear;
	-ms-transition: -ms-transform 300ms ease, color 300ms linear;
	-o-transition: -o-transform 300ms ease, color 300ms linear;
	transition: transform 300ms ease, color 300ms linear;
	vertical-align: middle;
}

.menu__submenu-item:hover span{
	color: #009fe3;
	-webkit-transform: translate(1em, 0);
	-moz-transform: translate(1em, 0);
	-ms-transform: translate(1em, 0);
	-o-transform: translate(1em, 0);
	transform: translate(1em, 0);
}

.menu__submenu-item + .menu__submenu-item:before{
	background-color: rgba(0, 0, 0, 0.12);
	content: "";
	display: block;
	height: 1px;
	left: 0;
	position: absolute;
	top: -1px;
	width: 13.2em;
}

.menu__footer{
	margin: 7em -2em 0 0;
	position: relative;
	width: auto;
}

.menu__footer:after{
	clear: both;
	content: "";
	display: block;
	height: 0;
	width: 100%;
}

.menu__footer-cell{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: left;
	opacity: 0;
	padding-right: 2em;
	position: relative;
	-webkit-transform: translate(0, 7em);
	-moz-transform: translate(0, 7em);
	-ms-transform: translate(0, 7em);
	-o-transform: translate(0, 7em);
	transform: translate(0, 7em);
	-webkit-transition: opacity 100ms 300ms, -webkit-transform 100ms ease-in-out 300ms;
	-moz-transition: opacity 100ms 300ms, -moz-transform 100ms ease-in-out 300ms;
	-ms-transition: opacity 100ms 300ms, -ms-transform 100ms ease-in-out 300ms;
	-o-transition: opacity 100ms 300ms, -o-transform 100ms ease-in-out 300ms;
	transition: opacity 100ms 300ms, transform 100ms ease-in-out 300ms;
	width: 33.33%;
}

.menu__frame.hover .menu__footer-cell {
	opacity: 1;
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition: opacity 1500ms 300ms, -webkit-transform 600ms ease-in-out 300ms;
	-moz-transition: opacity 1500ms 300ms, -moz-transform 600ms ease-in-out 300ms;
	-ms-transition: opacity 1500ms 300ms, -ms-transform 600ms ease-in-out 300ms;
	-o-transition: opacity 1500ms 300ms, -o-transform 600ms ease-in-out 300ms;
	transition: opacity 1500ms 300ms, transform 600ms ease-in-out 300ms;
}

.menu__frame.hover .menu__footer-cell.n1 {
	-webkit-transition-delay: 750ms;
	-moz-transition-delay: 750ms;
	-ms-transition-delay: 750ms;
	-o-transition-delay: 750ms;
	transition-delay: 750ms;
}


.menu__frame.hover .menu__footer-cell.n2 {
	-webkit-transition-delay: 1000ms;
	-moz-transition-delay: 1000ms;
	-ms-transition-delay: 1000ms;
	-o-transition-delay: 1000ms;
	transition-delay: 1000ms;
}

.menu__frame.hover .menu__footer-cell.n3 {
	-webkit-transition-delay: 1250ms;
	-moz-transition-delay: 1250ms;
	-ms-transition-delay: 1250ms;
	-o-transition-delay: 1250ms;
	transition-delay: 1250ms;
}

.menu__footer-btn{
	background: url(/assets/i/blank.gif) no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	color: #fff;
	display: block;
	height: 19em;
	left: 0;
	overflow: hidden;
	padding: 2.7em 3.5em;
	position: relative;
	text-decoration: none;
	top: 0;
	width: 100%;
}

.menu__footer-btn.n0{background-image: url(/assets/images/menu/1.jpg);}
.menu__footer-btn.n1{background-image: url(/assets/images/menu/2.jpg);}
.menu__footer-btn.n2{background-image: url(/assets/images/menu/3.jpg);}

.menu__footer-btn:before{
	background-color: #009fe3;
	content: "";
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	opacity: 1;
	top: 0;
	-webkit-transition: opacity 400ms linear;
	-moz-transition: opacity 400ms linear;
	-ms-transition: opacity 400ms linear;
	-o-transition: opacity 400ms linear;
	transition: opacity 400ms linear;
	width: 100%;
}

.menu__footer-btn:hover:before{
	opacity: 0;
}

.menu__footer-btn span{
	display: inline-block;
	font-size: 2.4em;
	font-weight: bold;
	line-height: 1.2;
	position: relative;
	text-transform: uppercase;
	-webkit-transition: -webkit-transform 500ms ease-in-out;
	-moz-transition: -moz-transform 500ms ease-in-out;
	-ms-transition: -ms-transform 500ms ease-in-out;
	-o-transition: -o-transform 500ms ease-in-out;
	transition: transform 500ms ease-in-out;
}

.menu__footer-btn:hover span{
	-webkit-transform: translate(0, 7em);
	-moz-transform: translate(0, 7em);
	-ms-transform: translate(0, 7em);
	-o-transform: translate(0, 7em);
	transform: translate(0, 7em);
	-webkit-transition-delay: 100ms;
	-moz-transition-delay: 100ms;
	-ms-transition-delay: 100ms;
	-o-transition-delay: 100ms;
	transition-delay: 100ms;
}

.menu__footer-btn:after{
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: bottom;
	width: 0;
}

.menu__sidebar{
	bottom: 10em;
	left: 100%;
	margin-left: 4.5em;
	opacity: 0;
	position: absolute;
	-webkit-transform: translate(7em, 0);
	-moz-transform: translate(7em, 0);
	-ms-transform: translate(7em, 0);
	-o-transform: translate(7em, 0);
	transform: translate(7em, 0);
	-webkit-transition: opacity 100ms 300ms, -webkit-transform 100ms ease-in-out 300ms;
	-moz-transition: opacity 100ms 300ms, -moz-transform 100ms ease-in-out 300ms;
	-ms-transition: opacity 100ms 300ms, -ms-transform 100ms ease-in-out 300ms;
	-o-transition: opacity 100ms 300ms, -o-transform 100ms ease-in-out 300ms;
	transition: opacity 100ms 300ms, transform 100ms ease-in-out 300ms;
	white-space: nowrap;
}

.menu__frame.hover .menu__sidebar{
	opacity: 1;
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition: opacity 600ms linear 1000ms, -webkit-transform 600ms ease-in-out 1000ms;
	-moz-transition: opacity 600ms linear 1000ms, -moz-transform 600ms ease-in-out 1000ms;
	-ms-transition: opacity 600ms linear 1000ms, -ms-transform 600ms ease-in-out 1000ms;
	-o-transition: opacity 600ms linear 1000ms, -o-transform 600ms ease-in-out 1000ms;
	transition: opacity 600ms linear 1000ms, transform 600ms ease-in-out 1000ms;
}

.menu__sidebar-links{
	margin-top: 2.75em;
}

.menu__sidebar-link{
	color: #009fe3;
	cursor: pointer;
	display: inline-block;
	font-weight: 200;
	position: relative;
	text-decoration: none;
}

.menu__sidebar-link span{
	font-size: 1.7em;
	line-height: 1.65;
}

.menu__sidebar-link:after{
	background-color: #009fe3;
	bottom: 0.5em;
	content: "";
	display: block;
	height: 1px;
	left: 0;
	position: absolute;
	-webkit-transition: width 300ms ease;
	-moz-transition: width 300ms ease;
	-ms-transition: width 300ms ease;
	-o-transition: width 300ms ease;
	transition: width 300ms ease;
	width: 100%;
}

.menu__sidebar-link:hover:after{
	width: 0;
}

.menu__sidebar-btn{
	cursor: pointer;
	display: inline-block;
	margin-bottom: 2.5em;
	padding-left: 5.5em;
	position: relative;
}

.menu__sidebar-btn.inactive{opacity: 0.4;}

.menu__sidebar-btn:before{
	background: url(/assets/i/sprite.svg) no-repeat;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	content: "";
	display: block;
	height: 3.5em;
	left: 0;
	margin-top: -1.75em;
	position: absolute;
	top: 50%;
	-webkit-transition: -webkit-transform 300ms ease-in-out;
	-moz-transition: -moz-transform 300ms ease-in-out;
	-ms-transition: -ms-transform 300ms ease-in-out;
	-o-transition: -o-transform 300ms ease-in-out;
	transition: transform 300ms ease-in-out;
	width: 3.5em;
}

.menu__sidebar-btn:hover:before{
	-webkit-transform: translate(1em, 0);
	-moz-transform: translate(1em, 0);
	-ms-transform: translate(1em, 0);
	-o-transform: translate(1em, 0);
	transform: translate(1em, 0);
}

.menu__sidebar-btn.video:before{background-position: -67.4em -7.9em;}
.menu__sidebar-btn.question:before{background-position: -71.4em -7.9em;}
.menu__sidebar-btn.subscribe:before{background-position: -75.4em -7.9em;}

.menu__sidebar-btn span{
	color: #009fe3;
	display: inline-block;
	font-size: 1.7em;
	font-weight: 200;
	line-height: 1;
	vertical-align: middle;
}

.menu__sidebar-btn:after{
	content: "";
	display: inline-block;
	height: 3.5em;
	vertical-align: middle;
	width: 0;
}

.menu__close{
	background-color: #0294d2;
	cursor: pointer;
	height: 7.2em;
	opacity: 0;
	position: absolute;
	right: 7.2em;
	top: 11.7em;
	-webkit-transform: translate(5em, 0);
	-moz-transform: translate(5em, 0);
	-ms-transform: translate(5em, 0);
	-o-transform: translate(5em, 0);
	transform: translate(5em, 0);
	-webkit-transition: -webkit-transform 500ms ease, opacity 500ms linear, visibility 500ms linear;
	-moz-transition: -moz-transform 500ms ease, opacity 500ms linear, visibility 500ms linear;
	-ms-transition: -ms-transform 500ms ease, opacity 500ms linear, visibility 500ms linear;
	-o-transition: -o-transform 500ms ease, opacity 500ms linear, visibility 500ms linear;
	transition: transform 500ms ease, opacity 500ms linear, visibility 500ms linear;
	width: 7.2em;
}

.menu__frame.hover .menu__close{
	opacity :1;
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition-delay: 1000ms;
	-moz-transition-delay: 1000ms;
	-ms-transition-delay: 1000ms;
	-o-transition-delay: 1000ms;
	transition-delay: 1000ms;
}

.menu__close:after{
	background: url(/assets/i/sprite.svg) no-repeat -39.9em -4em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	content: '';
	height: 2.2em;
	left: 50%;
	margin: -1.1em 0 0 -1.1em;
	position: absolute;
	top: 50%;
	width: 2.2em;
}

.menu__footer-icon{
	border: 2px solid red;
	border-radius: 50%;
	display: none;
	left: 3.5em;
	position: absolute;
	top: 0;
}

.menu__footer-icon:before{
	content: "";
	display: block;
	left: 50%;
	position: absolute;
	top: 50%;
}

*/

/* --------------------------------------------------------- */


/* ----------------------- Footer -------------------------- */

.footer {
	bottom: 0;
	left: 0;
	position: absolute;
	width: 7.2em;
	z-index: 10;
}
.footer__online_text {
	width: 45em;
	position: absolute;
	bottom: 0.48em;
	left: 22em;
	color: #fff;
	line-height: 1.1;
	font-size: 1.4em;
}
body.developer .footer__online_text, body.advantages .footer__online_text, body.placement .footer__online_text, body.plans .footer__online_text, body.korpus .footer__online_text, body.commercial .footer__online_text, body.parking .footer__online_text, body.storerooms .footer__online_text, body.mortgage .footer__online_text, body.calculator .footer__online_text, body.contacts .footer__online_text, body.offers .footer__online_text, body.policy .footer__online_text, body.news .footer__online_text, body.media .footer__online_text, body.gallery .footer__online_text, body.mop .footer__online_text, body.gallery-night .footer__online_text, body.views .footer__online_text, body.construction .footer__online_text{
	display: none;
}

.footer__copyright {
	bottom: 0;
	left: -1.3em;
	font-size: 1.4em;
	font-weight: normal;
	line-height: 5.1em;
	position: absolute;
	white-space: nowrap;
	color: #27292a;
	-webkit-transform: translateX(25%) rotate(-90deg);
	-moz-transform: translateX(25%) rotate(-90deg);
	-ms-transform: translateX(25%) rotate(-90deg);
	-o-transform: translateX(25%) rotate(-90deg);
	transform: translateX(25%) rotate(-90deg);
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-ms-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

.footer__afi {
	background: url('/assets/i/sprite.svg') -31.3em -15.1em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	cursor: pointer;
	display: inline-block;
	font-size: 0.7em;
	height: 2.7em;
	margin: -1.2em 0.6em -.5em 0;
	position: relative;
	vertical-align: middle;
	width: 17.4em;
}

.footer__art3d {
	position: absolute;
	width: 3em;
	height: 3em;
	bottom: 0.5em;
	right: 0.5em;
	z-index: 10;
}

.footer__art3d:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -0.5em 0 0 -0.5em;
	border-color: transparent #F24841 transparent transparent;
	border-style: solid;
	border-width: 0 1em 1em 0;
}

/* --------------------------------------------------------- */


/* ----------------------- Preload ------------------------- */

.preload__frame {
	background-color: #009fe3;
	display: none;
	z-index: 100;
}

.preload__frame.visible {
	display: block;
}

.preload__afi {
	position: absolute;
	width: 23.2em;
	height: 3.6em;
	bottom: 13em;
	left: 50%;
	margin-left: 8em;
	opacity: 0;
	background: url('../i/sprite.svg') -31.3em -0.1em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}

.preload__content {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -5em 0 0 8em;
}

.preload__title {
	margin-bottom: 0.4em;
	opacity: 0;
	font-size: 3em;
	font-weight: normal;
	font-variant: small-caps;
	line-height: 1;
	color: #ffffff;
}

.preload__text {
	opacity: 0;
	font-size: 1.8em;
	color: rgba(255, 255, 255, 0.73);
}

/* --------------------------------------------------------- */


/* ----------------------- Common -------------------------- */

.close_btn {
	background-color: #0294d2;
	cursor: pointer;
    height: 5.6em;
    left: 100%;
	position: absolute;
	top: 0;
	-webkit-transition: background-color .3s;
	-moz-transition: background-color .3s;
	-ms-transition: background-color .3s;
	-o-transition: background-color .3s;
	transition: background-color .3s;
	width: 5.6em;
}

.close_btn:hover {
	background-color: #0473a2;
}

.close_btn:after {
	background: url(/assets/i/sprite.svg) -39.9em -4em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
    content: '';
	height: 2.2em;
	left: 50%;
	margin: -1.1em 0 0 -1.1em;
    position: absolute;
    width: 2.2em;
    top: 50%;
}

.gallery_popup .close_btn{
	bottom: 100%;
	height: 7.2em;
	left: 100%;
	width: 7.2em;
	top: auto;
}

.video_popup_center {
	left: 50%;
	position: absolute;
	top: 50%;
}

.blur {
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	-ms-filter: blur(3px);
	-o-filter: blur(3px);
	filter: blur(3px);
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'blur\'><feGaussianBlur stdDeviation='3'/></filter></svg>#blur");
	filter: progid:DXImageTransform.Microsoft.Blur(pixelRadius=3);
}

.white-frame {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 20;
	background-color: #ffffff;
}

.open_feedback {
	cursor: pointer;
}

.page__header{
	left: 30em;
	position: absolute;
	top: 10em;
	z-index: 1;
}

.page__header-block{
	background-color: #f2f7fb;
	padding: 2.7em 6.25em;
	position: relative;
}

.page__header-block.small {
	padding: 2em 6.25em;
}

.home .page__header-title h1{
	color: #fff;
	font-weight: 600;
	font-size: 1.2em;
	text-shadow: 1px 1px 0px #000;
}

.home .page__header-block {
	background-color: inherit;
}

.page__header-title {
	color: #27292a;
	display: block;
	font-weight: normal;
	font-size: 4.6em;
	font-variant: small-caps;
	line-height: 0.85;
}
.picture_title {
	position: absolute;
    top: 0;
	left: 100%;
	width: 100%;
	display: block;
	text-align: center;
	font-size: 1.8em;
	font-weight: normal;
	/* padding: 1rem; */
	padding-top: 2rem;
	padding-bottom: 2rem;
	color: #fff;
	background-color: #364150;
	font-family: 'Circe', Helvetica, Arial, sans-serif;
    font-variant: normal;
    font-weight: 600;
}

.page__header-title h1{
	font-size: 1em;
}

.page__header-block.small .page__header-title {
	font-size: 3.6em;
}

.page__header-title.bold{
	font-weight: bold;
	letter-spacing: -0.04em;
}

.page__header-title.bold h1{
	font-weight: bold;
	letter-spacing: -0.04em;
	font-size: 1em;
}

.page__header-text{
	color: #5b7783;
	font-size: 2.2em;
	line-height: 1.1;
}

.page__header-text p{
	margin: 0.5em 0 0 0;
}

.page__submenu{
	left: 0;
	position: absolute;
	top: 0;
	z-index: 1;
}

.page__submenu.right{
	left: auto;
	right: 0;
}
.page__submenu.left_bottom{
	left: 0;
	top: auto;
	bottom: 5.5em;
}
.page__submenu.left_bottom .page__submenu-item {
	padding: 0 10.35em;
}
.page__submenu.left-bottom .page__submenu-item.commercial {
	padding: 0 6.55em;
}
.opened_floor .page__submenu.left-bottom .page__submenu-item.commercial, .opened_apart .page__submenu.left-bottom .page__submenu-item.commercial {
	display: none;
}
.pdf_icon{
	background: url(/assets/i/pdf.svg) no-repeat;
	background-size: contain;
	display: inline-block;
	height: 2.3em;
	margin: 0 1em 0 -0.75em;
	position: relative;
	vertical-align: middle;
	width: 2.3em;
	top: 50%;
}
.pdf_icon.link {
	margin: 0;
	height: 3.2em;
	top: 0;
	transition: top .3s ease;
}
.pdf_icon.link span {
	display: inline-block;
    font-size: 2em;
    left: 2em;
    position: relative;
    top: .1em;
	color: #fff;
	white-space: nowrap;
}
.pdf_icon.link:hover {
	top: .3em;
	transition: top .3s ease;
}
.page__submenu.left-bottom {
	/* bottom: 5.5em; */
	bottom: 0;
	top: auto;
}
.comm_presentation .about__text-frame.scroll {
	height: 38em;
}

.page__submenu-item {
	position: relative;
	display: inline-block;
	width: auto;
	padding: 0 3em;
	background-color: #dee9f0;
	text-align: center;
	text-decoration: none;
	color: #27292a;
	-webkit-transition: background-color .3s;
	-moz-transition: background-color .3s;
	-ms-transition: background-color .3s;
	-o-transition: background-color .3s;
	transition: background-color .3s;
	vertical-align: top;
	cursor: pointer;

	&.search-link {
		&.hidden {
			display: none;
		}
	}
}

.page__submenu-item:nth-child(odd){background-color: #dee9f0;}
.page__submenu-item:nth-child(even){background-color: #ebf3f9;}
.page__submenu-item.dark{background-color: #27292a;}

.page__submenu-item.active {
	cursor: default;
	background-color: #0294d2;
	/*background-color: #009fe3;*/
}

.page__submenu-item:before{
	background-color: rgb(255, 255, 255);
	content: "";
	display: block;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	-webkit-transition: opacity 300ms linear;
	-moz-transition: opacity 300ms linear;
	-ms-transition: opacity 300ms linear;
	-o-transition: opacity 300ms linear;
	transition: opacity 300ms linear;
	width: 100%;
}

.page__submenu-item:hover:before{
	opacity: 0.5;
}

.page__submenu-item.dark:hover:before{
	opacity: 0.15;
}

.page__submenu-item.active:before{
	opacity: 0 !important;
}

.page__submenu-item > span {
	color: #5b7783;
	display: inline-block;
	font-size: 1.7em;
	line-height: 1;
	position: relative;
	vertical-align: middle;
}

.page__submenu-item > span h1{
	font-size: 1em;
	font-weight: 300;
}

.page__submenu-item.dark > span{color: #fff;}
.page__submenu-item.active > span {color: #ffffff;}

.page__submenu-icon{
	background: url(/assets/i/sprite.svg) no-repeat;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	display: inline-block;
	height: 2.3em;
	margin: 0 1em 0 -0.75em;
	position: relative;
	vertical-align: middle;
	width: 2.3em;
	top: 50%;
}

.page__submenu-item:after{
	content: "";
	display: inline-block;
	height: 5.6em;
	vertical-align: middle;
	width: 0;
}

.page__select{
	background-color: #27292a;
	cursor: pointer;
	height: 5.5em;
	overflow: visible;
	position: relative;
}

.page__select:after{
	border-color: #fff transparent transparent transparent;
	border-style: solid;
	border-width: 0.9em 0.5em 0 0.5em;
	content: "";
	display: block;
	height: 0;
	margin: -0.45em -0.25em 0 0;
	position: absolute;
	right: 3.5em;
	top: 50%;
	-webkit-transition: -webkit-transform 300ms ease;
	-moz-transition: -moz-transform 300ms ease;
	-ms-transition: -ms-transform 300ms ease;
	-o-transition: -o-transform 300ms ease;
	transition: transform 300ms ease;
	width: 0;
}

.page__select:hover:after{
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}

.page__select-active{
	line-height: 5.5em;
}

.page__select-dropdown{
	background-color: #27292a;
	margin-top: -1px;
	max-height: 0;
	overflow: hidden;
	position: relative;
	-webkit-transition: max-height 500ms ease;
	-moz-transition: max-height 500ms ease;
	-ms-transition: max-height 500ms ease;
	-o-transition: max-height 500ms ease;
	transition: max-height 500ms ease;
}

.page__select:hover .page__select-dropdown{
	max-height: 50em;
}

.page__select-option{
	background-color: transparent;
	cursor: pointer;
	display: block;
	-webkit-transition: background 300ms linear;
	-moz-transition: background 300ms linear;
	-ms-transition: background 300ms linear;
	-o-transition: background 300ms linear;
	transition: background 300ms linear;
	white-space: nowrap;
}

.page__select-option:hover{
	background-color: rgba(2, 153, 217, 0.85);
}

.page__select-option.active{
	background-color: #0299d9;
}

.page__select-active:before,
.page__select-option:before{
	content: "";
	display: inline-block;
	vertical-align: middle;
	width: 0;
}

.page__select-active:before{
	height: 5.5em;
}

.page__select-option:before{
	height: 5em;
}

.page__select .label{
	color: #fff;
	display: inline-block;
	font-family: "Circe", Helvetica, Arial, sans-serif;
	font-size: 1.7em;
	padding: 0 4.1em 0 2.35em;
	vertical-align: middle;
	white-space: normal;
}

.browser_scroll {
	overflow: auto;
}

.p_item .div_100 {
	width: 110%;
	height: 110%;
	left: -5%;
	top: -5%;
}



/* --------------------------------------------------------- */


/* ------------------------ Home --------------------------- */

.home_frame{
	background-color: transparent;
}

.home__loader{
	display: none;
	z-index: 1000;
}

.home__loader-skip{
	background-color: #009fe3;
	border-top: 3.4em solid #058DC9;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	display: none;
	left: 50%;
	margin-left: -15em;
	overflow: hidden;
	position: absolute;
	text-align: center;
	top: -1em;
	-webkit-transition: top 300ms ease-in;
	-moz-transition: top 300ms ease-in;
	-ms-transition: top 300ms ease-in;
	-o-transition: top 300ms ease-in;
	transition: top 300ms ease-in;
	width: 30em;
}

.home__loader-skip:hover{
	top: 0;
}

.home__loader-skip span{
	color: #fff;
	display: inline-block;
	font-size: 1.3em;
	font-weight: bold;
	line-height: 4em;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
}

.home__loader-skip:before{
	background-color: #058DC9;
	bottom: 0;
	content: "";
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	-webkit-transform: translate(-100%, 0);
	-moz-transform: translate(-100%, 0);
	-ms-transform: translate(-100%, 0);
	-o-transform: translate(-100%, 0);
	transform: translate(-100%, 0);
	-webkit-transition: -webkit-transform 1500ms linear;
	-moz-transition: -moz-transform 1500ms linear;
	-ms-transition: -ms-transform 1500ms linear;
	-o-transition: -o-transform 1500ms linear;
	transition: transform 1500ms linear;
	width: 100%;
}

.home__loader-skip.loading:before{
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
}

.home__buttons {
	position: absolute;
	bottom: 8em;
	left: 5em;
}

.home__button {
	display: block;
	position: relative;
	width: 5.8em;
	height: 5.8em;
	cursor: pointer;
	text-decoration: none;
	-webkit-transition: visibility .4s, opacity .4s, -webkit-transform .4s;
	-moz-transition: visibility .4s, opacity .4s, -moz-transform .4s;
	-ms-transition: visibility .4s, opacity .4s, -ms-transform .4s;
	-o-transition: visibility .4s, opacity .4s, -o-transform .4s;
	transition: visibility .4s, opacity .4s, transform .4s;
}

.home__button.hidden {
	visibility: hidden;
	opacity: 0;
	-webkit-transform: scale(0.6);
	-moz-transform: scale(0.6);
	-ms-transform: scale(0.6);
	-o-transform: scale(0.6);
	transform: scale(0.6);
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none;
}

.home__button:before {
	content: '';
	position: absolute;
	width: 5.8em;
	height: 5.8em;
	top: 50%;
	left: 50%;
	z-index: 1;
	margin: -2.9em 0 0 -2.9em;
	background-color: #FFFFFF;
	border-radius: 3em;
	-webkit-transition: background-color .2s;
	-moz-transition: background-color .2s;
	-ms-transition: background-color .2s;
	-o-transition: background-color .2s;
	transition: background-color .2s;
}

.home__button:before {
	content: '';
	position: absolute;
	width: 5.8em;
	height: 5.8em;
	top: 50%;
	left: 50%;
	z-index: 1;
	margin: -2.9em 0 0 -2.9em;
	background-color: #FFFFFF;
	border-radius: 3em;
	-webkit-transition: background-color .2s;
	-moz-transition: background-color .2s;
	-ms-transition: background-color .2s;
	-o-transition: background-color .2s;
	transition: background-color .2s;
}

.home__button:hover:before {
	background-color: #009fe3;
}

.home__button:after {
	content: '';
	position: absolute;
	width: 3.5em;
	height: 3.5em;
	top: 50%;
	left: 50%;
	z-index: 1;
	margin: -1.75em 0 0 -1.75em;
	background: url('../i/sprite.svg');
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}

.home__button.aero:after {
	background-position: -87.1em -11.6em;
}
.home__button.aero:hover:after {
	background-position: -87.1em -7.9em;
}
.home__button.plans:after {
	background-position: -90.8em -11.6em;
}
.home__button.plans:hover:after {
	background-position: -90.8em -7.9em;
}
.home__button.online:after {
	width: 4em;
	height: 3em;
	margin: -1.75em 0 0 -1.9em;
	background-position: -86.9em -28.4em;
}
.home__button.online:hover:after {
	background-position: -86.9em -23.9em;
}
.home__button.documents:after {
	background-position: -94.5em -11.6em;
}
.home__button.documents:hover:after {
	background-position: -94.5em -7.9em;
}
.home__button.video:after {
	background-position: -90.8em -15.3em;
}
.home__button.video:hover:after {
	background-position: -87.1em -15.3em;
}
.home__button.commercial:after {
	background-position: -90.8em -19em;
}
.home__button.commercial:hover:after {
	background-position: -87.1em -19em;
}
.home__button.concept:after {
	background-position: -94.5em -19em;
}
.home__button.concept:hover:after {
	background-position: -94.5em -15.3em;
}


.home__button + .home__button {
	margin-top: 2em;
}

.home__button-text {
	visibility: hidden;
	position: absolute;
	height: 5.8em;
	top: 0;
	left: 50%;
	padding: 0 4em 0 5.6em;
	opacity: 0;
	background-color: #FFFFFF;
	border-radius: 0 3em 3em 0;
	white-space: nowrap;
	-webkit-transform: translateX(-1em);
	-moz-transform: translateX(-1em);
	-ms-transform: translateX(-1em);
	-o-transform: translateX(-1em);
	transform: translateX(-1em);
	-webkit-transition: visibility .3s, opacity .3s, transform .3s;
	-moz-transition: visibility .3s, opacity .3s, transform .3s;
	-ms-transition: visibility .3s, opacity .3s, transform .3s;
	-o-transition: visibility .3s, opacity .3s, transform .3s;
	transition: visibility .3s, opacity .3s, transform .3s;
}

.home__button:hover .home__button-text {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-o-transform: translateX(0);
	transform: translateX(0);
}

.home__button-text:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.home__button-text > span {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	font-size: 1.8em;
	font-weight: normal;
	color: #0299d9;
}

.home__content{
	height: 100%;
	position: relative;
	width: 100%;
}

.home__overflow{
	overflow: hidden;
}

.home__menu {
	left: 29.5em;
	position: absolute;
	top: 15em;
}

.home__menu-item {
	color: #5b7783;
	display: inline-block;
	font-size: 1.7em;
	padding: 0.3em;
	vertical-align: top;
	text-decoration: none;
	-webkit-transition: color .3s;
	-moz-transition: color .3s;
	-ms-transition: color .3s;
	-o-transition: color .3s;
	transition: color .3s;
}

.menu__submenu-item.inactive, .home__menu-item.inactive, .menu__add-item.inactive,
.plans__link.inactive, .plans__submenu-item.inactive, .apart_act.inactive, .menu__footer-link.inactive {
	cursor: default;
	opacity: 0.4;
}

.home__menu-item:hover {
	color: #009fe3;
}

.home__menu-item + .home__menu-item {
	margin-left: 1em;
}

.home__slogans-frame {
	color: #fff;
	bottom: 7.5em;
	position: absolute;
	right: 24%;
	width: 24%;
}

.home__slogan {
	bottom: 0;
	left: 0;
	position: absolute;
}

.home__slogan-text {
	font-size: 2.22em;
	white-space: nowrap;
}

.home__slogan-text a{
	font-size: 0.9em;
	text-transform: uppercase;
}

.home__slogan-text h4 {
	font-size: 2.82em;
	font-weight: bold;
	font-variant: small-caps;
	line-height: 0.85;
	margin: 0 0 0.3em 0;
}

.home__slogan-text p{
	line-height: 1.15;
	margin: 0.8em 0;
}

.home__image-frame {
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 10em;
}

.home__image-frame:after{
	background: url(/assets/i/shadow.png) repeat-x;
	-webkit-background-size: auto 100%;
	background-size: auto 100%;
	bottom: 0;
	content: "";
	display: block;
	height: 77.6%;
	left: 0;
	position: absolute;
	width: 100%;
}

.home__slideshow-image {
	top: auto !important;
	bottom: 0 !important;
	margin-bottom: 0 !important;
}

.slideshow__controls-frame {
	background-color: #009fe3;
	bottom: 0;
	height: 4em;
	position: absolute;
	right: 24%;
	width: 24%;
	z-index: 1;
}

.slideshow__bullets-frame {
	height: 100%;
	position: absolute;
	right: 0;
	white-space: nowrap;
}

.slideshow__bullet,
.home__offer-bullet {
	cursor: pointer;
	display: inline-block;
	margin-right: 1.8em;
	height: 100%;
	position: relative;
	vertical-align: top;
	width: 1.6em;
}

.slideshow__bullet.active,
.slideshow__bullet.inactive,
.home__offer-bullet.active,
.home__offer-bullet.inactive{
	cursor: default;
}


.slideshow__bullet:after,
.home__offer-bullet:after{
	background-color: #ffffff;
	-webkit-border-radius: 1em;
	-moz-border-radius: 1em;
	border-radius: 1em;
	content: '';
	height: 0.6em;
	left: 50%;
	margin: -0.3em 0 0 -0.3em;
	opacity: 0.34;
	position: absolute;
	top: 50%;
	-webkit-transition: opacity 300ms linear;
	-moz-transition: opacity 300ms linear;
	-ms-transition: opacity 300ms linear;
	-o-transition: opacity 300ms linear;
	transition: opacity 300ms linear;
	width: 0.6em;
}

.slideshow__bullet.active:after,
.slideshow__bullet:hover:after,
.home__offer-bullet.active:after,
.home__offer-bullet:hover:after {
	opacity: 1;
}

.slideshow__bullet.inactive:after,
.home__offer-bullet.inactive:after {
	opacity: 0.2;
}

.slideshow__bullet > span{
	bottom: 100%;
	color: #5b7783;
	font-size: 1.8em;
	left: 0;
	line-height: 1;
	margin-bottom: 1em;
	opacity: 0;
	position: absolute;
	-webkit-transition: visibility .3s, opacity .3s;
	-moz-transition: visibility .3s, opacity .3s;
	-ms-transition: visibility .3s, opacity .3s;
	-o-transition: visibility .3s, opacity .3s;
	transition: visibility .3s, opacity .3s;
	visibility: hidden;
}

.slideshow__bullet.active > span {
	opacity: 1;
	visibility: visible;
}

.slideshow__progress {
	background-color: #058DC9;
	height: 100%;
	left: 0;
	opacity: 1;
	position: absolute;
	top: 0;
	-webkit-transition: width, opacity;
	-moz-transition: width, opacity;
	-ms-transition: width, opacity;
	-o-transition: width, opacity;
	transition: width, opacity;
	width: 0;
}

.slideshow__progress.animating{
	opacity: 0;
	width: 100%;
}

.slideshow__arrows-frame {
	height: 7.2em;
	left: 100%;
	margin-left: -7.2em;
	margin-top: -1em;
	position: absolute;
	top: 50%;
	width: 14.4em;
	z-index: 1;
}

.slideshow__arrow{
	background-color: #009fe3;
	cursor: pointer;
	height: 100%;
	position: absolute;
	top: 0;
	-webkit-transition: background 200ms linear, opacity 200ms linear;
	-moz-transition: background 200ms linear, opacity 200ms linear;
	-ms-transition: background 200ms linear, opacity 200ms linear;
	-o-transition: background 200ms linear, opacity 200ms linear;
	transition: background 200ms linear, opacity 200ms linear;
	width: 50%;
}

.slideshow__arrow.inactive{
	background-color: transparent;
	opacity: 0.37;
}

.slideshow__arrow.prev{
	background-color: #27292a;
	left: 0;
}

.slideshow__arrow.next{
	right: 0;
}

.slideshow__arrow:before{
	background-color: #000;
	content: "";
	display: block;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	-webkit-transition: opacity 300ms linear;
	-moz-transition: opacity 300ms linear;
	-ms-transition: opacity 300ms linear;
	-o-transition: opacity 300ms linear;
	transition: opacity 300ms linear;
	width: 100%;
}

.slideshow__arrow:hover:before{
	opacity: 0.15;
}

.slideshow__arrow:after {
	background: url(/assets/i/sprite.svg) no-repeat;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	content: '';
	height: 2em;
	left: 50%;
	margin: -1em 0 0 -0.6em;
	position: absolute;
	top: 50%;
	-webkit-transition: -webkit-transform 300ms ease;
	-moz-transition: -moz-transform 300ms ease;
	-ms-transition: -ms-transform 300ms ease;
	-o-transition: -o-transform 300ms ease;
	transition: transform 300ms ease;
	width: 1.2em;
}

.slideshow__arrow.prev:after{background-position: -26.3em -7.8em;}
.slideshow__arrow.next:after{background-position: -27.8em -7.8em;}

.vertical__arrows-frame {
	position: absolute;
	width: 7.2em;
	top: 23.2em;
	left: 100%;
	z-index: 1;
}

.vertical__arrow {
	position: relative;
	width: 100%;
	height: 4em;
	cursor: pointer;
}

.vertical__arrow:after {
	content: '';
	position: absolute;
	width: 2em;
	height: 1.2em;
	left: 50%;
	margin-left: -1em;
	background: url('../i/sprite.svg?v3');
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}

.vertical__arrow.prev:after {
	top: 0.5em;
	background-position: -18.7em -7.8em;
}

.vertical__arrow.prev.inactive:after{
	background-position: -18.7em -9.6em;
	opacity: 0.24;
}

.vertical__arrow.next:after {
	bottom: 0.5em;
	background-position: -21em -7.8em;
}

.vertical__arrow.next.inactive:after{
	background-position: -21em -9.6em;
	opacity: 0.24;
}

.vertical__arrows-frame > i {
	display: block;
	width: 2.4em;
	height: 1px;
	margin: 0 auto;
	background-color: #b2b2b2;
}

.home__scroll-help {
	position: absolute;
	width: 4.6em;
	height: 4em;
	top: 0;
	left: 0;
	cursor: pointer;
}

.home__scroll-help:after {
	content: '▼';
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -0.6em 0 0 -0.5em;
	font-size: 1.6em;
	color: #ffffff;
}

.home__screen {
	position: absolute;
	height: 100%;
	top: 0;
	right: 7.2em;
	left: 7.2em;
}

.home__screen.n2 {
	height: auto;
	top: 0;
	z-index: 1;
}

.home__col.n1 {
    position: relative;
    width: 52%;
}

.home__col.n2, .home__col.n3 {
    position: absolute;
    width: 24%;
    top: 0;
}

.home__col.n2 {
    right: 24%;
}
.home__col.n3 {
    right: 0;
}

.home__text-frame {
    position: relative;
    padding: 8em 15em 8em 25em;
    background-color: #5b7885;
}

.home__text {
	display: block !important;
    position: relative;
    max-width: 25em;
    margin: 0 0 0 auto;
    font-size: 1.9em;
    text-align: left;
    line-height: 1.2;
    color: rgba(255, 255, 255, 0.62);
}

.home__text h4 {
    margin: 0 0 0.8em 0;
    font-size: 2.5em;
    font-weight: normal;
    font-variant: small-caps;
    line-height: 1;
    color: #ffffff;
}

.home__tile {
	display: block;
	position: relative;
	width: 100%;
	height: 58em;
}

.home__tile.n1, .home__tile.n2, .home__tile.n4, .home__tile.n5 {
	background: center center no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
}


.home__tile.n1 {
	height: auto;
	padding-bottom: 57%;
	background-image: url('../images/pages/home/1.jpg');
}
.home__tile.n2 {
	height: auto;
	padding-bottom: 50%;
	background-image: url('../images/pages/home/2.jpg');
}
.home__tile.n3 {
	background-color: #009fe3;
}
.home__tile.n4 {
	background-image: url('../images/pages/home/3.jpg');
}
.home__tile.n5 {
	background-image: url('../images/pages/home/4.jpg');
}
.home__tile.n6 {
	background-color: #aa716f;
}


.home__tile.hover-overlay:before {
	content: '';
	position: absolute;
	width: 0;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(33, 36, 37, 0.16);
	-webkit-transition: width .4s ease-in-out;
	-moz-transition: width .4s ease-in-out;
	-ms-transition: width .4s ease-in-out;
	-o-transition: width .4s ease-in-out;
	transition: width .4s ease-in-out;
}

.home__tile.hover-overlay:hover:before {
	width: 100%;
}

.home__tile-content {
	position: absolute;
	bottom: 0;
	left: 0;
	margin: 0 0 8% 8%;
}

.home__tile-text {
	position: relative;
	font-size: 1.8em;
	line-height: 1.2;
	color: rgba(255, 255, 255, 0.8);
}

.home__tile-text h5 {
	margin: 0 0 1em 0;
	font-size: 1.9em;
	font-weight: normal;
	font-variant: small-caps;
	line-height: 0.9;
	color: #ffffff;
}

.home__tile-text > *:first-child {
	margin-top: 0;
}
.home__tile-text > *:last-child {
	margin-bottom: 0;
}

.home__tile-text li {
	position: relative;
}

.home__tile-text li + li {
	margin-top: 0.5em;
}

.home__tile-text strong {
	color: #ffffff;
}

.home__tile-link {
	cursor: pointer;
	display: block;
	width: 13em;
	margin-top: 2em;
	padding: 0.5em 0;
	font-size: 1.5em;
	border: 1px solid #ffffff;
	font-weight: normal;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	color: rgba(255, 255, 255, 0.8);
	-webkit-transition: color .3s;
	-moz-transition: color .3s;
	-ms-transition: color .3s;
	-o-transition: color .3s;
	transition: color .3s;
}

.home__tile-link:hover {
	color: #ffffff;
}

.home__tile-link.inactive{
	color: rgba(255, 255, 255, 0.8);
	cursor: default;
	opacity: 0.5;
}

.home__news-frame {
	position: absolute;
	width: 48%;
	top: 20em;
	right: 0;
	bottom: 0;
	background-color: #414b4f;
}

.home__news-content {
	position: absolute;
	width: 100%;
	top: 64em;
	bottom: 0;
	left: 0;
}

.home__news-menu {
	position: absolute;
	width: 50%;
	top: 6em;
	left: 0;
	text-align: center;
	white-space: nowrap;
}

.home__news-menu-item {
	display: inline-block;
	padding: 0.2em 1em;
	vertical-align: middle;
	cursor: pointer;
	font-size: 1.7em;
	text-decoration: underline;
	color: #39c2fd;
}

.home__news-menu-item:hover {
	text-decoration: none;
}

.home__news-menu-item.active {
	cursor: default;
	text-decoration: none;
	color: #ffffff;
}

.home__news-menu-delimiter {
	display: inline-block;
	width: 1px;
	height: 1.2em;
	vertical-align: middle;
	background-color: #5b7783;
}

.home__news-center {
	visibility: hidden;
	position: absolute;
	top: 17em;
	right: 5em;
	bottom: 3em;
	left: 5em;
	opacity: 0;
	text-align: center;
	white-space: nowrap;
	-webkit-transform: translateY(5em);
	-moz-transform: translateY(5em);
	-ms-transform: translateY(5em);
	-o-transform: translateY(5em);
	transform: translateY(5em);
	-webkit-transition: visibility .5s, opacity .5s ease-in-out, -webkit-transform .5s ease-in-out;
	-moz-transition: visibility .5s, opacity .5s ease-in-out, -moz-transform .5s ease-in-out;
	-ms-transition: visibility .5s, opacity .5s ease-in-out, -ms-transform .5s ease-in-out;
	-o-transition: visibility .5s, opacity .5s ease-in-out, -o-transform .5s ease-in-out;
	transition: visibility .5s, opacity .5s ease-in-out, transform .5s ease-in-out;
}

.home__news-center.active {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: .3s;
	-moz-transition-delay: .3s;
	-ms-transition-delay: .3s;
	-o-transition-delay: .3s;
	transition-delay: .3s;
}

.home__news-center:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.home__news-center > div {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	white-space: normal;
}

.home__news-title {
	margin-bottom: 2em;
	font-size: 3.5em;
	font-weight: normal;
	font-variant: small-caps;
	line-height: 1;
	color: #ffffff;
}

.home__news-title:after {
	content: '';
	display: block;
	width: 1em;
	height: 0.1em;
	margin: 0.6em auto 0;
	background-color: #009fe3;
}

.home__news-list {
	position: relative;
	margin: 0 3em;
}

.home__news-item {
	display: inline-block;
	position: relative;
	width: 40%;
	margin: 0 5% 4em;
	padding-top: 5em;
	vertical-align: top;
	text-align: left;
}

.home__news-item-date {
	position: absolute;
	top: 0;
	left: -1em;
	padding: 0.3em 1em;
	background-color: #009fe3;
	font-size: 1.6em;
	font-weight: normal;
	color: #ffffff;
}

.home__news-item-title {
	font-size: 1.75em;
	line-height: 1.1;
	color: rgba(255, 255, 255, 0.77);
}

.home__offer-frame {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 100;
	cursor: pointer;
	background-color: rgba(28, 28, 28, 0.85);
}

.home__offer-item {
	visibility: hidden;
	white-space: nowrap;
	text-align: center;
	opacity: 0;
	-webkit-transform: translateY(-30%);
	-moz-transform: translateY(-30%);
	-ms-transform: translateY(-30%);
	-o-transform: translateY(-30%);
	transform: translateY(-30%);
	-webkit-transition: visibility .5s ease-in-out, opacity .5s ease-in-out, -webkit-transform .5s ease-in-out;
	-moz-transition: visibility .5s ease-in-out, opacity .5s ease-in-out, -moz-transform .5s ease-in-out;
	-ms-transition: visibility .5s ease-in-out, opacity .5s ease-in-out, -ms-transform .5s ease-in-out;
	-o-transition: visibility .5s ease-in-out, opacity .5s ease-in-out, -o-transform .5s ease-in-out;
	transition: visibility .5s ease-in-out, opacity .5s ease-in-out, transform .5s ease-in-out;
}

.home__offer-item.active {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: .2s;
	-moz-transition-delay: .2s;
	-ms-transition-delay: .2s;
	-o-transition-delay: .2s;
	transition-delay: .2s;
}

.home__offer-item:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.home__offer-image {
	display: inline-block;
	position: relative;
	max-width: 80%;
	max-height: 80%;
	vertical-align: middle;
}

.home__offer-close {
	width: 9em;
	height: 9em;
	top: 0;
	right: 0;
	left: auto;
	z-index: 1;
}
.search_bg .close_btn{
	left: 184.3em;
	top: 0;
}
.close_btn{
	background-color: #009fe3;
	cursor: pointer;
	height: 8em;
	left: 0;
	position: absolute;
	text-decoration: none;
	width: 8em;
	z-index: 1;
}

.mobile .home__offer-close {
	font-size: 2em;
}

.home__offer-controls {
	position: absolute;
	right: 7.2em;
	bottom: 0;
	left: 7.2em;
}

.scroll-top__button {
	position: absolute;
	top: 100%;
	right: 48%;
	margin: 10em -0.5em 0 0;
	padding-right: 4em;
	cursor: pointer;
}

.scroll-top__button:after {
	content: '';
	position: absolute;
	width: 1.2em;
	height: 4.2em;
	top: 50%;
	right: 0;
	margin-top: -2.1em;
	background: url('../i/sprite.svg') -18.2em -3.3em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}

.scroll-top__button > span {
	font-size: 1.6em;
	font-weight: normal;
	text-decoration: underline;
	line-height: 1.2;
	color: #a5a5a5;
}

.scroll-top__button:hover > span {
	text-decoration: none;
}

.home__afi-logo{
	background: url(../i/sprite.svg) no-repeat -31.3em -15.1em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	cursor: pointer;
	display: block;
	height: 2.7em;
	position: absolute;
	right: 0;
	top: 14.9em;
	width: 17.4em;
}

.home__offer-text{
	font-size: 2em;
	font-variant: small-caps;
	letter-spacing: -0.05em;
	line-height: 1;
	margin-bottom: 0.25em;
}

.home__offer-text strong{
	font-size: 2.68em;
	font-weight: normal;
	letter-spacing: -0.05em;
}

.home__offer-date{
	font-weight: 200;
}

.home_award{
	background: url(../i/sprite.png) no-repeat;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	display: block;
	position: absolute;
}

.home_award.n0{
	background-position: -0.1em -24.9em;
	height: 15.4em;
	left: 3.3em;
	top: 52.5em;
	width: 15.4em;
}

.home_award.n1{
	background-position: -0.1em -41.4em;
	height: 20.2em;
	left: 3.3em;
	top: 69em;
	width: 16em;
}

.home__black-friday-block {
	position: absolute;
	top: 28.5rem;
	left: 3em;
	width: 9.875rem;
	height: 9.875rem;
	background: url(/public/images/black-friday/label.svg) center / contain no-repeat;
	z-index: 1;
}

/* --------------------------------------------------------- */


/* --------------------- Advantages ------------------------ */

.advantages .page__header {
	left: auto;
	right: 10em;
}
.advantages .page__header-block {
	background-color: inherit;
	padding: 3em 4em;
}
.advantages .page__header-title {
	font-size: 2em;
}
.advantages .page__header-title h1 {
	color: #7b868b;
	font-size: 1em;
	font-weight: 300;
}

.advantages__submenu {
	left: 39.7em;
	margin-left: 1.3em;
	padding-left: 1em;
	position: absolute;
	top: 12.5em;
	white-space: nowrap;
	z-index: 1;
}

.advantages__submenu-item {
	display: inline-block;
	cursor: pointer;
	font-size: 1.6em;
	color: #5b7783;
	-webkit-transition: color .3s;
	-moz-transition: color .3s;
	-ms-transition: color .3s;
	-o-transition: color .3s;
	transition: color .3s;
}

.advantages__submenu-item:hover, .advantages__submenu-item.active {
	color: #009fe3;
}

.advantages__submenu-item.active {
	cursor: default;
	text-decoration: underline;
}

.advantages__submenu-item + .advantages__submenu-item {
	margin-left: 0.6em;
}

.advantages__frame .carousel_frame {
    position: absolute;
    top: 18em;
	right: 0;
    bottom: 0;
	left: 0;
	z-index: 2;
}

.advantages__frame .carousel_over {
	position: absolute;
	top: 0;
	right: 24%;
	bottom: 0;
	left: 39.7em;
}

.advantages__frame .carousel_frame:before {
	content: '';
	position: absolute;
	width: 1px;
	height: 2.4em;
	bottom: 100%;
	right: 7.9em;
	margin: 0 0 3.6em 0;
	background-color: #b2b2b2;
}

.carousel_move {
	position: relative;
	width: 100%;
    height: 100%;
}
.carousel_line {
	position: relative;
	left: 0;
	width: 100%;
    height: 100%;
    white-space: nowrap;
    font-size: 0;
}
.carousel_item {
	position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    display: inline-block;
	cursor: pointer;
    vertical-align: top;
	text-decoration: none;
	font-size: 1rem;
	white-space: normal;
}

.carousel_item.active {
	cursor: default;
}

.carousel_arrow {
	position: absolute;
    width: 3.6em;
    height: 3.6em;
    bottom: 100%;
	right: 4em;
    margin-bottom: 3em;
    opacity: 0.5;
}
.carousel_arrow.left {
    margin-right: 4.6em;
}
.carousel_arrow.right {
    /*margin-left: 9.6em;*/
}
.carousel_arrow.active {
    cursor: pointer;
    opacity: 1;
}

.carousel_arrow:after {
    content: '';
    position: absolute;
    width: 1.2em;
    height: 2em;
    top: 50%;
    left: 50%;
    margin: -1em 0 0 -0.6em;
    background: url('../i/sprite.svg');
    -webkit-background-size: 100em 100em;
    background-size: 100em 100em;
}

.carousel_arrow.left:after {
    background-position: -23.3em -7.8em;
}
.carousel_arrow.right:after {
    background-position: -24.8em -7.8em;
}

.carousel_dots_frame {
	position: absolute;
	width: 22%;
	height: 4em;
	bottom: 0;
	left: 54%;
	z-index: 1;
	background-color: #009fe3;
	text-align: right;
}

.carousel_dots {
	display: inline-block;
	position: relative;
	width: 1.6em;
	height: 100%;
	margin-right: 1.5em;
	vertical-align: top;
	cursor: pointer;
}

.carousel_dots:after {
	content: '';
	position: absolute;
	width: 0.6em;
	height: 0.6em;
	top: 50%;
	left: 50%;
	margin: -0.3em 0 0 -0.3em;
	opacity: 0.34;
	background-color: #ffffff;
	-webkit-border-radius: 1em;
	-moz-border-radius: 1em;
	border-radius: 1em;
	-webkit-transition: opacity .3s;
	-moz-transition: opacity .3s;
	-ms-transition: opacity .3s;
	-o-transition: opacity .3s;
	transition: opacity .3s;
}

.carousel_dots:hover:after, .carousel_dots.active:after {
	opacity: 1;
}

.advantages__item-scroll {
    position: absolute;
    top: 0;
    right: 2.5em;
    bottom: 0;
    left: 2.5em;
}

.advantages__item-scroll .textScroll__track {
    visibility: hidden;
}

.advantages__item-image {
    position: relative;
    width: 100%;
    padding-bottom: 56%;
    overflow: hidden;
    -webkit-transition: padding-bottom .5s ease-in-out;
    -moz-transition: padding-bottom .5s ease-in-out;
    -ms-transition: padding-bottom .5s ease-in-out;
    -o-transition: padding-bottom .5s ease-in-out;
    transition: padding-bottom .5s ease-in-out;
}

.carousel_item.active .advantages__item-image {
    padding-bottom: 34%;
	cursor: pointer;
}

.advantages__item-image:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	opacity: 0;
	background-color: #000000;
	-webkit-transition: opacity .3s;
	-moz-transition: opacity .3s;
	-ms-transition: opacity .3s;
	-o-transition: opacity .3s;
	transition: opacity .3s;
}

.carousel_item.active .advantages__item-image:hover:before {
	opacity: 0.2;
}

.advantages__item-image:after {
	content: '';
	position: absolute;
	width: 6.6em;
	height: 6.6em;
	top: 50%;
	left: 50%;
	z-index: 1;
	margin: -3.3em 0 0 -3.3em;
	opacity: 0;
	background: url('../i/sprite.svg') -61.4em -0.1em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	-webkit-transition: opacity .3s;
	-moz-transition: opacity .3s;
	-ms-transition: opacity .3s;
	-o-transition: opacity .3s;
	transition: opacity .3s;
}
.carousel_item.active .advantages__item-image:after {
	opacity: 0.6;
}
.carousel_item.active .advantages__item-image:hover:after {
	opacity: 0.9;
}

.advantages__item-image > img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
	-webkit-transition: -webkit-transform .3s ease-in-out;
	-moz-transition: -moz-transform .3s ease-in-out;
	-ms-transition: -ms-transform .3s ease-in-out;
	-o-transition: -o-transform .3s ease-in-out;
	transition: transform .3s ease-in-out;
}

.carousel_item.active .advantages__item-image:hover img {
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1);
}

.advantages__item-content {
    visibility: hidden;
    position: relative;
    padding: 5em;
    opacity: 0;
    -webkit-transition: visibility .5s, opacity .5s;
    -moz-transition: visibility .5s, opacity .5s;
    -ms-transition: visibility .5s, opacity .5s;
    -o-transition: visibility .5s, opacity .5s;
    transition: visibility .5s, opacity .5s;
}

.carousel_item.active .advantages__item-content {
    visibility: visible;
    opacity: 1;
}

.advantages__item-text {
    font-size: 1.9em;
    line-height: 1.2;
    color: #525252;
}

.advantages__item-text h5 {
    margin: 0 0 0.8em;
    font-size: 1.8em;
    font-weight: normal;
    line-height: 0.9;
    color: #27292a;
}

.advantages_frame .gallery_title {
	max-width: 76em;
	padding: 6em 6em 3em 3em;
	background-color: #ffffff;
}

.advantages__popup-text {
	position: relative;
	font-size: 1.9em;
	line-height: 1.2;
	color: #525252;
}

.advantages__popup-text h5 {
	margin: 0 0 0.8em;
	font-size: 1.8em;
	font-weight: normal;
	line-height: 0.9;
	color: #27292a;
}

/* --------------------------------------------------------- */


/* ------------------------ About -------------------------- */

.about__gallery-frame {
	bottom: 0;
	left: 7.2em;
	position: absolute;
	right: 7.2em;
	top: 10em;
	/*overflow: hidden;*/
}

.about__gallery {
	overflow: hidden;
}

.about__submenu {
	left: 100%;
	position: absolute;
	top: 0;
	white-space: nowrap;
}

.about__submenu-item {
	background-color: #dee9f0;
	color: #27292a;
	display: inline-block;
	position: relative;
	padding: 1em 3em 1em 4em;
	font-size: 1.8em;
	font-weight: 200;
	text-decoration: none;
	-webkit-transition: background-color .3s;
	-moz-transition: background-color .3s;
	-ms-transition: background-color .3s;
	-o-transition: background-color .3s;
	transition: background-color .3s;
}

.about__submenu-item:hover {
	background-color: #eff1f2;
}

.about__submenu-item.active {
	background-color: #009fe3;
	color: #ffffff;
	cursor: default;
	text-decoration: none;
}


.about__submenu-item:after {
	content: '';
	position: absolute;
	width: 2.2em;
	height: 2.2em;
	top: 50%;
	left: 2.8em;
	margin-top: -1.2em;
	background: url('../i/sprite.svg');
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	font-size: 0.58em;
}

.about__submenu-item.n1:after {
	background-position: -16.8em -11.6em;
}
.about__submenu-item.n1.active:after {
	background-position: -19.5em -11.6em;
}
.about__submenu-item.n2:after {
	background-position: -16.8em -14.3em;
}
.about__submenu-item.n2.active:after {
	background-position: -19.5em -14.3em;
}

.about__scroll-help {
	bottom: 5em;
	margin-right: 3.5em;
	padding-right: 4.5em;
	position: absolute;
	right: 50%;
	white-space: nowrap;
}

.about__scroll-help > span{
	color: #ffffff;
	display: inline-block;
	font-size: 1.6em;
	font-weight: normal;
	line-height: 1;
	vertical-align: middle;
}

.about__scroll-help:after {
	background: url(/assets/i/sprite.svg) -10em -15.2em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	content: '';
	display: block;
	height: 4.2em;
	margin: -2.1em 0 0 0;
	position: absolute;
	right: 0;
	top: 50%;
	width: 1.2em;
}

.about__text-frame {
	background-color: #BD726F;
	position: absolute;
	top: 14em;
	right: 7.2em;
	left: 53.8%;
	margin: -4em 0 0 38em;
	-webkit-transition: top .8s ease-in-out, -webkit-transform .3s ease-in-out;
	-moz-transition: top .8s ease-in-out, -moz-transform .3s ease-in-out;
	-ms-transition: top .8s ease-in-out, -ms-transform .3s ease-in-out;
	-o-transition: top .8s ease-in-out, -o-transform .3s ease-in-out;
	transition: top .8s ease-in-out, transform .3s ease-in-out;
	z-index: 2;
}

.about__text-frame.scroll {
	width: 60em;
	height: 55em;
	left: auto;
	/*bottom: 45%;*/
}

.about__text-frame.blue {
	background-color: #009fe3;
}

.about__text-frame .textScroll__drag {
	background-color: #FFFFFF;
}


.about__text-frame.folded {
	top: 100%;
	cursor: pointer;
}

.about__text-frame.folded:hover {
	-webkit-transform: translateY(-3em);
	-moz-transform: translateY(-3em);
	-ms-transform: translateY(-3em);
	-o-transform: translateY(-3em);
	transform: translateY(-3em);
}

.about__text-content {
	position: relative;
	margin: 5.2em;
	-webkit-transition: visibility .5s, opacity .5s;
	-moz-transition: visibility .5s, opacity .5s;
	-ms-transition: visibility .5s, opacity .5s;
	-o-transition: visibility .5s, opacity .5s;
	transition: visibility .5s, opacity .5s;
}

.about__text-frame.scroll .about__text-content {
	position: absolute;
	top: 5.2em;
	right: 5.2em;
	bottom: 5.2em;
	left: 5.2em;
	margin: 0;
}

.about__text-frame.folded .about__text-content {
	visibility: hidden;
	opacity: 0;
}

.about__text {
	font-size: 1.8em;
	color: #ffe6e5;
}

.about__text-frame.blue .about__text {
	color: #d7f1fd;
}

.about__text h5 {
	margin: 0 0 1em;
	font-size: 2em;
	font-weight: normal;
	font-variant: small-caps;
	line-height: 1;
	color: #ffffff;
}

.about__images-list {
	position: relative;
	padding: 1em 0;
}

.about__images-item {
	display: block;
	width: 100%;
	cursor: pointer;
}

.about__images-item + .about__images-item {
	margin-top: 1em;
}

.about__text-head {
	visibility: hidden;
	position: absolute;
	width: 100%;
	height: 4em;
	top: 0;
	left: 0;
	z-index: 1;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	opacity: 0;
	text-align: center;
	white-space: nowrap;
	-webkit-transition: visibility .5s, opacity .5s;
	-moz-transition: visibility .5s, opacity .5s;
	-ms-transition: visibility .5s, opacity .5s;
	-o-transition: visibility .5s, opacity .5s;
	transition: visibility .5s, opacity .5s;
}

.about__text-frame.folded .about__text-head {
	visibility: visible;
	opacity: 1;
	-webkit-transition-delay: .5s;
	-moz-transition-delay: .5s;
	-ms-transition-delay: .5s;
	-o-transition-delay: .5s;
	transition-delay: .5s;
}

.about__text-head:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.about__text-head > div {
	display: inline-block;
	position: relative;
	padding-top: 0.2em;
	vertical-align: middle;
	font-size: 1.2em;
	font-weight: normal;
	text-transform: uppercase;
	line-height: 1;
	white-space: normal;
	color: #fed9d9;
}

.about__text-close, .placement__route-close {
	position: absolute;
	width: 7.2em;
	height: 7.2em;
	top: 0;
	left: 100%;
	cursor: pointer;
	background-color: #0294d2;
	-webkit-transition: visibility .5s, opacity .5s;
	-moz-transition: visibility .5s, opacity .5s;
	-ms-transition: visibility .5s, opacity .5s;
	-o-transition: visibility .5s, opacity .5s;
	transition: visibility .5s, opacity .5s;
}

.about__text-frame.folded .about__text-close, .placement__route-frame.folded .placement__route-close {
	visibility: hidden;
	opacity: 0;
}

.about__text-close:after, .placement__route-close:after {
	content: '';
	position: absolute;
	width: 2.2em;
	height: 2.2em;
	top: 50%;
	left: 50%;
	margin: -1.1em 0 0 -1.1em;
	background: url('../i/sprite.svg') -39.9em -4em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}

/* --------------------------------------------------------- */


/* ---------------------- Developer ------------------------ */

.developer__content {
	position: absolute;
	top: 10em;
	right: 0;
	bottom: 0;
	left: 0;
}

.developer__content:before {
	content: '';
	position: absolute;
	top: 0;
	right: 7.2em;
	bottom: 0;
	left: 7.2em;
	background-color: #f7f8f9;
}

.developer__text-frame{
	bottom: 3.5em;
	left: 42.2em;
	margin-left: 1em;
	position: absolute;
	right: 45%;
	top: 5.5em;
}

.developer__title{
	background-color: transparent;
	margin-bottom: 0.75em;
	padding: 0;
}

.developer__text {
	font-size: 1.9em;
	line-height: 1.2;
	color: #5b7783
}

.developer__text > p:first-child {
	margin-top: 0;
}

.developer__link {
	display: inline-block;
	margin-top: 1.5em;
	color: #009fe3;
}

.developer__images-frame {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	right: 0;
	padding-right: 20.4em;
	position: absolute;
	top: 5.5em;
	width: 38%;
}

.developer__image {
	left: 0;
	margin-bottom: 5.7em;
	padding-top: 56.25%;
	position: relative;
    width: 100%;
}

.developer__image:before {
	content: '';
	height: 100%;
	position: absolute;
	width: 100%;
	left: 0;
	background: center center;
	-webkit-background-size: cover;
	background-size: cover;
}

.developer__image.n1:before {
	top: 0;
	bottom: 8em;
	background-image: url('../images/pages/developer/1.jpg');
    background-position: bottom;
}
.developer__image.n2:before {
	top: 8em;
	bottom: 0;
	background-image: url('../images/pages/developer/2.jpg');
}

.developer__logo {
	position: absolute;
    width: 100%;
    height: 10em;
	margin-top: -5em;
	top: 100%;
	left: 0;
	background-color: #27292a;
}

.developer__logo:after {
	content: '';
	position: absolute;
	width: 33.5em;
	height: 4.5em;
	top: 50%;
	left: 50%;
	margin: -2.25em 0 0 -16.75em;
	background: url('/assets/i/sprite.svg') -31.3em -18.4em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
    font-size: 0.65em;
}

.developer__info-graph {
    position: relative;
}

.developer__info-graph .splitter {
    position: absolute;
	right: 0;
    border-top: 2px dotted #009fe3;
    left: 10em;
}

.developer__info-graph-row {
	display: block;
    padding: 0.5em 0;
	text-decoration: none;
	white-space: nowrap;
}

.developer__info-graph span {
    font-size: 2.5em;
    font-family: 'Circe', Helvetica, Arial, sans-serif;
    font-weight: 500;
    letter-spacing: -0.06em;
    display: inline-block;
    vertical-align: middle;
    padding-left: 0.4em;
}

.developer__info-graph strong {
	padding: 0 0.2em;
    font-size: 1.4em;
	color: #009fe3;
}

.developer__info-graph-row:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
}

.developer__info-graph-row.n4 span {
	padding-left: 0.7em;
}


.developer__info-graph-row:before {
    content: '';
    background: url('../i/sprite.svg');
    -webkit-background-size: 100em 100em;
    background-size: 100em 100em;
    width: 6.5em;
    display: inline-block;
    vertical-align: middle;
    height: 6.5em;
    font-size: 1em;
    padding: 0.9em;
    left:0;
    border: 2px solid #009fe3;
}

.developer__info-graph-row.n1:before {
    background-position: -53.6em -24.1em;
}

.developer__info-graph-row.n2:before {
    background-position: -42.9em -24.1em;
}

.developer__info-graph-row.n3:before {
    background-position: -31.4em -24.1em;
}

.developer__info-graph-row.n4:before {
	background-position: -67.4em -24.1em;
}

/* --------------------------------------------------------- */


/* ----------------------- Route --------------------------- */

.open_route {
	position: absolute;
	width: 265px;
	height: 58px;
	top: 85%;
	left: 50%;
	margin: -50px 0 0 -132px;
	background: url('../i/sprite.png') -694px -95px;
	cursor: pointer;
	z-index: 1;
}
.route_adr_frame {
	position: absolute;
	width: 542px;
	height: 99px;
	left: 50%;
	top: 85%;
	margin: -72px 0 0 -271px;
	background: #f2711f;
	border-radius: 14px;
	z-index: 1;
	display: none;
}
.route_adr_input {
	position: absolute;
	top: 38px;
	left: 21px;
	display: block;
	width: 448px;
	margin: 0;
	padding: 10px 40px 10px 12px;
	background: #fff;
	border: none;
	color: #f39200;
	font-size: 15px;
	height: 20px;
	border-radius: 7px;
    font-family: Arial, Helvetica, sans-serif;
	cursor: pointer;
}
.route_adr_title {
	position: absolute;
	top: 0;
	left: 32px;
	line-height: 40px;
	font-size: 17px;
	color: #fff;
}
.route_adr_close {
	right: -33px;
	top: -33px;
	margin: 0;
}
.route_adr_btn {
	position: absolute;
	width: 40px;
	height: 40px;
	right: 21px;
	top: 37px;
	cursor: pointer;
	background: url('../i/sprite.png') -862px -5px;
}

/* --------------------------------------------------------- */


/* ----------------------- Plans --------------------------- */

.plans__offers-frame {
	position: absolute;
	width: 22.1%;
	right: 7.2em;
	bottom: 0;
	z-index: 2;
}

.plans__office {
	position: absolute;
    background: #fff;
    width: 13em;
    height: 3em;
    top: 62%;
    left: 69%;
    z-index: 10;
}
.opened_korpus .plans__office, .opened_floor .plans__office, .opened_apart .plans__office {
	display: none;
}
.commercial_frame .plans__office {
	top: 13%;
    left: 26%;
}
.parking_frame .plans__office,
.storerooms_frame .plans__office{
	top: 58.5%;
    left: 88%;
}
.plans__office span {
	display: block;
	position: absolute;
	right: 1em;
    left: auto;
    font-size: 1.3em;
    color: #27292a;
    margin-top: .4em;
}
.plans__office:before {
	content: '';
    position: absolute;
    display: block;
    width: 5.6em;
    height: 8.1em;
    opacity: 1;
    top: -2em;
    left: -3em;
    background-position: -90.7em -.1em;
    background-size: 100em 100em;
	background-image: url(/assets/i/sprite.png);
}

.plans__offers-button {
	background-color: #bb6461;
	cursor: pointer;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.plans__offers-frame.opened .plans__offers-button {
    cursor: default;
}

.plans__offers-button:after {
    content: '▲';
    position: absolute;
    top: 50%;
    right: 2em;
    margin-top: -0.7em;
    font-size: 1.6em;
    color: #ffffff;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -ms-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
}

.plans__offers-frame.opened .plans__offers-button:after {
    opacity: 0;
}

.plans__offers-name {
	position: relative;
    padding: 0 5.6em 0 3em;
    height: 5.6em;
    white-space: nowrap;
}

.plans__offers-name:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
}

.plans__offers-name > div {
    display: inline-block;
    position: relative;
    padding-top: 0.2em;
    vertical-align: middle;
    font-size: 1.2em;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 1;
    white-space: normal;
    color: #fed9d9;
}

.plans__offers-name p {
    margin: 0;
}

.plans__offers-name strong {
	color: #ffffff;
}

.plans__offers-content {
	background-color: #bb6461;
    position: absolute;
    width: 100%;
    height: 77em;
    bottom: 0;
    left: 0;
    -webkit-transform: translateY(71.5em);
    -moz-transform: translateY(71.5em);
    -ms-transform: translateY(71.5em);
    -o-transform: translateY(71.5em);
    transform: translateY(71.5em);
    -webkit-transition: -webikt-transform .5s ease-in-out;
    -moz-transition: -moz-transform .5s ease-in-out;
    -ms-transition: -ms-transform .5s ease-in-out;
    -o-transition: -o-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
}

.plans__offers-content:hover {
    -webkit-transform: translateY(70em);
    -moz-transform: translateY(70em);
    -ms-transform: translateY(70em);
    -o-transform: translateY(70em);
    transform: translateY(70em);
}

.plans__offers-frame.opened .plans__offers-content {
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.plans__offers-close {
    position: absolute;
    width: 5.6em;
    height: 5.6em;
    top: 0;
    left: 100%;
    cursor: pointer;
    background-color: #0294d2;
    -webkit-transform: translateX(-6em);
    -moz-transform: translateX(-6em);
    -ms-transform: translateX(-6em);
    -o-transform: translateX(-6em);
    transform: translateX(-6em);
    -webkit-transition: background-color .3s, -webkit-transform .3s ease-in-out .5s;
    -moz-transition: background-color .3s, -moz-transform .3s ease-in-out .5s;
    -ms-transition: background-color .3s, -ms-transform .3s ease-in-out .5s;
    -o-transition: background-color .3s, -o-transform .3s ease-in-out .5s;
    transition: background-color .3s, transform .3s ease-in-out .5s;
}

.plans__offers-close:hover {
    background-color: #0284bb;
}

.plans__offers-frame.opened .plans__offers-close {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.plans__offers-close:after {
    content: '';
    position: absolute;
    width: 2.2em;
    height: 2.2em;
    top: 50%;
    left: 50%;
    margin: -1.1em 0 0 -1.1em;
    background: url('../i/sprite.svg') -39.9em -4em;
    -webkit-background-size: 100em 100em;
    background-size: 100em 100em;
}

.plans__offers-scroll {
    position: absolute;
    top: 5.6em;
    right: 0;
    bottom: 0;
    left: 0;
}

.plans__offers-scroll .textScroll__track {
	height: auto;
	top: 2em;
	bottom: 2em;
	margin-left: 0;
}

.plans__offers-item {
	padding: 2.5em 5em;
    position: relative;
}

.plans__offers-item-text {
    font-size: 1.8em;
    color: #ece2e1;
}

.plans__offers-item-text h5 {
    font-size: 1.5em;
    font-weight: normal;
	margin: 0 0 0.5em 0;
    text-transform: uppercase;
    line-height: 1;
    color: #ffffff;
}

.plans__offers-link {
    display: inline-block;
    position: relative;
    margin-top: 1em;
    padding: 0.6em 1em;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #ffffff;
    font-size: 1.5em;
    font-weight: normal;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.96);
    -webkit-transition: background-color .3s, color .3s;
    -moz-transition: background-color .3s, color .3s;
    -ms-transition: background-color .3s, color .3s;
    -o-transition: background-color .3s, color .3s;
    transition: background-color .3s, color .3s;
}

.plans__offers-link:hover {
    background-color: #ffffff;
    color: #bb6461;
}

.trade-in__frame {
	position: absolute;
	display: block;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 2em;
}

.trade-in__frame strong {
	font-size: 2em;
}

.page__header-title h1{
	color: #27292a;
	display: block;
	font-weight: normal;
	font-size: 1em;
	font-variant: small-caps;
	line-height: 0.85;
}

.plans_info {
	display: none;
}

.plans__frame {
	bottom: 0;
	left: 30em;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 10em;
}

.plans__left, .plans__right {
	bottom: 9.8em;
	position: absolute;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.plans__left {
	left: 0;
	top: 0;
	width: 40em;
}

.plans__right {
	/*left: 40em;*/
	left: 0;
	right: 7.2em;
	top: 0;
	-webkit-transition: right .5s ease-in-out, left .5s ease-in-out, -webkit-transform .5s ease-in-out;
	-moz-transition: right .5s ease-in-out, left .5s ease-in-out, -moz-transform .5s ease-in-out;
	-ms-transition: right .5s ease-in-out, left .5s ease-in-out, -ms-transform .5s ease-in-out;
	-o-transition: right .5s ease-in-out, left .5s ease-in-out, -o-transform .5s ease-in-out;
	transition: right .5s ease-in-out, left .5s ease-in-out, transform .5s ease-in-out;
}

.search_bg .plans__right {
	left: 40em;
}

.opened_filters .plans__right, .opened_filters .plans__back {
	/*	-webkit-transform: translateX(40em);
        -moz-transform: translateX(40em);
        -ms-transform: translateX(40em);
        -o-transform: translateX(40em);
        transform: translateX(40em);*/
	right: -32.8em;
	left: 40em;
}


.opened_floor .plans__right, .opened_apart .plans__right {
	right: 7.2em;
	left: 0;
	-webkit-transform: translateX(5.2em);
	-moz-transform: translateX(5.2em);
	-ms-transform: translateX(5.2em);
	-o-transform: translateX(5.2em);
	transform: translateX(5.2em);
}

.plans__title {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 1em;
	height: 13.8em;
	padding: 0 4em;
	position: relative;
	-webkit-transition: opacity 300ms linear;
	-moz-transition: opacity 300ms linear;
	-ms-transition: opacity 300ms linear;
	-o-transition: opacity 300ms linear;
	transition: opacity 300ms linear;
	white-space: nowrap;
}

.opened_floor .plans__title,
.opened_apart .plans__title{
	opacity: 0;
}

.plans__title:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.plans__title > span {
	display: inline-block;
	font-size: 4.3em;
	vertical-align: middle;
}

.plans__filters-toggle {
	position: absolute;
	width: 40em;
	height: 5.5em;
	bottom: 0;
	left: 0;
	z-index: 1;
	background-color: #009fe3;
	text-align: center;
	white-space: nowrap;
	color: #fff;
	cursor: pointer;
	-webkit-transition: background-color .3s;
	-moz-transition: background-color .3s;
	-ms-transition: background-color .3s;
	-o-transition: background-color .3s;
	transition: background-color .3s;

	&.hidden {
		display: none;
	}
}

.opened_filters .plans__filters-toggle {
	background-color: #009fe3;
	color: #fff;
}

.opened_floor .plans__filters-toggle,
.opened_apart .plans__filters-toggle,
.opened_floor .page__submenu.left_bottom,
.opened_apart .page__submenu.left_bottom {
	visibility: hidden;
	opacity: 0;
}

.plans__filters-toggle:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.plans__filters-toggle:before {
	content: '';
	display: inline-block;
	position: relative;
	width: 2.3em;
	height: 2.3em;
	margin-right: 1em;
	vertical-align: middle;
	background: url('/assets/i/sprite.svg') -50.5em -11.6em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}

.opened_filters .plans__filters-toggle:before {
	background-position: -55.9em -11.6em;
}

.plans__filters-toggle > span {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	font-size: 1.7em;
	text-transform: lowercase;
}

.plans__filters-toggle > span:before {
	content: 'Подобрать по параметрам';
}

.opened_filters .plans__filters-toggle > span:before {
	content: 'Скрыть фильтр';
}

.plans__filters-frame {
	position: absolute;
	width: 40em;
	top: 0;
	bottom: 0;
	background-color: #eff1f2;
	overflow: hidden;
/*	-webkit-transition: width .5s ease-in-out;
	-moz-transition: width .5s ease-in-out;
	-ms-transition: width .5s ease-in-out;
	-o-transition: width .5s ease-in-out;
	transition: width .5s ease-in-out;*/
	-webkit-transition: visibility .5s, opacity .5s;
	-moz-transition: visibility .5s, opacity .5s;
	-ms-transition: visibility .5s, opacity .5s;
	-o-transition: visibility .5s, opacity .5s;
	transition: visibility .5s, opacity .5s;
}

.opened_floor .plans__filters-frame,
.opened_apart .plans__filters-frame {
	visibility: hidden;
	opacity: 0;
	/*width: 4.5em;*/
}

.plans__filters {
	position: absolute;
	width: 40em;
	top: 0;
	bottom: 5.5em;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: visibility .5s, opacity .5s;
	-moz-transition: visibility .5s, opacity .5s;
	-ms-transition: visibility .5s, opacity .5s;
	-o-transition: visibility .5s, opacity .5s;
	transition: visibility .5s, opacity .5s;
}

.opened_floor .plans__filters,
.opened_apart .plans__filters {
	visibility: hidden;
	opacity: 0;
}

.plans__frame:after {
	content: '';
	position: absolute;
	width: 7.2em;
	height: 100%;
	top: 0;
	right: 0;
	z-index: 2;
	background-color: #FFFFFF;
	-webkit-transition: visibility .3s, opacity .3s;
	-moz-transition: visibility .3s, opacity .3s;
	-ms-transition: visibility .3s, opacity .3s;
	-o-transition: visibility .3s, opacity .3s;
	transition: visibility .3s, opacity .3s;
}

.plans__frame.opened_floor:after, .plans__frame.opened_apart:after {
	visibility: hidden;
	opacity: 0;
}

.plans__frame .page__submenu{
	-webkit-transition: -webkit-transform 500ms ease;
	-moz-transition: -moz-transform 500ms ease;
	-ms-transition: -ms-transform 500ms ease;
	-o-transition: -o-transform 500ms ease;
	transition: transform 500ms ease;
}

.opened_floor .page__submenu, .opened_apart .page__submenu {
	-webkit-transform: translateY(-10em);
	-moz-transform: translateY(-10em);
	-ms-transform: translateY(-10em);
	-o-transform: translateY(-10em);
	transform: translateY(-10em);
}

.search-link .page__submenu-icon{background-position: -19.6em -0.2em;}
.vtour-link .page__submenu-icon{background-position: -22.4em -0.2em;}
.plans-link .page__submenu-icon{background-position: -42.4em -11.6em;}

.search-link.active .page__submenu-icon{background-position: -31.4em -11.6em;}
.vtour-link.active .page__submenu-icon{background-position: -34.2em -11.6em;}
.plans-link.active .page__submenu-icon{background-position: -45.1em -11.6em;}

.plans__wrapper {
	bottom: 0;
	height: auto;
	overflow: hidden;
	top: 0;
}

.plans__image-frame.plans-frame {
	left: 0;
}

.plans__image-frame.korpus-frame {
	left: 100%;
}

.plans__image:after {
	content: '';
	visibility: hidden;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	opacity: 0;
	/*background-color: rgba(255, 255, 255, 0.3);*/
	-webkit-transition: visibility .3s, opacity .3s;
	-moz-transition: visibility .3s, opacity .3s;
	-ms-transition: visibility .3s, opacity .3s;
	-o-transition: visibility .3s, opacity .3s;
	transition: visibility .3s, opacity .3s;
}

.opened_floor .plans__image:after,
.opened_apart .plans__image:after {
	visibility: visible;
	opacity: 1;
}

.plans__loft-button {
	position: absolute;
	top: 50%;
	right: 2em;
	margin-top: -1em;
	padding: 0.5em 1em 0.2em;
	background-color: #567cab;
	font-size: 1.2em;
	/*font-weight: bold;*/
	text-transform: uppercase;
	color: #FFFFFF;
}

.plans__loft-bubble {
	visibility: hidden;
	position: absolute;
	width: 46em;
	height: 18em;
	top: 50%;
	left: 50%;
	z-index: 3;
	margin: -9em 0 0 3em;
	opacity: 0;
	background-color: #567cab;
	white-space: nowrap;
	-webkit-transform: translateX(2em);
	-moz-transform: translateX(2em);
	-ms-transform: translateX(2em);
	-o-transform: translateX(2em);
	transform: translateX(2em);
	-webkit-transition: visibility .3s ease-in-out, opacity .3s ease-in-out, -webkit-transform .3s ease-in-out;
	-moz-transition: visibility .3s ease-in-out, opacity .3s ease-in-out, -moz-transform .3s ease-in-out;
	-ms-transition: visibility .3s ease-in-out, opacity .3s ease-in-out, -ms-transform .3s ease-in-out;
	-o-transition: visibility .3s ease-in-out, opacity .3s ease-in-out, -o-transform .3s ease-in-out;
	transition: visibility .3s ease-in-out, opacity .3s ease-in-out, transform .3s ease-in-out;
}

.plans__loft-bubble.visible {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-o-transform: translateX(0);
	transform: translateX(0);
}

.plans__loft-bubble:before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	top: 50%;
	right: 100%;
	margin-top: -1em;
	border-style: solid;
	border-width: 1em 2em 1em 0;
	border-color: transparent #567cab transparent transparent;
}

.plans__loft-bubble:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.plans__loft-bubble > div {
	display: inline-block;
	padding: 0 2em;
	vertical-align: middle;
	font-size: 1.8em;
	white-space: normal;
	color: rgba(255, 255, 255, 0.85);
}

.windrose {
	position: absolute;
	width: 5.9em;
	height: 7.6em;
	top: 10em;
	right: 6em;
	z-index: 1;
	background: url('../i/sprite.svg') -25.1em -0.1em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	color: #ffffff;
	-webkit-transform-origin: 50% 4.6em;
	-moz-transform-origin: 50% 4.6em;
	-ms-transform-origin: 50% 4.6em;
	-o-transform-origin: 50% 4.6em;
	transform-origin: 50% 4.6em;
}

.windrose.dark {
	background-position: -55.1em -0.1em;
	color: #335665;
}

.windrose_letter {
	position: absolute;
	font-size: 2.3em;
	font-weight: bold;
	width: 2em;
	line-height: 2;
	text-align: center;
	margin: -1em;
}

.windrose_letter.north {
	left: 50%;
	bottom: 120%;
}

.plans__floor-frame .windrose {
	top: auto;
	right: auto;
	/*left: 5em;*/
	bottom: 2em;
	left: 6em;
	-webkit-transition: left .3s ease-in-out, bottom .3s ease-in-out;
	-moz-transition: left .3s ease-in-out, bottom .3s ease-in-out;
	-ms-transition: left .3s ease-in-out, bottom .3s ease-in-out;
	-o-transition: left .3s ease-in-out, bottom .3s ease-in-out;
	transition: left .3s ease-in-out, bottom .3s ease-in-out;
}

.plans__image-wrapper {
	overflow: hidden;
}

.plans__korpus-bubble {
	position: absolute;
	width: 1.7em;
	height: 1.7em;
	margin: -0.85em 0 0 -0.85em;
	cursor: pointer;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
	pointer-events: none;
	z-index: 1;
}

.plans__korpus-bubble-num {
	/*visibility: hidden;*/
	position: absolute;
	width: 5.3em;
	height: 5.3em;
	top: 50%;
	left: 50%;
	z-index: 1;
	margin: -2.65em 0 0 -2.65em;
	/*opacity: 0;*/
	text-align: center;
	white-space: nowrap;
	-webkit-transition: visibility .2s ease-in-out, opacity .2s ease-in-out;
	-moz-transition: visibility .2s ease-in-out, opacity .2s ease-in-out;
	-ms-transition: visibility .2s ease-in-out, opacity .2s ease-in-out;
	-o-transition: visibility .2s ease-in-out, opacity .2s ease-in-out;
	transition: visibility .2s ease-in-out, opacity .2s ease-in-out;
}

.plans__korpus-bubble-num:before {
	background-color: rgba(39, 41, 42, 1);
	content: '';
	position: absolute;
	width: 5.3em;
	height: 5.3em;
	top: 50%;
	left: 50%;
	margin: -2.65em 0 0 -2.65em;
	/*background-color: #009fe3;*/
	-webkit-transform: scale(0.7);
	-moz-transform: scale(0.7);
	-ms-transform: scale(0.7);
	-o-transform: scale(0.7);
	transform: scale(0.7);
	-webkit-transition: -webkit-transform .3s ease-in-out;
	-moz-transition: -moz-transform .3s ease-in-out;
	-ms-transition: -ms-transform .3s ease-in-out;
	-o-transition: -o-transform .3s ease-in-out;
	transition: transform .3s ease-in-out;
}

.plans__korpus-bubble.active .plans__korpus-bubble-num:before {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.plans__korpus-bubble.active .plans__korpus-bubble-num:before{
	background-color: #009fe3;
}

.plans__korpus-bubble.loft.active .plans__korpus-bubble-num:before{
	background-color: #4e689b;
}

/*
.plans__korpus-bubble.hover .plans__korpus-bubble-num:before{
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
}
*/

.plans__korpus-bubble-num:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.plans__korpus-bubble-num > span {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	font-size: 1.4em;
	font-weight: bold;
	line-height: 1;
	color: #ffffff;
}

.plans__korpus-bubble .label {
	visibility: hidden;
	position: absolute;
	/*width: 11em;*/
	height: 5.3em;
	top: 50%;
	left: 0;
	margin-top: -2.65em;
	padding: 0 1.5em 0 5em;
	opacity: 0;
	background-color: #ffffff;
	white-space: nowrap;
	-webkit-transition: -webkit-transform 300ms ease-in-out, padding 300ms ease-in-out, opacity 300ms linear, visibility 300ms linear;
	-moz-transition: -moz-transform 300ms ease-in-out, padding 300ms ease-in-out, opacity 300ms linear, visibility 300ms linear;
	-ms-transition: -ms-transform 300ms ease-in-out, padding 300ms ease-in-out, opacity 300ms linear, visibility 300ms linear;
	-o-transition: -o-transform 300ms ease-in-out, padding 300ms ease-in-out, opacity 300ms linear, visibility 300ms linear;
	transition: transform 300ms ease-in-out, padding 300ms ease-in-out, opacity 300ms linear, visibility 300ms linear;
}

.plans__korpus-bubble.active .label {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-o-transform: translateX(0);
	transform: translateX(0);
}


.plans__korpus-bubble.hover .label{
	padding-left: 6em;
}


.plans__korpus-bubble .label:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.plans__korpus-bubble .label > div {
	display: inline-block;
	position: relative;
	vertical-align: middle;
}

.plans__korpus-bubble .label .flats-count {
	font-size: 1.3em;
	color: #27292a;
}

.plans__korpus-bubble .label .release-date {
	font-size: 1.3em;
	color: rgba(39, 41, 42, 0.5);
}

.plans__korpus-bubble .label .start {
	position: absolute;
	width: 10em;
	top: 100%;
	left: 3.5em;
	padding: 0.5em 0;
	background-color: #bb6461;
	text-align: center;
	color: #FFFFFF;
}

.plans__korpus-bubble .label .start > span {
	font-size: 1em;
	text-transform: uppercase;
}

.plans__korpus-label, .plans__floor-label, .plans__section-label {
	position: absolute;
	width: 13.5em;
	height: 13.8em;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: #0294d2;
	white-space: nowrap;
}

.plans__floor-frame .plans__floor-label, .plans__floor-frame .plans__section-label {
	visibility: hidden;
	top: 29em;
	left: -14.3em;
	opacity: 0;
	-webkit-transition: visibility .5s, opacity .5s;
	-moz-transition: visibility .5s, opacity .5s;
	-ms-transition: visibility .5s, opacity .5s;
	-o-transition: visibility .5s, opacity .5s;
	transition: visibility .5s, opacity .5s;
}

.plans__floor-frame .plans__section-label {
	top: 14.5em;
}
.parking_frame .plans__floor-frame .plans__floor-label,
.storerooms_frame .plans__floor-frame .plans__floor-label{
    top: 14.5em;
}
.opened_floor .plans__floor-frame .plans__floor-label,
.opened_apart .plans__floor-frame .plans__floor-label,
.opened_floor .plans__floor-frame .plans__section-label,
.opened_apart .plans__floor-frame .plans__section-label {
	visibility: visible;
	opacity: 1;
}

.plans__korpus-label:after, .plans__floor-label:after, .plans__section-label:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}



.plans__korpus-label > .center,
.plans__floor-label > .center,
.plans__floor-label-at > .center,
.plans__section-label > .center,
.plans__section-label-at > .center {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	margin-left: 2.5em;
}

.plans__korpus-label span, .plans__floor-label span, .plans__section-label span {
	display: block;
	position: relative;
	color: #ffffff;
}
.plans__korpus-label span.n1, .plans__floor-label span.n1, .plans__section-label span.n1 {
	margin-bottom: 1em;
	font-size: 1.2em;
	font-weight: normal;
	text-transform: uppercase;
}
.plans__korpus-label span.n2, .plans__floor-label span.n2, .plans__section-label span.n2 {
	font-size: 5.9em;
	font-weight: bold;
	line-height: 1;
}

.plans__floor-label.floor-popup {
	visibility: hidden;
	margin: -6.9em 0 0 5em;
	opacity: 0;
	-webkit-transition: visibility .2s, top .2s, left .2s, opacity .2s;
	-moz-transition: visibility .2s, top .2s, left .2s, opacity .2s;
	-ms-transition: visibility .2s, top .2s, left .2s, opacity .2s;
	-o-transition: visibility .2s, top .2s, left .2s, opacity .2s;
	transition: visibility .2s, top .2s, left .2s, opacity .2s;
}

.plans__floor-label.floor-popup.active {
	visibility: visible;
	opacity: 1;
}

.plans__floor-label.floor-popup.inverse {
	margin-left: -30em;
}

.plans__floor-label-at {
	position: absolute;
	width: 13.5em;
	height: 13.8em;
	top: 0;
	left: 100%;
	background-color: #335665;
}

.plans__floor-label-at:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.plans__back {
	background-color: #27292a;
	cursor: pointer;
	display: block;
	height: 6.9em;
	left: 0;
	position: absolute;
	-webkit-transition: -webkit-transform 500ms ease-in-out, opacity 300ms linear, visiblity 300ms linear, left 500ms ease-in-out;
	-moz-transition: -moz-transform 500ms ease-in-out, opacity 300ms linear, visiblity 300ms linear, left 500ms ease-in-out;
	-ms-transition: -ms-transform 500ms ease-in-out, opacity 300ms linear, visiblity 300ms linear, left 500ms ease-in-out;
	-o-transition: -o-transform 500ms ease-in-out, opacity 300ms linear, visiblity 300ms linear, left 500ms ease-in-out;
	transition: transform 500ms ease-in-out, opacity 300ms linear, visiblity 300ms linear, left 500ms ease-in-out;
	top: 14.5em;
	width: 6.9em;
	z-index: 2;
}

.plans__back-button {
	background-color: #333;
	transition: all .3s ease;
}
.plans__back-button:hover {
	background-color: #4d4d4d;
	transition: all .3s ease;
}
.plans__back.search {
	left: 16.1em;
	top: 39.3em;
	z-index: 4;
	background-color: inherit;
}
.plans__back.search .plans__back-label {
	left: 6.9em;
	max-width: inherit;
	background-color: inherit;
}

.plans__back:before{
	background: url(/assets/i/sprite.svg) no-repeat -26.3em -7.8em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	content: "";
	display: block;
	height: 2em;
	left: 50%;
	margin: -1em 0 0 -0.6em;
	position: absolute;
	top: 50%;
	width: 1.2em;
}

.plans__back.hidden {
	visibility: hidden;
	opacity: 0;
}

.plans__back-label{
	background-color: #27292a;
	height: 100%;
	left: 100%;
	overflow: hidden;
	position: absolute;
	max-width: 0;
	top: 0;
	-webkit-transition: max-width 500ms ease;
	-moz-transition: max-width 500ms ease;
	-ms-transition: max-width 500ms ease;
	-o-transition: max-width 500ms ease;
	transition: max-width 500ms ease;
	white-space: nowrap;
	width: auto;
}

.plans__back:hover .plans__back-label{
	max-width: 20em;
}

.plans__back-label span{
	color: #fff;
	display: inline-block;
	font-size: 1.7em;
	padding: 0 1.5em 0 0;
	vertical-align: middle;
}

.plans__back-label:after{
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	width: 0;
}

.opened_floor .plans__back,
.opened_apart .plans__back {
	-webkit-transform: translate(5.4em, 29em);
	-moz-transform: translate(5.4em, 29em);
	-ms-transform: translate(5.4em, 29em);
	-o-transform: translate(5.4em, 29em);
	transform: translate(5.4em, 29em);
}

.opened_filters .opened_floor .plans__back,
.opened_filters .opened_apart .plans__back {
	-webkit-transform: translate(-34.7em, 29em);
	-moz-transform: translate(-34.7em, 29em);
	-ms-transform: translate(-34.7em, 29em);
	-o-transform: translate(-34.7em, 29em);
	transform: translate(-34.7em, 29em);
}


.plans_frame .slideshow__controls-frame,
.commercial_frame .slideshow__controls-frame,
.parking_frame .slideshow__controls-frame,
.storerooms_frame .slideshow__controls-frame{
	margin-right: 7.2em;
	right: 22.1%;
}

.plans_frame .slideshow__bullets-frame,
.commercial_frame .slideshow__bullets-frame,
.parking_frame .slideshow__bullets-frame,
.storerooms_frame .slideshow__bullets-frame{
	padding-right: 4em;
}

.plans__floor-frame {
	background-color: #f7f8f9;
	bottom: 9.8em;
	left: 19.5em;
	position: absolute;
	right: 0;
	top: 0;
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	-ms-transform: translateX(100%);
	-o-transform: translateX(100%);
	transform: translateX(100%);
	-webkit-transition: visibility .5s ease-in-out, -webkit-transform .5s ease-in-out;
	-moz-transition: visibility .5s ease-in-out, -moz-transform .5s ease-in-out;
	-ms-transition: visibility .5s ease-in-out, -ms-transform .5s ease-in-out;
	-o-transition: visibility .5s ease-in-out, -o-transform .5s ease-in-out;
	transition: visibility .5s ease-in-out, transform .5s ease-in-out;
	visibility: hidden;
	z-index: 2;
}

.plans__floor-frame:before {
	content: '';
	position: absolute;
	width: 0.8em;
	height: 100%;
	top: 0;
	right: 100%;
	background-color: #ffffff;
}

.opened_floor .plans__floor-frame, .opened_apart .plans__floor-frame {
	visibility: visible;
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-o-transform: translateX(0);
	transform: translateX(0);
}

.plans__floor-select, .plans__section-select {
	position: absolute;
	width: 5.6em;
	height: 100%;
	top: 0;
	right: 0;
}

/*
.plans__floor-select:before {
	content: '';
	position: absolute;
	width: 2.4em;
	height: 1px;
	top: 50%;
	left: 50%;
	margin: -0.5px 0 0 -1.2em;
	background-color: #acadae;
}
*/

.plans__release-btn{
	background-color: #5b8ea4;
	bottom: 0;
	cursor: pointer;
	display: inline-block;
	left: 0;
	padding: 0 3em;
	position: absolute;
	white-space: nowrap;
	z-index: 2;
}

.plans__release-btn:before{
	background-color: rgb(0, 0, 0);
	content: "";
	display: block;
	height: 100%;
	left: 0;
	opacity :0;
	position: absolute;
	top: 0;
	-webkit-transition: opacity 300ms linear;
	-moz-transition: opacity 300ms linear;
	-ms-transition: opacity 300ms linear;
	-o-transition: opacity 300ms linear;
	transition: opacity 300ms linear;
	width: 100%;
}

.plans__release-btn:hover:before{
	opacity: 0.15;
}

.plans__release-btn .icon{
	background: url(/assets/i/sprite.svg) no-repeat -83.4em -7.9em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	display: inline-block;
	height: 3.5em;
	font-size: 0.9em;
	margin: 0 1em 0 -1em;
	position: relative;
	vertical-align: middle;
	width: 3.5em;
}

.plans__release-btn .label{
	color: #fff;
	display: inline-block;
	font-size: 1.7em;
	position: relative;
	vertical-align: middle;
}

.plans__release-btn:after {
	content: "";
	display: inline-block;
	height: 5.6em;
	vertical-align: middle;
	width: 0;
}


.floor_up, .floor_down, .section_next, .section_prev {
	position: absolute;
	width: 5.6em;
	height: 5.6em;
	right: 0;
	opacity: 0.2;
}

.floor_up, .section_next {
	top: 0;
}
.floor_down, .section_prev {
	bottom: 0;
}

.floor_up.active, .floor_down.active, .section_next.active, .section_prev.active {
	opacity: 1;
	cursor: pointer;
}

.floor_up:after, .floor_down:after, .section_next:after, .section_prev:after {
	content: '';
	position: absolute;
	width: 1.7em;
	height: 0.9em;
	top: 50%;
	left: 50%;
	margin: -0.45em 0 0 -0.85em;
	background: url('/assets/i/sprite.svg');
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}

.floor_up:after, .section_next:after {
	background-position: -29.3em -7.8em;
}
.floor_down:after, .section_prev:after {
	background-position: -29.3em -9em;
}

.floor_up > span, .floor_down > span {
	display: none;
	position: absolute;
	width: 100%;
	left: 0;
	font-size: 1.4em;
	text-align: center;
	color: #5b7783;
}

.floor_up > span {
	bottom: 90%;
}
.floor_down > span {
	top: 90%;
}

.floor_up.active > span, .floor_down.active > span {
	display: block;
}

.apart__bubble {
	visibility: hidden;
	position: absolute;
	width: 9.4em;
	height: 9.4em;
	margin: -4.7em 0 0 -4.7em;
	opacity: 0;
	background-color: #ffffff;
	-webkit-border-radius: 5em;
	-moz-border-radius: 5em;
	border-radius: 5em;
	font-size: 0.8em;
	-webkit-transition: visibility .3s, opacity .3s;
	-moz-transition: visibility .3s, opacity .3s;
	-ms-transition: visibility .3s, opacity .3s;
	-o-transition: visibility .3s, opacity .3s;
	transition: visibility .3s, opacity .3s;
}

.apart__bubble.hover {
	visibility: visible;
	opacity: 1;
}

.apart__bubble:after {
	content: '';
	position: absolute;
	width: 3.2em;
	height: 3.2em;
	top: 50%;
	left: 50%;
	margin: -1.6em 0 0 -1.6em;
	background: url('../i/sprite.svg') -36.3em -4em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}

.apart__help-frame {
	position: absolute;
	right: 15%;
	bottom: 4em;
	left: 15%;
	text-align: center;
	white-space: nowrap;
}

.apart__help {
	display: inline-block;
	position: relative;
	padding-left: 3.5em;
	vertical-align: top;
}

.apart__help + .apart__help {
	margin-left: 4em;
}

.apart__help-icon {
	position: absolute;
	width: 1.6em;
	height: 1.6em;
	top: 50%;
	left: 0;
	margin-top: -0.8em;
	background-color: #4abbeb;
	-webkit-border-radius: 1em;
	-moz-border-radius: 1em;
	border-radius: 1em;
}

.apart__help > span {
	font-size: 1.7em;
	color: #5b7783;
}


.minimap_frame {
	position: absolute;
	width: 27em;
	height: 19.5em;
	top: 15%;
	right: 7em;
	margin-top: -10em;
	-webkit-transition: transform .3s ease-in-out;
	-moz-transition: transform .3s ease-in-out;
	-ms-transition: transform .3s ease-in-out;
	-o-transition: transform .3s ease-in-out;
	transition: transform .3s ease-in-out;
	/*-webkit-transition: left .3s ease-in-out;*/
	/*-moz-transition: left .3s ease-in-out;*/
	/*-ms-transition: left .3s ease-in-out;*/
	/*-o-transition: left .3s ease-in-out;*/
	/*transition: left .3s ease-in-out;*/
}

.minimap_frame:hover {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
}

.minimap_legend {
	position: absolute;
	width: 100%;
	top: 100%;
	left: 0;
	padding-top: 2em;
	white-space: nowrap;
}

.minimap_legend_item {
	display: inline-block;
	position: relative;
	padding-left: 2.5em;
	vertical-align: top;
}

.minimap_legend_item + .minimap_legend_item {
	margin-left: 2em;
}

.minimap_legend_item:before {
	content: '';
	position: absolute;
	width: 1.3em;
	height: 1.3em;
	top: 0;
	left: 0;
	box-sizing: border-box;
	border: 1px solid #6d777b;
	border-radius: 1em;
}
.minimap_legend_item.n1:before {
	background-color: #c0e5ff;
}
.minimap_legend_item.n2:before {
	background-color: #e0f2ff;
}
.minimap_legend_item.n3:before {
	background-color: #eff8ff;
}

.minimap_legend_item > span {
	display: inline-block;
	position: relative;
	font-size: 1.4em;
	line-height: 1.1;
	color: #6d777b;
}

/*.opened_apart .minimap_frame {
	left: 16em;
}*/

.quarter_map, .quarter_map_cont {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.floor_minimap_frame {
	position: absolute;
	width: 20em;
	height: 13.3em;
	top: 15%;
	right: 40em;
	margin-top: -6.65em;
	/*bottom: 40em;*/
	/*left: 19em;*/
}

.svg_area {
	z-index: 1;
}
.floor_map_cont .svg_area {
	z-index: auto;
}
.blur_bg, .floor_frame {
	z-index: 10;
	display: none;
}
.at_apart, .at_floor, .at_closed_floor {
	opacity: 0;
	visibility: hidden;
    -webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
    -moz-transition: opacity 0.3s ease, visibility 0.3s ease;
    -ms-transition: opacity 0.3s ease, visibility 0.3s ease;
    -o-transition: opacity 0.3s ease, visibility 0.3s ease;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}
.opened_floor .at_floor, .opened_apart .at_apart, .closed_floor .at_closed_floor, .apart_details_frame.active {
	opacity: 1;
	visibility: visible;
}
.plan_frame {
	bottom: 15%;
	left: 20em;
	position: absolute;
	right: 45em;
	top: 15%;
}

.plan_frame.n1 {
	top: 8%;
	right: 64em;
	bottom: 8%;

}

.parking_frame .plan_frame,
.storerooms_frame .plan_frame{
	left: 16em;
	right: 32em;
	-webkit-transition: -webkit-transform 300ms ease;
	-moz-transition: -moz-transform 300ms ease;
	-ms-transition: -ms-transform 300ms ease;
	-o-transition: -o-transform 300ms ease;
	transition: transform 300ms ease;
	-webkit-transform: translate(6em, 0);
	-moz-transform: translate(6em, 0);
	-ms-transform: translate(6em, 0);
	-o-transform: translate(6em, 0);
	transform: translate(6em, 0);
}

.parking_frame .opened_apart .plan_frame,
.storerooms_frame .opened_apart .plan_frame{
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
}

.plan_frame_centrer {
	position: absolute;
	top: 0;
	left: 0;
	background: url('../i/blank.gif');
}

.plan_frame_centrer_position {
	overflow: hidden;
	border: 1px solid rgba(126, 116, 96, 0);
	-webkit-transition: border-color .3s;
	-moz-transition: border-color .3s;
	-ms-transition: border-color .3s;
	-o-transition: border-color .3s;
	transition: border-color .3s;
}

.plan_frame_centrer_position.draggable {
	border: 1px solid #7e7460;
}

.plan_draggable {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	-webkit-transition: width .3s ease-in-out, height .3s ease-in-out, top .3s ease-in-out, width .3s ease-in-out;
	-moz-transition: width .3s ease-in-out, height .3s ease-in-out, top .3s ease-in-out, width .3s ease-in-out;
	-ms-transition: width .3s ease-in-out, height .3s ease-in-out, top .3s ease-in-out, width .3s ease-in-out;
	-o-transition: width .3s ease-in-out, height .3s ease-in-out, top .3s ease-in-out, width .3s ease-in-out;
	transition: width .3s ease-in-out, height .3s ease-in-out, top .3s ease-in-out, width .3s ease-in-out;
}


.plan_frame_centrer_position.draggable .plan_draggable {
	cursor: url('../i/cur1.cur'), pointer;
	width: 200%;
	height: 200%;
}
.parking_frame .plan_frame_centrer_position.draggable .plan_draggable,
.storerooms_frame .plan_frame_centrer_position.draggable .plan_draggable{
	cursor: url('../i/cur1.cur'), pointer;
	width: 275%;
	height: 275%;
}

.plan_draggable.dragging {
	cursor: url('../i/cur2.cur'), pointer;
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none;
}

.parking__zoom-button {
	position: absolute;
	width: 13.5em;
	height: 13.8em;
	top: 0;
	left: 0;
	cursor: pointer;
	background-color: #567cab;
	-webkit-transition: background-color .3s;
	-moz-transition: background-color .3s;
	-ms-transition: background-color .3s;
	-o-transition: background-color .3s;
	transition: background-color .3s;
}

.parking__zoom-button:hover {
	background-color: #5073a0;
}

.parking__zoom-button:before {
	content: 'Увеличить \Aплан';
	position: absolute;
	top: 2rem;
	left: 2rem;
	font-size: 1.2em;
	font-weight: normal;
	text-transform: uppercase;
	white-space: pre;
	color: #FFFFFF;
}

.parking__zoom-button.active:before {
	content: 'Уменьшить \Aплан';
}

.parking__zoom-button:after {
	content: '';
	position: absolute;
	width: 4.6em;
	height: 4.8em;
	right: 2em;
	bottom: 2em;
	background: url('../i/sprite.svg') -31.3em -34em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}

.parking__zoom-button.active:after {
	background-position: -31.3em -39.1em;
}

.apart_details_frame {
	position: absolute;
	width: 19.5em;
	top: 0;
	left: 0;
	/*top: 50%;*/
	/*left: 86%;*/
	/*margin-top: -28.3em;*/
}

.popup_details_row {
	position: relative;
	width: 19.5em;
	height: 13.8em;
	margin-bottom: 0.5em;
	/*background-color: #335665;*/

	&.hidden {
		display: none;
	}
}

.popup_details_row + .popup_details_row:before {
	content: '';
	position: absolute;
	width: 9.8em;
	height: 1px;
	bottom: 100%;
	left: 4em;
	margin-bottom: 0.2em;
	background-color: #e2e4ec;
}

.popup_details_row:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.popup_details_row.hidden{
	display: none!important;
}
.popup_details_row.n4 {
	/*background-color: #557e8f;*/
}
.popup_details_row.n5 .val{
	font-size:3.6em;
	color: #b84444;
}
.popup_details_row.n6 .val{
	font-size:2.6em;
}

.popup_details_row > div {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	margin-left: 4em;
}

.popup_details_row span, .popup_details_row .val {
	display: block;
	/*position: absolute;*/
	/*left: 2rem;*/
	white-space: normal;
	color: #6d777b;
}

.popup_details_row span {
	margin-bottom: 1em;
	font-size: 1.2em;
	font-weight: normal;
	text-transform: uppercase;
}

.popup_details_row .val {
	font-size: 5.9em;
	font-weight: bold;
	line-height: 1;
	white-space: nowrap;
}

.popup_details_row.n4 .val {
	font-size: 3.8em;
}

.not_sale_text {
	display: none;
	position: absolute;
	width: 100%;
	top: 20em;
	left: 0;
	font-size: 1.4em;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;
	color: #909090;
}
.apart_details_frame.not-sale .popup_details_row {
	display: none;
}
.apart_details_frame.not-sale .not_sale_text {
	display: block;
}

.parking_frame .apart_act_frame,
.storerooms_frame .apart_act_frame{
	top: 30%;
}
.apart_act_frame {
	position: absolute;
	top: 48%;
	right: 7em;
	margin-top: -7em;
}
.apart_act {
	position: relative;
	padding: 0.5em 3em 0.5em 0;
	cursor: pointer;
	font-size: 1.7em;
	text-align: right;
	text-decoration: underline;
	color: #009fe3;
}
.apart_act.reserve_btn {
	color: #fff;
	background-color: #009fe3;
	border-radius: 5em;
	text-decoration: none;
	transition: all .3s ease;
	padding: 0.5em 0 0.5em 0;
	text-align: center;
	margin-bottom: 1.4em;
}
.apart_act.reserve_btn:hover {
	background-color: #0294d2;
	transition: all .3s ease;
}
.apart_act.reserve_btn:before {
	display: none;
}
.apart_act.online_payment {
	color: #fff;
	background-color: #0fb633;
	border-radius: 5em;
	text-decoration: none;
	transition: all .3s ease;
	padding: 0.5em 3em 0.5em 3em;
	text-align: center;
}s
.apart_act.online_payment:hover {
	background-color: #13e240;
	transition: all .3s ease;
}
.apart_act.online_payment:before {
	display: none;
}
.parking_frame .apart_act, .storerooms_frame .apart_act,
.storerooms_frame .apart_act, .storerooms_frame .apart_act{
	padding: 0.5em 3em 0.5em 3em;
}
.commercial_frame .apart_act {
	margin-left: 3em;

	&.reserve_btn {
		width: 22.5rem;
	}

}

.apart_act:hover {
	text-decoration: none;
}

.apart_act + .apart_act {
	margin-top: 1.4em;
}

.apart_act:before {
	content: '';
	position: absolute;
	width: 3.5em;
	height: 3.5em;
	top: 50%;
	right: 0;
	margin-top: -1.85em;
	background: url('../i/sprite.svg');
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	font-size: 0.58em;
	-webkit-transition: -webkit-transform .3s;
	-moz-transition: -moz-transform .3s;
	-ms-transition: -ms-transform .3s;
	-o-transition: -o-transform .3s;
	transition: transform .3s;
}


.compare_btn:before {
	background-position: -67.2em -11.6em;
}
.compare_btn.active:before {
	background-position: -71.2em -11.6em;
}
.apart_act.fav_btn:before {
	background-position: -31.4em -7.9em;
}
.apart_act.fav_btn.active:before {
	background-position: -43.4em -7.9em;
}
/* .apart_act.reserve_btn:before {
	background-position: -35.4em -7.9em;
} */
.apart_act.callback_btn:before {
	background-position: -39.4em -7.9em;
}
.apart_act.calculator_btn:before {
	background-position: -75.4em -19em;
}
.apart_act.online_btn:before {
	width: 3.3rem;
	/* margin: -1.75em 0 0 -1.9em; */
	font-size: 0.48em;
	background-position: -86.9em -28.4em;
}

.apart_act:hover:before {
	-webkit-transform: translateX(-0.5em);
	-moz-transform: translateX(-0.5em);
	-ms-transform: translateX(-0.5em);
	-o-transform: translateX(-0.5em);
	transform: translateX(-0.5em);
}

.apart_levels_frame {
	display: none;
	position: absolute;
	right: 33em;
	bottom: 7%;
	left: 44em;
	z-index: 1;
	margin-bottom: -3.5em;
	text-align: center;
	white-space: nowrap;
}

.apart_levels_frame.visible {
	display: block;
}

.apart_levels_frame > span {
	display: inline-block;
	vertical-align: middle;
	font-size: 1.7em;
	color: #27292a;
}

.apart_levels_list {
	display: inline-block;
	margin-left: 2em;
	vertical-align: middle;
}

.apart_levels_item {
	display: inline-block;
	position: relative;
	width: 4em;
	height: 4em;
	cursor: pointer;
	text-align: center;
	white-space: nowrap;
}
.apart_levels_item.active {
	cursor: default;
}
.apart_levels_item + .apart_levels_item {
	margin-left: 0.5em;
}

.apart_levels_item:before {
	content: '';
	position: absolute;
	width: 3em;
	height: 3em;
	top: 50%;
	left: 50%;
	margin: -1.5em 0 0 -1.5em;
	opacity: 0.6;
	background-color: #27292a;
	-webkit-border-radius: 3em;
	-moz-border-radius: 3em;
	border-radius: 3em;
	-webkit-transition: opacity .3s, -webkit-transform .3s ease-in-out;
	-moz-transition: opacity .3s, -moz-transform .3s ease-in-out;
	-ms-transition: opacity .3s, -ms-transform .3s ease-in-out;
	-o-transition: opacity .3s, -o-transform .3s ease-in-out;
	transition: opacity .3s, transform .3s ease-in-out;
}
.apart_levels_item:hover:before {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
}
.apart_levels_item.active:before {
	opacity: 1;
}

.apart_levels_item:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.apart_levels_item > span {
	display: inline-block;
	position: relative;
	margin-bottom: -0.2em;
	vertical-align: middle;
	font-size: 1.6em;
	font-weight: bold;
	line-height: 1;
	color: #FFFFFF;
}

.fav_btn:after {
	content: 'Добавить в избранное';
}
.fav_btn.active:after {
	content: 'Удалить из избранного';
}
.compare_btn:after{
	content: 'Добавить к сравнению';
}
.compare_btn.active:after{
	content: 'Убрать из сравнения';
}

.apart_act-link{
	color: #2595de;
	cursor: pointer;
	margin-top: -0.75em;
	padding: 0.25em 0.5em 0.25em 5.1em;
	position: relative;
	text-decoration: underline;
	-webkit-transition: opacity 300ms linear, visibility 300ms linear;
	-moz-transition: opacity 300ms linear, visibility 300ms linear;
	-ms-transition: opacity 300ms linear, visibility 300ms linear;
	-o-transition: opacity 300ms linear, visibility 300ms linear;
	transition: opacity 300ms linear, visibility 300ms linear;
}

.apart_act-link.hidden{
	// opacity: 0;
	visibility: hidden;
}

.apart_act-link:hover{
	text-decoration: none;
}

.apart_act-link span{
	font-size: 1.2em;
	line-height: 1;
}

.commercial_frame {
	.attention {
		text-decoration: none;
		position: relative;
		margin-bottom: 2.5rem;
		display: flex;
		background: #0b51d1;
		box-sizing: border-box;
		padding: 1rem;
		align-items: center;
		justify-content: center;

		&:before {
			content: none;
		}

		.attention__icon {
			width: 4rem;
			margin-left: 2rem;
		}

		.attention__text {
			white-space: nowrap;
			font-size: 1.4em;
			color: #fff;
		}

	}
}


.pdf_btn {
	position: relative;
	width: 13.5em;
	height: 13.8em;
	margin: 1em 0 0 auto;
	cursor: pointer;
	background-color: rgba(85, 126, 143, 0);
	color: #557e8f;
	-webkit-transition: background-color .3s, color .3s;
	-moz-transition: background-color .3s, color .3s;
	-ms-transition: background-color .3s, color .3s;
	-o-transition: background-color .3s, color .3s;
	transition: background-color .3s, color .3s;
}

.pdf_btn:hover {
	background-color: #557e8f;
	color: #ffffff;
}

.pdf_btn:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 1px solid #557e8f;
}

.pdf_btn span {
	position: absolute;
	left: 2rem;
}
.pdf_btn span.n1 {
	top: 2rem;
	font-size: 1.2em;
	font-weight: normal;
	text-transform: uppercase;
	line-height: 1;
}
.pdf_btn span.n2 {
	bottom: 1rem;
	font-size: 4.5em;
	font-weight: bold;
	line-height: 1;
}

.apart_announce {
	background-color: #048dc8;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	left: 0;
	margin: 2.5em 0 2em;
	padding: 2em;
	position: relative;
	text-align: center;
	white-space: nowrap;
	width: 27.6em;
}

.apart_announce:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.apart_announce > div {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	font-size: 1.5em;
	color: #FFFFFF;
}


.favourites__frame .search_right {
	position: absolute;
	top: 15em;
	right: 15%;
	left: 15%;
	bottom: 5em;
}
.favourites__frame .search_bg td {
	height: 7.5rem;
	line-height: 2.5;
	width: 10%;
}
.favourites__frame .search_bg td.r_tc,
.favourites__frame .search_bg td.r_sc {
	width: 16%;
}
.favourites__frame .search_bg td.r_img {
	width: 6%;
}
.favourites__frame .search_bg td.r_fav_del {
	position: relative;
	width: 8%;
	height: 2.5em;
}

.favourites__frame .search_bg td.r_views{
	line-height: 3.8;
}

.favourites__frame .search_result_tab tr:hover .r_img {
	background-color: #ffffff;
}

.favourites__frame .search_header_tab td {
	padding: 0;
	line-height: 4;
	height: 6rem;
}
.favourites__frame .search_results {
	padding-top: 6.1em;
}

.fav-preview {
	position: absolute;
    width: 65%;
	top: 50%;
	left: 17.5%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}


.favourites__frame {
	position: absolute;
	width: 100%;
	height: 80%;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: #ffffff;
	-webkit-transition: -webkit-transform .8s ease-in-out;
	-moz-transition: -moz-transform .8s ease-in-out;
	-ms-transition: -ms-transform .8s ease-in-out;
	-o-transition: -o-transform .8s ease-in-out;
	transition: transform .8s ease-in-out;
}

.favourites__frame.hidden {
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-100%);
}

.search_header_tab td.inactive {
	cursor: default;
}
.fav-del-btn {
    position: absolute;
	width: 3.5em;
	height: 3.5em;
	top: 50%;
	left: 50%;
	margin: -1.75em 0 0 -1.75em;
	background: url('../i/sprite.svg') -47.4em -7.9em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	font-size: 0.5em;
}


.favourites__frame .search_result_tab tr:hover .fav-del-btn {
	background-position: -51.4em -7.9em;
}

.favourites__button {
	cursor: pointer;
	position: absolute;
	right: 1.8em;
	// margin-top: 19.8em;
	top: 22%;
	-webkit-transition: opacity 500ms linear, visibility 500ms linear, top 500ms ease;
	-moz-transition: opacity 500ms linear, visibility 500ms linear, top 500ms ease;
	-ms-transition: opacity 500ms linear, visibility 500ms linear, top 500ms ease;
	-o-transition: opacity 500ms linear, visibility 500ms linear, top 500ms ease;
	transition: opacity 500ms linear, visibility 500ms linear, top 500ms ease;
	z-index: 1;
}

body.home .favourites__button .compare__button {
	top: 0;
}

.favourites__button.hidden {
	opacity: 0;
	visibility: hidden;
}


.favourites__button-title{
	bottom: 100%;
	color: #5b7783;
	font-size: 1.7em;
	left: 50%;
	padding-right: 3em;
	position: absolute;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transform-origin: 0 50%;
	-moz-transform-origin: 0 50%;
	-ms-transform-origin: 0 50%;
	-o-transform-origin: 0 50%;
	transform-origin: 0 50%;
}

.favourites__button-count {
	background-color: #009fe3;
	-webkit-border-radius: 3em;
	-moz-border-radius: 3em;
	border-radius: 3em;
	height: 3.6em;
	position: relative;
	text-align: center;
	white-space: nowrap;
	width: 3.6em;
}

.favourites__button-count:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.favourites__button-count > span {
	color: #ffffff;
	display: inline-block;
	font-size: 1.8em;
	font-weight: bold;
	line-height: 1;
	vertical-align: middle;
	margin-top: 54%;
    margin-left: 19%;
	transform: translate(-50%, -50%);
}

.favourites__close .compare__close {
	height: 7.2em;
	left: auto;
	right: 0;
	width: 7.2em;
}
.compare__button {
	cursor: pointer;
	position: absolute;
	right: 1.8em;
	// margin-top: 18.5em;
	top: 40%;
	-webkit-transition: opacity 500ms linear, visibility 500ms linear, top 500ms ease;
	-moz-transition: opacity 500ms linear, visibility 500ms linear, top 500ms ease;
	-ms-transition: opacity 500ms linear, visibility 500ms linear, top 500ms ease;
	-o-transition: opacity 500ms linear, visibility 500ms linear, top 500ms ease;
	transition: opacity 500ms linear, visibility 500ms linear, top 500ms ease;
	z-index: 1;
}
body.news .favourites__button {
	top: 45rem;
}
body.placement .favourites__button {
	top: 34rem;
}
body.contacts .favourites__button {
	top: 34rem;
}
body.news .compare__button {
	top: 64rem;
}
body.placement .compare__button {
	top: 53rem;
}
body.contacts .compare__button {
	top: 53rem;
}
body.plans .favourites__button {
	opacity: 0;
}
body.plans .compare__button {
	opacity: 0;
}
.compare__button.hidden {
	opacity: 0;
	visibility: hidden;
}
body.compare .favourites__button {
	top: 34rem;
}
body.compare .compare__button {
	top: 52.5rem;
}

.compare__button-title {
	bottom: 100%;
	color: #5b7783;
	font-size: 1.7em;
	left: 50%;
	padding-right: 3em;
	position: absolute;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transform-origin: 0 50%;
	-moz-transform-origin: 0 50%;
	-ms-transform-origin: 0 50%;
	-o-transform-origin: 0 50%;
	transform-origin: 0 50%;
}

.compare__button-count {
	background-color: #009fe3;
	-webkit-border-radius: 3em;
	-moz-border-radius: 3em;
	border-radius: 3em;
	height: 3.6em;
	position: relative;
	text-align: center;
	white-space: nowrap;
	width: 3.6em;
}

.compare__button-count:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.compare__button-count>span {
	color: #ffffff;
	display: inline-block;
	font-size: 1.8em;
	font-weight: bold;
	line-height: 1;
	vertical-align: middle;
	margin-top: 54%;
    margin-left: 19%;
	transform: translate(-50%, -50%);
}

.compare__close {
	height: 7.2em;
	left: auto;
	right: 0;
	width: 7.2em;
}
/* обратная связь */

.reserve_popup,
.reserve_parking_popup,
.reserve_commercial_popup,
.question_popup,
.mortgage_popup {
	display: block;
	background-color: transparent;
}

.calculator_popup,
.visit_popup,
.callback_popup {
	background-color: rgba(0, 0, 0, 0.7);
}

.feedback_bg {
    position: absolute;
    width: 44em;
    height: 64em;
	top: 50%;
	left: 50%;
	margin: -28em 0 0 -22em;
}

.feedback_bg_wrapper,
.feedback_bg_inner {
	position: absolute;
	width: 44em;
	height: 64em;
	top: 0;
	left: 0;
	overflow: hidden;
}

.feedback_bg.n0 .feedback_bg_inner {
	background-color: #335665;
}

.feedback_bg.n1 .feedback_bg_inner {
    background-color: #27292a;
}

.feedback_bg.n2 .feedback_bg_inner {
	background-color: #557e8f;
}

.feedback_bg.n4 {
	width: 50em;
	height: 74em;
	margin: -37em 0 0 -25em;
	background-color: #27292a;
}

.feedback_inputs {
	left: 6.5em;
	right: 6.5em;
	text-align: center;
    width: auto;
}

.feedback_input_frame {
	position: relative;
    text-align: left;
	width: 100%;
}

.feedback_input_frame.hidden {
	display: none;
}

.feedback_input_frame + .feedback_input_frame {
	margin-top: 3em;
}

.feedback_input_frame.big {
	margin-top: 5em;
}

.feedback_bg input,
.feedback_bg textarea,
.feedback_bg label{
	background: none;
	border: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.47);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	color: #ffffff;
    display: block;
	font-family: 'Circe', Helvetica, Arial, sans-serif;
	font-size: 1.6em;
	line-height: 1;
	padding: 0.2em 0.5em 0.2em 0.5em;
    position: relative;
	text-align: center;
    width: 100%;
}

.feedback_bg textarea,
.feedback_bg textarea + label {
	border: 1px solid rgba(255, 255, 255, 0.47);
	height: 15em;
	padding: 1.5em;
	resize: none;
}

.feedback_bg label {
	background: #27292a;
	border-color: #ff5d62 !important;
	cursor: pointer;
	display: none;
    height: 100%;
	left: 0;
	position: absolute;
	top: 0;
}

.feedback_bg.n0 label {
	background: #335665;
}
.feedback_bg.n1 label {
	background: #27292a;
}
.feedback_bg.n2 label {
	background: #557e8f;
}

.feedback_bg ::-webkit-input-placeholder {
    color: #cad3d8;
}
.feedback_bg :-moz-placeholder {
    color: #cad3d8;
}
.feedback_bg ::-moz-placeholder {
    color: #cad3d8;
}
.feedback_bg :-ms-input-placeholder {
    color: #cad3d8;
}

.feedback_sended {
    display: none;
    left: 10%;
    right: 10%;
    width: auto;
}

.feedback_sended > div {
    color: #cad3d8;
	font-size: 1.6em;
}

.send_btn {
	color: #ffffff;
	cursor: pointer;
	font-size: 1.2em;
	display: inline-block;
	font-weight: bold;
    margin-top: 3em;
    text-decoration: underline;
    text-transform: uppercase;
}
.send_btn.white_btn {
	position: relative;
    padding: 0.6em 3em 0.5em 3em;
    border-radius: 5em;
	text-align: right;
	color: #333;
    font-size: 1.2em;
    font-weight: bold;
    text-decoration: none;
    background-color: #fff;
}

.feedback_bg.n2 .send_btn {
	margin-top: 6em;
}

.feedback_name {
	text-align: center;
	font-size: 2.6em;
	font-weight: bold;
	line-height: 0.9;
	margin-bottom: 1.5em;
	color: #ffffff;
}

.feedback_apart {
	position: relative;
	width: 13.5em;
	height: unset;
	margin: 2rem auto 4rem;
	padding: 1rem .4rem;
	background-color: #335665;
	box-sizing: border-box;
	cursor: default;

	&:after {
		display: none;
	}

	&.popup_details_row {
		span {
			position: static;
		}
		.val {
			margin: 0;
		}
	}
}

.feedback_apart > span {
	color: #ffffff;
	left: 2rem;
	position: absolute;
}

.feedback_apart .n2 {
	bottom: 1rem;
	font-size: 4.9em;
	font-weight: bold;
	line-height: 1;
}

.feedback_date,
.feedback_time{
	cursor: pointer;
}

.feedback_date:after,
.feedback_time:after{
	content: "";
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.feedback_bg .datepicker,
.feedback_time_dropdown{
	background-color: #5b7885;
	bottom: -6.5em;
	left: 0;
	/*opacity: 0;*/
	position: absolute;
	-webkit-transform: translate(0, 250%);
	-moz-transform: translate(0, 250%);
	-ms-transform: translate(0, 250%);
	-o-transform: translate(0, 250%);
	transform: translate(0, 250%);
	-webkit-transition: -webkit-transform 500ms ease, visibility 500ms linear, opacity 500ms linear;
	-moz-transition: -moz-transform 500ms ease, visibility 500ms linear, opacity 500ms linear;
	-ms-transition: -ms-transform 500ms ease, visibility 500ms linear, opacity 500ms linear;
	-o-transition: -o-transform 500ms ease, visibility 500ms linear, opacity 500ms linear;
	transition: transform 500ms ease, visibility 500ms linear, opacity 500ms linear;
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	visibility: hidden;
	width: 100%;
	z-index: 1;
}

.feedback_time_dropdown {
	bottom: -4.9rem;
}

.feedback_bg .datepicker.visible,
.feedback_time_dropdown.visible{
	/*opacity: 1;*/
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition-delay: 0ms;
	-moz-transition-delay: 0ms;
	-ms-transition-delay: 0ms;
	-o-transition-delay: 0ms;
	transition-delay: 0ms;
	visibility: visible;
}

.feedback_time_select{padding: 1em 0;}

.feedback_time_option{
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-size: 1.2em;
	line-height: 1.5;
	margin: 0.25em 0.2em;
	text-align: center;
	width: 2.7em;
}

.feedback_time_option:hover{
	outline: 1px solid #4f4f4f;
}

.feedback_time_option.selected{
	background-color: #009fe3;
}

/* ---------------------- Placement --------------------- */

.placement__map-frame {
	bottom: 0;
	left: 7.2em;
	position: absolute;
	right: 7.2em;
	top: 10em;
}

.placement__submenu {
	left: 100%;
	position: absolute;
	top: 0;
	white-space: nowrap;
}

.placement__submenu:after {
	clear: both;
	content: '';
	display: block;
}

.placement__submenu-item {
	background-color: #dee9f0;
	color: #27292a;
	display: inline-block;
	padding: 0 6.5em;
	width: auto;
	vertical-align: bottom;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	-webkit-transition: background-color .3s;
	-moz-transition: background-color .3s;
	-ms-transition: background-color .3s;
	-o-transition: background-color .3s;
	transition: background-color .3s;
}

.placement__submenu-item:hover {
	background-color: #eff1f2;
}

.placement__submenu-item.active {
	cursor: default;
	background-color: #009fe3;
}

.placement__submenu-item > span {
	font-size: 1.7em;
	line-height: 3.3;
	color: #5b7783;
}

.placement__submenu-item.active > span {
	color: #ffffff;
}

.placement__frame .about__text-frame,
.contacts__frame .about__text-frame,
.history_frame .about__text-frame {
	background-color: #5b7885;
}

.placement__frame .about__text-head,
.contacts__frame .about__text-head {
	text-align: left;
}

.placement__frame .about__text,
.placement__frame .about__text-head > div,
.contacts__frame .about__text,
.contacts__frame .about__text-head > div,
.history_frame .about__text,
.history_frame .about__text-head > div {
	color: #ebf9ff;
}

.placement__frame .about__text-head > div,
.contacts__frame .about__text-head > div {
	padding-left: 3em;
}

.placement__route-frame {
	position: absolute;
	width: 38em;
	bottom: 0;
	left: 53.8%;
	margin-bottom: 4em;
	background-color: #009fe3;
	-webkit-transform: translateY(4em);
	-moz-transform: translateY(4em);
	-ms-transform: translateY(4em);
	-o-transform: translateY(4em);
	transform: translateY(4em);
	-webkit-transition: bottom .3s ease-in-out, -webkit-transform .5s ease-in-out;
	-moz-transition: bottom .3s ease-in-out, -moz-transform .5s ease-in-out;
	-ms-transition: bottom .3s ease-in-out, -ms-transform .5s ease-in-out;
	-o-transition: bottom .3s ease-in-out, -o-transform .5s ease-in-out;
	transition: bottom .3s ease-in-out, transform .5s ease-in-out;
}

.placement__route-frame.folded {
	cursor: pointer;
	-webkit-transform: translateY(100%);
	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	-o-transform: translateY(100%);
	transform: translateY(100%);
}

.placement__route-frame.folded:hover {
	bottom: 3em;
}

.placement__route-head {
	position: relative;
	height: 4em;
	padding-left: 5.5em;
	white-space: nowrap;
}

.placement__route-head:before {
	content: '';
	position: absolute;
	width: 2.3em;
	height: 2.3em;
	top: 50%;
	left: 1.8em;
	margin-top: -1.25em;
	background: url('../i/sprite.svg') -39.7em -11.6em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}
.placement__route-head:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.placement__route-head > div {
	display: inline-block;
	vertical-align: middle;
	font-size: 1.2em;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1;
	color: #ffffff;
}

.placement__route-content {
	position: relative;
	padding: 3em 0;
}

.placement__route-content p {
	margin: 0;
	padding-left: 3em;
	font-size: 1.8em;
	line-height: 1.2;
	color: rgba(235, 249, 255, 0.72);
}

.placement__route-input {
	display: block;
	width: 100%;
	margin: 1.5em 0;
	padding: 1em 0;
	background-color: #26ade7;
	border: none;
	font-size: 2em;
	font-family: 'Circe', Helvetica, Arial, sans-serif;
	font-weight: normal;
	text-align: center;
	color: #ffffff;
}

.placement__route-content ::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.85);
}
.placement__route-content :-moz-placeholder {
	color: rgba(255, 255, 255, 0.85);
}
.placement__route-content ::-moz-placeholder {
	color: rgba(255, 255, 255, 0.85);
}
.placement__route-content :-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.85);
}

.placement__route-submit {
	width: 10em;
	margin: 0 auto;
	cursor: pointer;
	font-size: 1.2em;
	font-weight: bold;
	text-align: center;
	text-decoration: underline;
	text-transform: uppercase;
	color: #ffffff;
}

.placement__route-submit:hover {
	text-decoration: none;
}


/* ------------------------------------------------------ */

/* -------------------- Infrastructure ------------------ */


.infrastructure__groups {
	position: absolute;
	width: 14.4em;
	top: 0;
	bottom: 0;
	right: -7.2em;
	background-color: #ffffff;
}

/*.infrastructure__groups:before {*/
	/*content: '';*/
	/*display: inline-block;*/
	/*width: 0;*/
	/*height: 100%;*/
	/*vertical-align: middle;*/
/*}*/

/*.infrastructure__groups-list {*/
	/*display: inline-block;*/
	/*position: relative;*/
	/*vertical-align: middle;*/
	/*font-size: 1rem;*/
/*}*/

.infrastructure__groups-list {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -25rem;
	font-size: 0;
	text-align: right;
	white-space: nowrap;
}

.infrastructure__group {
	display: inline-block;
	position: relative;
	padding-right: 7.2em;
	height: 7.2em;
	vertical-align: top;
	background-color: #ffffff;
	cursor: pointer;
	font-size: 1rem;
	-webkit-transition: background-color .3s;
	-moz-transition: background-color .3s;
	-ms-transition: background-color .3s;
	-o-transition: background-color .3s;
	transition: background-color .3s;
}

.infrastructure__group.even {
	background-color: #fbfbfb;
}

.infrastructure__group.inactive {
	visibility: hidden;
}

.infrastructure__group-icon {
	position: absolute;
	width: 4em;
	height: 4em;
	top: 1.6em;
	right: 1.6em;
	background: url('../i/infra_sprite.svg');
	-webkit-background-size: 100em 20em;
	background-size: 100em 20em;
}

.infrastructure__group-text {
	position: relative;
	max-width: 0;
	height: 100%;
	overflow: hidden;
	white-space: nowrap;
	-webkit-transition: max-width .5s;
	-moz-transition: max-width .5s;
	-ms-transition: max-width .5s;
	-o-transition: max-width .5s;
	transition: max-width .5s;
}

.infrastructure__group:hover .infrastructure__group-text {
	max-width: 25em;
}

.infrastructure__group-text:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.infrastructure__group-text > span {
	display: inline-block;
	margin-top: 0.15em;
	padding: 0 3em 0 2em;
	font-size: 1.2em;
	font-weight: bold;
	text-transform: uppercase;
	color: #ffffff;
}

.infrastructure__group.n0:hover, .infrastructure__group.n0.active, .gmaps_color.n0 {
	 background-color: #648ec2;
}
.infrastructure__group.n1:hover, .infrastructure__group.n1.active, .gmaps_color.n1 {
	background-color: #279e50;
}
.infrastructure__group.n2:hover, .infrastructure__group.n2.active, .gmaps_color.n2 {
	background-color: #b164c2;
}
.infrastructure__group.n3:hover, .infrastructure__group.n3.active, .gmaps_color.n3 {
	background-color: #da9340;
}
.infrastructure__group.n4:hover, .infrastructure__group.n4.active, .gmaps_color.n4 {
	background-color: #ca694e;
}
.infrastructure__group.n5:hover, .infrastructure__group.n5.active, .gmaps_color.n5 {
	background-color: #d0b15e;
}
.infrastructure__group.n6:hover, .infrastructure__group.n6.active, .gmaps_color.n6 {
	background-color: #5f59c7;
}
.infrastructure__group.n7:hover, .infrastructure__group.n7.active, .gmaps_color.n7 {
	background-color: #a24242;
}
.infrastructure__group.n8:hover, .infrastructure__group.n8.active, .gmaps_color.n8 {
	background-color: #c4cb4b;
}
.infrastructure__group.n9:hover, .infrastructure__group.n9.active, .gmaps_color.n9 {
	background-color: #4eb9a4;
}
.infrastructure__group.n10:hover, .infrastructure__group.n10.active, .gmaps_color.n10 {
	background-color: #e2b34e;
}
.infrastructure__group.n11:hover, .infrastructure__group.n11.active, .gmaps_color.n11 {
	background-color: #43bd45;
}
.infrastructure__group.n12:hover, .infrastructure__group.n12.active, .gmaps_color.n12 {
	background-color: #5391da;
}

.infrastructure__group-icon.n0 {
	background-position: -0.2em -0.2em;
}
.infrastructure__group-icon.n1 {
	background-position: -4.7em -0.2em;
}
.infrastructure__group-icon.n2 {
	background-position: -9.2em -0.2em;
}
.infrastructure__group-icon.n3 {
	background-position: -13.7em -0.2em;
}
.infrastructure__group-icon.n4 {
	background-position: -18.2em -0.2em;
}
.infrastructure__group-icon.n5 {
	background-position: -22.7em -0.2em;
}
.infrastructure__group-icon.n6 {
	background-position: -27.2em -0.2em;
}
.infrastructure__group-icon.n7 {
	background-position: -31.7em -0.2em;
}
.infrastructure__group-icon.n8 {
	background-position: -36.2em -0.2em;
}
.infrastructure__group-icon.n9 {
	background-position: -40.7em -0.2em;
}
.infrastructure__group-icon.n10 {
	background-position: -45.2em -0.2em;
}
.infrastructure__group-icon.n11 {
	background-position: -49.7em -0.2em;
}
.infrastructure__group-icon.n12 {
	background-position: -54.2em -0.2em;
}

.infrastructure__group:hover .infrastructure__group-icon.n0, .infrastructure__group.active .infrastructure__group-icon.n0, .gmaps_icon.n0 {
	background-position: -0.2em -4.7em;
}
.infrastructure__group:hover .infrastructure__group-icon.n1, .infrastructure__group.active .infrastructure__group-icon.n1, .gmaps_icon.n1 {
	background-position: -4.7em -4.7em;
}
.infrastructure__group:hover .infrastructure__group-icon.n2, .infrastructure__group.active .infrastructure__group-icon.n2, .gmaps_icon.n2 {
	background-position: -9.2em -4.7em;
}
.infrastructure__group:hover .infrastructure__group-icon.n3, .infrastructure__group.active .infrastructure__group-icon.n3, .gmaps_icon.n3 {
	background-position: -13.7em -4.7em;
}
.infrastructure__group:hover .infrastructure__group-icon.n4, .infrastructure__group.active .infrastructure__group-icon.n4, .gmaps_icon.n4 {
	background-position: -18.2em -4.7em;
}
.infrastructure__group:hover .infrastructure__group-icon.n5, .infrastructure__group.active .infrastructure__group-icon.n5, .gmaps_icon.n5 {
	background-position: -22.7em -4.7em;
}
.infrastructure__group:hover .infrastructure__group-icon.n6, .infrastructure__group.active .infrastructure__group-icon.n6, .gmaps_icon.n6 {
	background-position: -27.2em -4.7em;
}
.infrastructure__group:hover .infrastructure__group-icon.n7, .infrastructure__group.active .infrastructure__group-icon.n7, .gmaps_icon.n7 {
	background-position: -31.7em -4.7em;
}
.infrastructure__group:hover .infrastructure__group-icon.n8, .infrastructure__group.active .infrastructure__group-icon.n8, .gmaps_icon.n8 {
	background-position: -36.2em -4.7em;
}
.infrastructure__group:hover .infrastructure__group-icon.n9, .infrastructure__group.active .infrastructure__group-icon.n9, .gmaps_icon.n9 {
	background-position: -40.7em -4.7em;
}
.infrastructure__group:hover .infrastructure__group-icon.n10, .infrastructure__group.active .infrastructure__group-icon.n10, .gmaps_icon.n10 {
	background-position: -45.2em -4.7em;
}
.infrastructure__group:hover .infrastructure__group-icon.n11, .infrastructure__group.active .infrastructure__group-icon.n11, .gmaps_icon.n11 {
	background-position: -49.7em -4.7em;
}
.infrastructure__group:hover .infrastructure__group-icon.n12, .infrastructure__group.active .infrastructure__group-icon.n12, .gmaps_icon.n12 {
	background-position: -54.2em -4.7em;
}


.gmaps_labels {
	overflow: visible !important;
}

.gmaps_icon {
	position: absolute;
	width: 40px;
	height: 40px;
	top: -20px;
	left: -20px;
	background-image: url('../i/infra_sprite.png');
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}

.gmaps_labels_span {
	visibility: hidden;
	position: absolute;
	height: 40px;
	top: -20px;
	left: -20px;
	padding: 0 15px 0 25px;
	opacity: 0;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	white-space: nowrap;
	-webkit-transition: visibility .4s ease-in-out, opacity .4s ease-in-out, padding-left .4s ease-in-out;
	-moz-transition: visibility .4s ease-in-out, opacity .4s ease-in-out, padding-left .4s ease-in-out;
	-ms-transition: visibility .4s ease-in-out, opacity .4s ease-in-out, padding-left .4s ease-in-out;
	-o-transition: visibility .4s ease-in-out, opacity .4s ease-in-out, padding-left .4s ease-in-out;
	transition: visibility .4s ease-in-out, opacity .4s ease-in-out, padding-left .4s ease-in-out;
}

.gmaps_labels.hover .gmaps_labels_span {
	visibility: visible;
	opacity: 1;
	padding-left: 50px;
}

.gmaps_labels_span:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.gmaps_labels_span > span {
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
	color: #ffffff;
}

/* ------------------------------------------------------ */


/* --------------------- Contacts ----------------------- */

.contacts__frame .about__text-frame {
	right: 0;
	top: 4em ;
}

.contacts__frame .about__text-frame.folded{
	top: 100%;
}

.contacts__frame .about__text-content {
	position: relative;
    margin: 5.2rem;
}

.contacts__frame .textScroll__track {
	height: auto;
	top: 7em;
	bottom: 0;
	margin-left: 5.8em;
}

.contacts__image {
	display: block;
	position: relative;
	width: 100%;
	cursor: pointer;
	font-size: 1rem;
}

.contacts__image + .contacts__image {
	margin-top: 2em;
}

.contacts__image:after {
	content: '';
	position: absolute;
	width: 9em;
	height: 9em;
	top: 50%;
	left: 50%;
	margin: -4.5em 0 0 -4.5em;
	opacity: 0;
	background: url('../i/sprite.svg') -0.1em -15.1em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	-webkit-transform: translateY(50%);
	-moz-transform: translateY(50%);
	-ms-transform: translateY(50%);
	-o-transform: translateY(50%);
	transform: translateY(50%);
	-webkit-transition: opacity .3s ease-in-out, -webkit-transform .3s ease-in-out;
	-moz-transition: opacity .3s ease-in-out, -moz-transform .3s ease-in-out;
	-ms-transition: opacity .3s ease-in-out, -ms-transform .3s ease-in-out;
	-o-transition: opacity .3s ease-in-out, -o-transform .3s ease-in-out;
	transition: opacity .3s ease-in-out, transform .3s ease-in-out;
}

.contacts__image:hover:after {
	opacity: 1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}

/* --------------------------------------------------------- */


/* -------------------- Construction ----------------------- */


.construction__title strong{
	font-size: 2.39em;
}

.construction__column{
	bottom: 0;
	margin-top: 5.6em;
	position: absolute;
	right: 7.2em;
	top: 10em;
	width: 43.6em;
}

.construction__albums:after{
	content: "";
	display: block;
	height: 0;
	position: relative;
	width: 100%;
}

.construction__album{
	color: #fff;
	cursor: pointer;
	float: left;
	height: 21.8em;;
	margin: 0 0 0.6em 0.6em;
	overflow: hidden;
	position: relative;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	white-space: nowrap;
	width: 21.2em;
}

.construction__album .preview{
	background: url(../i/blank.gif) no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	-webkit-transition: -webkit-transform 500ms ease;
	-moz-transition: -moz-transform 500ms ease;
	-ms-transition: -ms-transform 500ms ease;
	-o-transition: -o-transform 500ms ease;
	transition: transform 500ms ease;
}

.construction__album:hover .preview{
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
}

.construction__album .preview:after{
	background-color: rgba(39, 41, 42, 0.34);
	content: "";
	display: block;
	height: 100%;
	position: relative;
	width: 100%;
}

.construction__album .overlay{
	background-color: #0299d9;
	-webkit-transform: rotate(270deg) scale(0);
	-moz-transform: rotate(270deg) scale(0);
	-ms-transform: rotate(270deg) scale(0);
	-o-transform: rotate(270deg) scale(0);
	transform: rotate(270deg) scale(0);
	-webkit-transition: -webkit-transform 500ms ease, opacity 500ms linear;
	-moz-transition: -moz-transform 500ms ease, opacity 500ms linear;
	-ms-transition: -ms-transform 500ms ease, opacity 500ms linear;
	-o-transition: -o-transform 500ms ease, opacity 500ms linear;
	transition: transform 500ms ease, opacity 500ms linear;
}

.construction__album.active .overlay{
	opacity: 1;
	-webkit-transform: rotate(0deg) scale(1);
	-moz-transform: rotate(0deg) scale(1);
	-ms-transform: rotate(0deg) scale(1);
	-o-transform: rotate(0deg) scale(1);
	transform: rotate(0deg) scale(1);
}

.construction__album:before{
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: bottom;
	width: 0;
}

.construction__album .description{
	display: inline-block;
	padding: 2em;
	position: relative;
	vertical-align: bottom;
	white-space: normal;
}

.construction__album .month{
	font-size: 4.1em;
	font-weight: bold;
	letter-spacing: -0.05em;
	line-height: 1;
	text-transform: capitalize;
}

.construction__album .year{
	font-size: 2em;
	font-weight: bold;
	letter-spacing: -0.05em;
	line-height: 1;
}

.construction__years{
	bottom: 100%;
	left: 0.6em;
	position: absolute;
	width: 21.2em;
	z-index: 1;
}

.construction__year{
	cursor: pointer;
	display: inline-block;
	margin-left: 0.6em;
	text-align: center;
	text-decoration: none;
	vertical-align: bottom;
	width: 11.9em;
}

.construction__year.active{
	background-color: #009fe3;
	cursor: default;
}

.construction__year span{
	color: #5b7783;
	font-size: 1.7em;
	font-weight: 200;
	line-height: 3.3;
}

.construction__year.active span{
	color: #fff;
}

.construction__gallery{
	bottom: 0;
	left: 7.2em;
	opacity: 0;
	position: absolute;
	right: 50.8em;
	top: 10em;
	-webkit-transform: translate(0, 100%);
	-moz-transform: translate(0, 100%);
	-ms-transform: translate(0, 100%);
	-o-transform: translate(0, 100%);
	transform: translate(0, 100%);
	-webkit-transition: -webkit-transform 750ms ease, opacity 750ms linear, visibility 750ms linear;
	-moz-transition: -moz-transform 750ms ease, opacity 750ms linear, visibility 750ms linear;
	-ms-transition: -ms-transform 750ms ease, opacity 750ms linear, visibility 750ms linear;
	-o-transition: -o-transform 750ms ease, opacity 750ms linear, visibility 750ms linear;
	transition: transform 750ms ease, opacity 750ms linear, visibility 750ms linear;
	visibility: hidden;
}

.construction__gallery.visible {
	opacity: 1;
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	visibility: visible;
}

/*
.construction__gallery .content {
	background-color: #009fe3;
	bottom: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	color: #fff;
	height: 14.1em;
	padding: 2.2em 2.5em;
	position: absolute;
	right: 0;
	width: 38.4em;
}
*/

.construction__gallery .content {
	position: absolute;
	width: 14.4em;
	height: 7.2em;
	right: 0;
	bottom: 0;
	background-color: #009fe3;
	text-align: center;
	white-space: nowrap;
	color: #FFFFFF;
}

.construction__gallery .content:before {
	content: '';
	display: inline-block;
	position: relative;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.construction__gallery .date{
	display: inline-block;
	margin-top: 0.5em;
	vertical-align: middle;
	font-size: 1.2em;
	font-weight: bold;
	line-height: 1;
}

.construction__gallery .date strong{
	font-size: 4.33em;
	line-height: 1;
}

.construction__gallery .description{
	display: none;
	color: #ebf9ff;
	font-size: 1.8em;
	font-weight: 200;
	margin-top: 0.2em;
}

.construction_frame .gallery_dots_frame{
	bottom: 0;
	left: 0;
}

.construction__gallery .g_btn {
	top: auto;
	right: 7.2em;
	bottom: 7.2em;
}

.construction__gallery .g_btn.right {
	right: 0;
	left: auto;
}

.construction__gallery .gallery_title_frame{
	display: none;
}


.mop2_frame .gallery_title_frame {
	display: none;
	position: absolute;
	top: 0;
	left: 28.5%;
	height: 1%;
	background-color: #364150;
    font-family: 'Circe', Helvetica, Arial, sans-serif;
    font-variant: normal;
    font-weight: 600;
}
.mop2_frame .gallery_title_frame .gallery_title {
	font-size: 2em;
	display: none;
	background-color: #364150;
}



.construction__korpus{
	background-color: #27292a;
	cursor: pointer;
	height: 5.5em;
	overflow: visible;
	position: absolute;
	top: 0;
	right: 0;
}

.construction__korpus:after{
	border-color: #fff transparent transparent transparent;
	border-style: solid;
	border-width: 0.9em 0.5em 0 0.5em;
	content: "";
	display: block;
	height: 0;
	margin: -0.45em -0.25em 0 0;
	position: absolute;
	right: 3.5em;
	top: 50%;
	-webkit-transition: -webkit-transform 300ms ease;
	-moz-transition: -moz-transform 300ms ease;
	-ms-transition: -ms-transform 300ms ease;
	-o-transition: -o-transform 300ms ease;
	transition: transform 300ms ease;
	width: 0;
}

.construction__korpus:hover:after{
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}

.construction__korpus-selected{
	line-height: 5.5em;
}

.construction__korpus-dropdown{
	background-color: #27292a;
	margin-top: -1px;
	max-height: 0;
	overflow: hidden;
	position: relative;
	-webkit-transition: max-height 500ms ease;
	-moz-transition: max-height 500ms ease;
	-ms-transition: max-height 500ms ease;
	-o-transition: max-height 500ms ease;
	transition: max-height 500ms ease;
}

.construction__korpus:hover .construction__korpus-dropdown{
	max-height: 75em;
}

.construction__korpus-option{
	background-color: transparent;
	cursor: pointer;
	-webkit-transition: background 300ms linear;
	-moz-transition: background 300ms linear;
	-ms-transition: background 300ms linear;
	-o-transition: background 300ms linear;
	transition: background 300ms linear;
	white-space: nowrap;
}

.construction__korpus-option:hover{
	background-color: rgba(2, 153, 217, 0.7);
}

.construction__korpus-option.active{
	background-color: rgba(2, 153, 217, 1);
}

.construction__korpus-selected:before,
.construction__korpus-option:before{
	content: "";
	display: inline-block;
	vertical-align: middle;
	width: 0;
}

.construction__korpus-selected:before{
	height: 5.5em;
}

.construction__korpus-option:before{
	height: 5em;
}

.construction__korpus .label{
	color: #fff;
	display: inline-block;
	font-family: "Circe", Helvetica, Arial, sans-serif;
	font-size: 1.7em;
	padding: 0 4.1em 0 2.35em;
	vertical-align: middle;
	white-space: normal;
}

.webcam_open, .mop_open {
	white-space: nowrap;
	padding: 1em 2.5em;
	color: #fff;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 100%;
	background-color: #364150;
	font-family: 'Circe', Helvetica, Arial, sans-serif;
	font-variant: normal;
	font-weight: 600;
	font-size: 1.8em;
	padding-left: 4em;
	-webkit-transition: all 100ms linear;
	-moz-transition: all 100ms linear;
	-ms-transition: all 100ms linear;
	-o-transition: all 100ms linear;
	transition: all 100ms linear;
}
.mop_open {
	display: block;
	padding-left: 0;
	padding: 1em 1.5em;
	text-decoration: none;
}
.webcam_open:hover, .mop_open:hover{
	background: #0299d9;
	color: #fff;
}
.webcam_open:before{
	content: '';
	display: block;
	position: absolute;
	width: 2.6em;
	height: 3.1em;
	font-size: 0.6em;
	top: 50%;
	margin-top: -1.55em;
	left: 2.2em;
	background: url(../i/sprite.svg?v17) -75.8em -15.5em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}
.webcam__popup{
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.webcam_wrapper{
	display: block;
	height: 80%;
	left: 15%;
	position: absolute;
	top: 10%;
	width: 70%;
}
.webcam_popup_center{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
.close_btn.webcam_close {
	background-color: #dcdcdc;
	cursor: pointer;
	height: 5.6em;
	left: 85%;
	position: absolute;
	top: 10%;
	-webkit-transition: background-color .3s;
	-moz-transition: background-color .3s;
	-ms-transition: background-color .3s;
	-o-transition: background-color .3s;
	transition: background-color .3s;
	width: 5.6em;
}

/* --------------------------------------------------------- */


/* ------------------------- News -------------------------- */
.news_content{
	background-color: #414b4f;
	bottom: 0;
	left: 30em;
	margin-top: 5.6em;
	position: absolute;
	top: 10em;
	width: 46.4em;
}



.news_content > .browser_scroll {
	height: 100%;
}

.news_menu{
	bottom: 100%;
	position: absolute;
	right: 0;
	text-align: left;
	white-space: nowrap;
	width: 100%;

}



.news_menu_item{
	cursor: pointer;
	display: inline-block;
	margin: 0;
	text-align: center;
	text-decoration: none;
	vertical-align: bottom;
	width: 33.33%;
}

.news_menu_item.active{
	background-color: #009fe3;
	cursor: default;
}

.news_menu_item span{
	color: #5b7783;
	font-size: 1.7em;
	line-height: 3.3;
}

.news_menu_item h1{
	font-size: 1em;
	line-height: 3.3;
	font-weight: 300;
}

.news_menu_item.active span{
	color: #fff;
}

.news_scroll{
	padding: 6.5em 0 2em 0;
}

.news_page_title{
	padding: 0 0 2.3em 0;
	position: relative;
	text-align: center;
}

.news_page_title:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
	display: block;
	width: 3.5em;
	height: 0.4em;
	margin-left: -1.75em;
	background-color: #009fe3;
}

.news_page_title span {
	font-size: 3.54em;
	line-height: 1;
	font-variant: small-caps;
	letter-spacing: -0.05em;
	color: #fff;
}

.news_list {
	margin-top: 3.6em;
}

.news_item {
	position: relative;
	display: block;
	padding: 3.4em 5.8em 2.4em 5.8em;
	text-decoration: none;
	cursor: pointer;
}

.news_item--promos[data-targ="commercial, living"],
.news_item--promos[data-targ="living, commercial"] {
	.news_realty_types {
		display: none;
	}
}

.news_item:before{
	background-color: rgba(0, 0, 0, 0);
	content: "";
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	-webkit-transition: background 200ms linear;
	-moz-transition: background 200ms linear;
	-ms-transition: background 200ms linear;
	-o-transition: background 200ms linear;
	transition: background 200ms linear;
	width: 100%;
}

.news_item:hover:before{
	background-color: rgba(0, 0, 0, 0.15);
}

.news_item.active:before{
	background-color: #0095d5;
}

.news_item:after{
	background-color: rgba(255, 255, 255, 0.3);
	bottom: 0;
	content: "";
	height: 1px;
	left: 4.3em;
	position: absolute;
	right: 4.3em;
}

.news_date{
	background: #009fe3;
	display: inline-block;
	left: -1.5em;
	padding: 0.9em 1.5em 0.5em 1.5em;
	position: relative;
}

.news_date span{
	color: rgba(255, 255, 255, 0.77);
	font-size: 1.6em;
	font-weight: normal;
	line-height: 1;
}

.news_title {
	color: rgba(255, 255, 255, 0.77);
	display: block;
	font-size: 2em;
	line-height: 1.15;
	margin: 1em 0;
	position: relative;
	text-decoration: underline;
	font-weight: bold;
	margin-top: 2em;
}
.news_title.short{
	font-size: 1.6em;
	font-weight: normal;
	margin-top: -0.7em;
	text-decoration: none;
}
.article_title.short{
	font-size: 2em;
	margin-top: -0.5em;
}
.news_source {
	position: relative;
	font-size: 1.5em;
	font-weight: bold;
	color: rgba(255, 255, 255, 0.9);
}

.news_realty_types{
	position: absolute;
	right: 4.3em;
	top: 2.5em;
	white-space: nowrap;
}

.news_realty_type{
	background: #567cab;
	display: inline-block;
	margin-left: 1em;
	padding: 0.9em 1.5em 0.5em 1.5em;
	position: relative;
}

.news_item.active .news_realty_type{
	background: #414b4f;
}

.news_realty_type span{
	color: rgba(255, 255, 255, 0.77);
	font-size: 1.6em;
	font-weight: normal;
	line-height: 1;
}

.news_popup{
	background-color: #fff;
	bottom: 0;
	left: 78.8em;
	margin-top: 5.6em;
	/*opacity: 0;*/
	position: absolute;
	right: 7.2em;
	top: 10em;
	-webkit-transform: translate(105%, 0);
	-moz-transform: translate(105%, 0);
	-ms-transform: translate(105%, 0);
	-o-transform: translate(105%, 0);
	transform: translate(105%, 0);
	-webkit-transition: -webkit-transform 1000ms ease, opacity 1000ms linear, visibility 1000ms linear;
	-moz-transition: -moz-transform 1000ms ease, opacity 1000ms linear, visibility 1000ms linear;
	-ms-transition: -ms-transform 1000ms ease, opacity 1000ms linear, visibility 1000ms linear;
	-o-transition: -o-transform 1000ms ease, opacity 1000ms linear, visibility 1000ms linear;
	transition: transform 1000ms ease, opacity 1000ms linear, visibility 1000ms linear;
	visibility: hidden;
}

.news_popup.visible{
	opacity: 1;
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	visibility: visible;
}

.news_popup .text_scroll{
	bottom: 0;
	height: auto;
	left: 6.3em;
	position: absolute;
	right: 1.6em;
	top: 0;
	width: auto;
}

.news_popup .vertical__arrows-frame{
	top: 4.5em;
}

.news_popup .news_realty_types{
	display: inline-block;
	left: -1.5em;
	position: relative;
	right: auto;
	top: auto;
	vertical-align: top;
}

.article_content{
	padding: 0 1.5em 2em 1.5em;
	position: relative;
}

.article_title{
	color: #27292a;
	font-weight: normal;
	font-size: 3.8em;
	letter-spacing: -0.05em;
	line-height: 1.05;
	margin: 0.8em 0 0.5em;
}

.article_title h1{
	font-weight: normal;
	font-size: 1em;
	letter-spacing: -0.05em;
	line-height: 1.05;
	margin: 0.8em 0 1em;
}

.article_source {
	margin-top: 1.5em;
	font-size: 1.8em;
	font-weight: bold;
	color: #5b7783;
}

.article_text{
	color: #5b7783;
}

.article_text p,
.article_text ul {
	font-size: 1.9em;
	line-height: 1.15;
}

.article_text ul ul {
	font-size: 1em;
}

.article_text li + li {
	margin-top: 0.5em;
}

.article_text img{
	display: block;
	margin: 3.8em 0;
	max-width: 100%;
}

.article_text table{
	border-collapse: collapse;
	margin: 2em 0;
	outline: none;
	width: 100%;
}

.article_text td,
.article_text th{
	border: 1px solid #5b7783;
	font-size: 1.9em;
	line-height: 1.15;
	padding: 0.75em;
	text-align: center;
}

.article_text th{
	background-color: #009fe3;
	color: #fff;
	font-weight: bold;
}

.news_img_tmb{
	cursor: pointer;
	display: inline-block;
	margin: 0 0.5em 0.5em 0;
	vertical-align: top;
}

.news_frame .gallery_popup{
	background-color: #fff;
}

.filter_buttons {
	position: relative;
	display: inline-block;
	left: .6em;
    color: rgba(255, 255, 255, 0.77);
    font-size: 1.4em;
    font-weight: normal;
    line-height: 1;
    background: #567cab;
    margin: 2em 0 -.5em 1em;
	padding: 0.6em 1.5em 0.5em 1.5em;
	transition: all .2s ease;
	&.disabled {
		pointer-events: none;
	}
}

.filter_buttons:hover {
	cursor: pointer;
	background: #038bc5;
	transition: all .2s ease;
}

.filter_buttons.active {
	background: #009fe3;
	transition: all .2s ease;
}

.news_item.hidden {
	display: none;
}

/* --------------------------------------------------------- */


/* ------------------------- Vtour ------------------------- */

.vtour__frame{
	bottom: 0;
	left: 7.2em;
	position: absolute;
	right: 7.2em;
	top: 10em;
}

.vtour__title{
	background-color: #ffffff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	height: 12em;
	left: 0;
	padding: 0 4.5em;
	position: absolute;
	top: 0;
}

.vtour__title:before{
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.vtour__title span{
	color: #27292a;
	display: inline-block;
	font-size: 4.3em;
	font-variant: small-caps;
	font-weight: normal;
	line-height: 0.9;
	vertical-align: middle;
}

.vtour__body{
	height: 100%;
	position: relative;
	width: 100%;
}

.vtour__image-frame{
	overflow: hidden;
}

.vtour__image-size{
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.vtour_frame .windrose{
	left: auto;
	right: 6em;
}

.pano_open_btn{
	background: url('../i/sprite.svg') -68.9em -0.1em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	cursor: pointer;
	left: 50%;
	height: 6.7em;
	margin: -6.7em -2.45em;
	position: absolute;
	top: 50%;
	width: 4.9em;
}

.pano_open_btn:hover {
	background-position: -74.4em -0.1em;
}

.pano_open_btn.active {
	opacity: 0.8;
}

.pano_open_btn_num {
	display: none;
}

.pano_open_btn_view {
	position: absolute;
	width: 10.2em;
	height: 10.2em;
	margin: -5.1em;
	top: 0;
	left: 0;
	background: url(../i/sprite.svg) -11.4em -31em;
	background-size: 100em;
}

.pano_open_btn_active_frame {
	top: 5.9em;
	left: 3.7em;
	position: absolute;
	opacity: 0;
	visibility: hidden;
}

.pano_open_btn.active .pano_open_btn_active_frame {
	opacity: 1;
	visibility: visible;
}

.pano_place.off:after {
	content: '';
	background: url('../i/blank.gif');
	display: block;
	z-index: 1;
}

.pano_content_frame {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	-webkit-transform: translate(100%, 0);
	-moz-transform: translate(100%, 0);
	-ms-transform: translate(100%, 0);
	-o-transform: translate(100%, 0);
	transform: translate(100%, 0);
	-webkit-transition: -webkit-transform 750ms ease, opacity 750ms linear, visibility 750ms linear;
	-moz-transition: -moz-transform 750ms ease, opacity 750ms linear, visibility 750ms linear;
	-ms-transition: -ms-transform 750ms ease, opacity 750ms linear, visibility 750ms linear;
	-o-transition: -o-transform 750ms ease, opacity 750ms linear, visibility 750ms linear;
	transition: transform 750ms ease, opacity 750ms linear, visibility 750ms linear;
	visibility: hidden;
	width: 100%;
	z-index: 11;
}

.pano_content_frame.visible{
	opacity: 1;
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	visibility: visible;
}

.pano_close{
	height: 7.2em;
	left: auto;
	right: 0;
	top: 10em;
	width: 7.2em;
	z-index: 1;
}

.vtour__help{
	background-color: #009fe3;
	bottom: 0;
	height: 8.2em;
	overflow: hidden;
	position: absolute;
	right: 48.1em;
	white-space: nowrap;
	width: 41em;
	z-index: 12;
}

.vtour__help-scroll{
	height: 100%;
	position: relative;
	-webkit-transition: -webkit-transform 300ms ease;
	-moz-transition: -moz-transform 300ms ease;
	-ms-transition: -ms-transform 300ms ease;
	-o-transition: -o-transform 300ms ease;
	transition: transform 300ms ease;
	width: 100%;
}

.pano_opened .vtour__help-scroll{
	-webkit-transform: translate(0, -100%);
	-moz-transform: translate(0, -100%);
	-ms-transform: translate(0, -100%);
	-o-transform: translate(0, -100%);
	transform: translate(0, -100%);
}

.vtour__help-state{
	height: 100%;
	padding-left: 9em;
	position: relative;
	width: 100%;
}

.vtour__help-state.n0{
	padding-left: 9em;
}

.vtour__help-state.n1{
	padding-left: 10.3em;
}

.vtour__help-state:before{
	background: url(../i/sprite.svg) no-repeat;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
}

.vtour__help-state.n0:before{
	background-position: -80.4em -0.1em;
	height: 4.1em;
	left: 4.7em;
	margin: -2.05em 0 0 -1.5em;
	width: 3em;
}

.vtour__help-state.n1:before{
	background-position: -83.9em -0.1em;
	height: 4em;
	left: 5.15em;
	margin: -2em 0 0 -3.2em;
	width: 6.4em;
}

.vtour__help-state span{
	color: #fff;
	display: inline-block;
	font-size: 1.2em;
	font-weight: bold;
	line-height: 1.15;
	margin-top: 0.5em;
	text-transform: uppercase;
	vertical-align: middle;
}

.vtour__help-state:after{
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	width: 0;
}

/* --------------------------------------------------------- */

.footer_policy {
	position: absolute;
	top: 4em;
    right: 0;
	color: #27292a;
	white-space: nowrap;
	font-size: 1.4em;
}
body.developer .footer_policy, body.advantages .footer_policy, body.placement .footer_policy, body.plans .footer_policy, body.korpus .footer_policy, body.commercial .footer_policy, body.parking .footer_policy, body.storerooms .footer_policy, body.mortgage .footer_policy, body.calculator .footer_policy, body.contacts .footer_policy{
	color: #373737;
}
body.offers .footer_policy, body.policy .footer_policy, body.news .footer_policy, body.media .footer_policy, body.gallery .footer_policy, body.mop .footer_policy, body.gallery-night .footer_policy, body.views .footer_policy, body.construction .footer_policy{
	display: none;
}
/* ----------------------- Documents ----------------------- */

.documents__popup{
	background-color: #fff;
	display: block;
	height: 78.5%;
	left: 0;
	position: absolute;
	top: 100%;
	width: 100%;
}
.policy_frame .docs__frame{
	background-color: #f7f8f9;
}
.policy_frame .docs__frame .docs__title span {
	font-size: 3em;
	color: #27292a;
}
.policy_frame .docs__frame .policy__content{
	color: #5b7783;
}

.policy_frame .docs__frame .docs__title{
	position: relative;
	left: 0;
	margin-left: 0;
	margin-top: 0;
	margin-bottom: 2em;
}
.policy_frame .docs__scroll{
	top: 15%;
}
.docs__frame{
	background-color: #5b7885;
	height: 100%;
	left: 7.2em;
	position: absolute;
	right: 7.2em;
	top: 0;
	width: auto;
}

.docs__frame .close_btn{
	height: 7.2em;
	width: 7.2em;
}

.docs__frame .textScroll__track{
	left: auto;
	margin: 0;
	right: 0;
	z-index: 1;
}

.docs__scroll{
	bottom: 0;
	left: 0;
	position: absolute;
	top: 27%;
	width: 100%;
}

.docs__list{
	padding-left: 23%;
	padding-right: 14.35%;
	padding-bottom: 5em;
}

.docs__title{
	display: inline-block;
	left: 23%;
	margin-left: 2.2em;
	margin-top: -4em;
	position: absolute;
	top: 15.5%;
}

.docs__title span{
	color: #fff;
	display: inline-block;
	font-size: 4.3em;
	font-variant: small-caps;
	font-weight: normal;
	line-height: 0.9;
}

/*.docs__cell{*/
/*display: inline-block;*/
/*vertical-align: top;*/
/*width: 33.33%;*/
/*}*/

/*.docs__cell.no-visible{*/
/*display: none;*/
/*}*/

.docs__item{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	min-width: 40em;
	padding: 2.4em 4.7em 2.4em 7.2em;
	position: relative;
	text-decoration: none;
	-webkit-transition: background 300ms linear;
	-moz-transition: background 300ms linear;
	-ms-transition: background 300ms linear;
	-o-transition: background 300ms linear;
	transition: background 300ms linear;
	vertical-align: top;
	width: 50%;
}

.docs__item.no-visible{
	display: none;
}

.docs__item:hover{
	background-color: #009fe3;
}

.docs__item:before{
	background: url(../i/sprite.svg) no-repeat -10.9em -11.5em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	content: "";
	display: block;
	height: 3.2em;
	left: 3.6em;
	margin: -1.6em 0 0 -1.3em;
	position: absolute;
	top: 4em;
	width: 2.6em;
}

.docs__item-title span{
	display: inline-block;
	font-family: "OpenSans", Helvetica, Arial, sans-serif;
	font-size: 1.8em;
	font-weight: normal;
	letter-spacing: -0.05em;
	line-height: 1.15;
	padding-top: 0.3em;
	text-decoration: underline;
	width: 100%;
}

.docs__item:hover .docs__item-title span{
	text-decoration: none;
}

.docs__item-details{
	margin-top: 1.25em;
}

.docs__item-row{
	line-height: 1.2;
}

.docs__item-row span{
	color: #accad8;
	display: inline-block;
	font-size: 1.5em;
	font-weight: 200;
}

.docs__item-row strong{
	color: #c0dfec;
	display: inline-block;
	font-size: 1.5em;
	font-weight: normal;
}

.docs__search-frame{
	margin-top: -1.25em;
	padding-right: 4.7em;
	position: absolute;
	right: 14.35%;
	top: 15.5%;
	width: 30em;
}

.docs__search-input{
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #96a9b1;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	color: #fff;
	display: block;
	font-family: inherit;
	font-size: 1.7em;
	font-weight: 200;
	height: auto;
	line-height: 1;
	margin: 0;
	outline: none;
	padding: 0.6em 3em 0.6em 3em;
	-webkit-transition: border 200ms linear;
	-moz-transition: border 200ms linear;
	-ms-transition: border 200ms linear;
	-o-transition: border 200ms linear;
	transition: border 200ms linear;
	width: 100%;
}

.docs__search-input:hover,
.docs__search-input:focus{
	border-bottom-color: #fff;
}

.docs__search-frame ::-webkit-input-placeholder {
	color: #c0dfec;
}
.docs__search-frame :-moz-placeholder {
	color: #c0dfec;
}
.docs__search-frame ::-moz-placeholder {
	color: #c0dfec;
}
.docs__search-frame :-ms-input-placeholder {
	color: #c0dfec;
}

.docs__search-icon{
	background: url(../i/sprite.svg) no-repeat -36.9em -11.6em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	bottom: 0.8em;
	content: "";
	display: block;
	height: 2.3em;
	position: absolute;
	right: 0.8em;
	width: 2.3em;
}


.docs_group_title{
	color: #fff;
	font-size: 2.5em;
	padding: 0.5em;
	cursor: pointer;
	margin-left: -2em;
	display: inline-block;
	position: relative;
}
.docs_group_title:after{
	content: '▼';
	position: absolute;
	top: 50%;
	left: 100%;
	margin: -0.6em 0 0 0;
	font-size: 0.6em;
	color: #fff;
	-webkit-transition: transform .3s ease-in;
	-moz-transition:transform .3s ease-in;
	-ms-transition: transform .3s ease-in;
	-o-transition: transform .3s ease-in;
	transition: transform .3s ease-in;
}

.docs_group:last-child .docs_group_title:after{
	display: none;
}

.document__link {
	text-decoration: none;
	border-bottom: 0.1em solid #fff;
}

.document__link:hover {
	border-bottom: 0.1em solid transparent;
}

.docs_group.active .docs_group_title:after{
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
.docs_item_container{
	display: none;
}
.header_docs{
	position: absolute;
	top: 100%;
	text-align: right;
	margin-top: 9em;
	padding: 1.1em 0;
	cursor: pointer;
	color: #a5a5a5;
	left: 50%;
	margin-left: 8em;

	display: inline-block;
	font-family: 'Circe', Helvetica, Arial, sans-serif;
}
.header_docs:hover .menu__sidebar-link span{
	text-decoration: none;
}
.header_docs .menu__sidebar-link span{
	font-size: 1.6em;
	font-family: 'Circe', Helvetica, Arial, sans-serif;
	font-weight: normal;
	line-height: 1.2;
	text-decoration: underline;
}
.header_docs .menu__sidebar-link{
  color: #a5a5a5;
}
.header_docs .menu__sidebar-link:after {
  display: none;
}
/* --------------------------------------------------------- */


/* ------------------------ Policy ------------------------- */

.policy__content{
	color: #c0dfec;
	font-size: 1.7em;
	margin: 0 23%;
	padding: 0 1.35em 3em 1.35em;
}

/* --------------------------------------------------------- */


/* ----------------------- Mortgage ------------------------ */

.mortgage__frame{
	background-color: #f7f8f9;
	bottom: 0;
	left: 7.2em;
	right: 7.2em;
	position: absolute;
	top: 10em;
}

.mortgage__title{
	bottom: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	height: 9.6em;
	left: 14.5%;
	position: absolute;
}

.mortgage__title > div{
	color: #83785b;
	font-size: 1.8em;
}

.mortgage__title strong{
	color: #27292a;
	display: block;
	font-size: 2.35em;
	font-weight: normal;
	font-variant: small-caps;
	line-height: 0.9;
}

.mortgage__rate strong{
	color: #009fe3;
	display: inline-block;
	font-weight: 700;
}

.mortgage__feedback-btn{
	cursor: pointer;
	margin-right: -41em;
	padding-left: 5.5em;
	position: absolute;
	right: 50%;
	top: 12em;
}

.calculator_frame .mortgage__feedback-btn {
	right: 5em;
	margin: 0;
}

.mortgage__feedback-btn:before{
	background: url(../i/sprite.svg) no-repeat -63.4em -7.9em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	content: "";
	display: block;
	height: 3.5em;
	left: 2.75em;
	margin: -1.75em;
	position: absolute;
	top: 50%;
	width: 3.5em;
}

.mortgage__feedback-btn span{
	border-bottom: 1px solid;
	color: #009fe3;
	display: inline-block;
	font-size: 1.7em;
	line-height: 1.1;
}

.mortgage__feedback-btn:hover span{
	border-bottom-color: transparent;
}

.mortgage__frame .text_scroll {
	bottom: 0;
	left: 50%;
	margin-left: -41em;
	position: absolute;
	top: 18.8em;
	width: 82em;
}

.calculator_frame .mortgage__frame .text_scroll {
	width: 87.8em;
	right: 5em;
	left: auto;
	margin: 0;
}

.mortgage__preloader {
	visibility: hidden;
	position: absolute;
	width: 93em;
	top: 18.8em;
	right: 0;
	bottom: 0;
	opacity: 0;
	background-color: #f7f8f9;
	-webkit-transition: visibility .3s, opacity .3s;
	-moz-transition: visibility .3s, opacity .3s;
	-ms-transition: visibility .3s, opacity .3s;
	-o-transition: visibility .3s, opacity .3s;
	transition: visibility .3s, opacity .3s;
}

.mortgage__preloader.visible {
	visibility: visible;
	opacity: 1;
}

.mortgage__frame .text_scroll.browser_scroll {
	width: 87em;
}

.mortgage__frame .textScroll__track{
	margin-left: 1.2em;
}

.mortgage__banks{
	padding-bottom: 1em;
	border-bottom: 1px solid transparent;
}

.mortgage__bank {
	position: relative;
	width: 84.8em;
	height: 16em;
	margin: 0 auto 1em;
}

.calculator_frame .mortgage__bank {
	width: auto;
	height: 12em;
	overflow: hidden;
	cursor: pointer;
}

.mortgage__bank-logo {
	display: inline-block;
	position: relative;
	width: 34em;
	height: 100%;
	vertical-align: top;
	background-color: #ffffff;
	text-align: center;
	white-space: nowrap;
}

.calculator_frame .mortgage__bank-logo {
	width: 18em;
}

.mortgage__bank-logo:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.mortgage__bank-logo > img {
	display: inline-block;
	max-width: 65%;
	max-height: 65%;
	vertical-align: middle;
}

.calculator_frame .mortgage__bank-logo > img {
	max-width: 75%;
	max-height: 70%;
}

.mortgage__bank-info {
	display: inline-block;
	position: relative;
	width: 16.6em;
	height: 100%;
	vertical-align: top;
	background-color: #557e8f;
}

.mortgage__bank-info.wide {
	width: 20em;
}

.mortgage__bank-info:nth-child(odd) {
	background-color: #598394;
}

.mortgage__bank-info > span {
	position: absolute;
	top: 2rem;
	left: 2rem;
	font-size: 1.2em;
	font-weight: normal;
	text-transform: uppercase;
	color: #ffffff;
}

.mortgage__bank-info > strong {
	position: absolute;
	bottom: 2rem;
	left: 2rem;
	font-size: 4.2em;
	font-weight: bold;
	line-height: 1;
	white-space: nowrap;
	color: #ffffff;
}

.calculator_frame .mortgage__bank-info > strong {
	font-size: 3.2em;
}

.mortgage__bank-info > strong > i {
	font-size: 0.5em;
	font-style: normal;
}

.mortgage__bank-feedback {
	position: absolute;
	height: 100%;
	top: 0;
	right: 0;
	left: 18em;
	overflow: hidden;
	cursor: pointer;
	opacity: 0;
	background-color: #009fe3;
	text-align: center;
	white-space: nowrap;
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
	-moz-transition: opacity .4s ease-in-out, -moz-transform .4s ease-in-out;
	-ms-transition: opacity .4s ease-in-out, -ms-transform .4s ease-in-out;
	-o-transition: opacity .4s ease-in-out, -o-transform .4s ease-in-out;
	transition: opacity .4s ease-in-out, transform .4s ease-in-out;
}

.mortgage__bank:hover .mortgage__bank-feedback {
	opacity: 1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}

.mortgage__bank-feedback:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.mortgage__bank-feedback > span {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	font-size: 2em;
	color: #ffffff;
}

.mortgage__feedback-info {
	position: relative;
	text-align: left;
	color: #ffffff;
}

.mortgage__feedback-info .item {
	display: inline-block;
	position: relative;
	width: 16em;
	vertical-align: top;
	margin: 0 2em 3em 0;
}

.mortgage__feedback-info .item .label {
	margin-bottom: 0.5em;
	font-size: 1.4em;
	opacity: 0.9;
}

.mortgage__feedback-info .item .value {
	font-size: 2.2em;
	font-weight: bold;
	white-space: nowrap;
}

/* --------------------------------------------------------- */

/* ---------------- Calculator ---------------- */

.calc_bg {
	position: absolute;
	max-width: 56em;
	top: 50%;
	right: 100em;
	left: 25em;
	margin-top: -20em;
}

.calc_info {
	position: relative;
	margin: 5rem 4rem 0;
	font-size: 1.4em;
	color: rgba(91, 119, 131, 0.75);
}

.calc_info:before {
	content: '*';
	position: absolute;
	right: 100%;
	margin-right: 0.2em;
	font-size: 1.4em;
	font-family: Arial, sans-serif;
}

.calc_close {
	top: 0;
	right: 0;
	left: auto;
	width: 7.2em;
	height: 7.2em;
}

/* ---------------- Commercial Description ----------------- */

.commercial-description_frame .page__header{
	left: 22.8em;
}

.commercial-description__info-block {
	background-color: #567cab;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 4.5em 5em;
	position: absolute;
	right: 0;
	top: 10em;
	width: 24%;
}

.commercial-description__info-block .home__tile-link {
	width: 17em;
}

.commercial-description_frame .home__text-frame {
	background-color: #5b8ea4;
}

.commercial-description_frame .home__tile.n1 {
	background-image: url('../images/pages/commercial-description/1.jpg');
}
.commercial-description_frame .home__tile.n2 {
	background-image: url('../images/vk/m/01_00002.jpg');
}

.commercial-description_frame .home__tile.n3 {
	background-color: #009fe3;
}

.commercial-description_frame .home__tile.n4 {
	position: absolute;
	width: 200%;
	height: 116em;
	top: 0;
	left: 0;
	background-image: url('../images/pages/commercial-description/3.jpg')
}

.commercial-description_frame .home__tile.n6 {
	background-color: #567cab;
	height: 48em;
}

.commercial-description_frame .home__tile.n7 {
	position: absolute;
	width: 46.15%;
	top: 5em;
	right: 0;
	z-index: 1;
	background-color: #aa716f;
}

.home__tile.hidden {
	visibility: hidden;
	background: none !important;
}

.commercial-description__area {
	position: absolute;
	left: 7rem;
	font-size: 2.2em;
	font-weight: normal;
	font-variant: small-caps;
	color: #5b8ea4;
}

.commercial-description__area.n1 {
	top: 7rem;
}
.commercial-description__area.n2 {
	bottom: 7rem;
}

.commercial-description__area > span {
	font-size: 2.8em;
}

.commercial-description__open-concept {
	cursor: pointer;
}

.commercial-description__concept-button {
	position: absolute;
	bottom: 5rem;
	left: 7rem;
	padding: 0.5em 1em;
	background-color: #009fe3;
	font-size: 1.5em;
	font-weight: normal;
	color: #ffffff;
}

.commercial-description__zoom {
	position: absolute;
	width: 5.2em;
	height: 5.2em;
	right: 5em;
	bottom: 4.5em;
	background-color: #009fe3;
	-webkit-border-radius: 3em;
	-moz-border-radius: 3em;
	border-radius: 3em;
}

.commercial-description__zoom:after {
	content: '';
	position: absolute;
	width: 2.3em;
	height: 2.3em;
	top: 50%;
	left: 50%;
	margin: -1.15em 0 0 -1.15em;
	background: url('../i/sprite.svg') -47.8em -11.6em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}

.commercial-description_frame .home__news-frame {
	background-color: #325664;
}

.commercial-description_frame .home__news-content {
	top: 56em;
}

.commercial-description_frame .home__news-center {
	top: 0;
}

.commercial-description_frame .home__news-title {
	margin: 1em 0 1em;
}
.commercial-description_frame .home__news-title:first-child {
	margin-top: 0;
}

.commercial-description__numbers {
	position: relative;
	margin: 0 3em;
	text-align: left;
}

.commercial-description__number {
	display: inline-block;
	position: relative;
	width: 39%;
	margin: 0 5% 4em;
	vertical-align: top;
	line-height: 1.1;
}

.commercial-description__number-head {
	font-size: 3.6em;
	font-weight: normal;
	font-variant: small-caps;
	color: #ffffff;
}

.commercial-description__number-head > span {
	font-size: 2.1em;
}

.commercial-description__number-head.more-than > span:before {
	content: '>';
	display: inline-block;
	position: relative;
	margin-left: -0.65em;
}

.commercial-description__number-text {
	font-size: 1.9em;
	color: rgba(255, 255, 255, 0.8);
}

.commercial-description__characteristics {
	margin: 0 5%;
	font-size: 1.9em;
	line-height: 1.1;
	color: rgba(255, 255, 255, 0.8);
}

.commercial-description__characteristics strong {
	color: #ffffff;
}

.commercial-description__small-title {
	bottom: 100%;
	color: rgba(255, 255, 255, 0.45);
	font-size: 2.5em;
	font-variant: small-caps;
	font-weight: normal;
	left: 0;
	line-height: 0.9;
	margin: 0 0 6em 5%;
	position: absolute;
}

.commercial-concept__frame {
	position: absolute;
	height: 75.4em;
	top: 50%;
	right: 7.2em;
	left: 7.2em;
	margin-top: -37.7em;
}

.commercial-concept__frame:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	-webkit-box-shadow: 0 1em 7em rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0 1em 7em rgba(0, 0, 0, 0.5);
	box-shadow: 0 1em 7em rgba(0, 0, 0, 0.5);
	-webkit-transition: opacity .5s 1s;
	-moz-transition: opacity .5s 1s;
	-ms-transition: opacity .5s 1s;
	-o-transition: opacity .5s 1s;
	transition: opacity .5s 1s;
}

.commercial-concept__frame.hidden:before {
	opacity: 0;
}

.commercial-concept__left, .commercial-concept__right {
	position: absolute;
	height: 100%;
	top: 0;
	-webkit-transition: opacity .8s ease-in-out, -webkit-transform .8s ease-in-out;
	-moz-transition: opacity .8s ease-in-out, -moz-transform .8s ease-in-out;
	-ms-transition: opacity .8s ease-in-out, -ms-transform .8s ease-in-out;
	-o-transition: opacity .8s ease-in-out, -o-transform .8s ease-in-out;
	transition: opacity .8s ease-in-out, transform .8s ease-in-out;
}

.commercial-concept__left {
	overflow: hidden;
	width: 65%;
	left: 0;
	-webkit-transition-delay: .1s;
	-moz-transition-delay: .1s;
	-ms-transition-delay: .1s;
	-o-transition-delay: .1s;
	transition-delay: .1s;
}

.commercial-concept__right {
	width: 35%;
	right: 0;
	background-color: #009fe3;
	white-space: nowrap;
	-webkit-transition-delay: .3s;
	-moz-transition-delay: .3s;
	-ms-transition-delay: .3s;
	-o-transition-delay: .3s;
	transition-delay: .3s;
}

.commercial-concept__right:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.commercial-concept__frame.hidden .commercial-concept__left,
.commercial-concept__frame.hidden .commercial-concept__right {
	opacity: 0;
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-100%);
}

.commercial-concept__frame .close_btn {
	top: -5.6em;
	left: 0;
}

.commercial-concept__korpus {
	position: absolute;
	width: 4.7em;
	height: 4.7em;
	margin: -2.35em 0 0 -2.35em;
	text-align: center;
	white-space: nowrap;
}

.commercial-concept__korpus:before {
	content: '';
	position: absolute;
	width: 4.7em;
	height: 4.7em;
	top: 50%;
	left: 50%;
	margin: -2.35em 0 0 -2.35em;
	background-color: #009fe3;
	-webkit-border-radius: 3em;
	-moz-border-radius: 3em;
	border-radius: 3em;
	-webkit-transition: -webkit-transform .3s ease-in-out;
	-moz-transition: -moz-transform .3s ease-in-out;
	-ms-transition: -ms-transform .3s ease-in-out;
	-o-transition: -o-transform .3s ease-in-out;
	transition: transform .3s ease-in-out;
}

.commercial-concept__korpus.active:before {
	-webkit-transform: scale(1.5);
	-moz-transform: scale(1.5);
	-ms-transform: scale(1.5);
	-o-transform: scale(1.5);
	transform: scale(1.5);
}

.commercial-concept__korpus:after {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.commercial-concept__korpus > span {
	display: inline-block;
	position: relative;
	margin: 0.06em -0.1em 0 0;
	vertical-align: middle;
	font-size: 1.4em;
	font-weight: bold;
	color: #ffffff;
}

.commercial__concept-arrow{
	border-color: #009fe3 transparent transparent transparent;
	border-style: solid;
	border-width: 1.5em 0.75em 0 0.75em;
	display: block;
	height: 0;
	left: 50%;
	margin: -0.3em -0.75em;
	position: absolute;
	top: 100%;
	width: 0;
}

.commercial-concept__list {
	display: inline-block;
	position: relative;
	padding-left: 14em;
	vertical-align: middle;
	white-space: normal;
}

.commercial-concept__title {
	margin-bottom: 1em;
	font-size: 3.5em;
	font-weight: normal;
	font-variant: small-caps;
	line-height: 1.1;
	color: #ffffff;
}

.commercial-concept__item {
	position: relative;
	height: 5em;
	margin-left: -7.5em;
	cursor: pointer;
	white-space: nowrap;
}

.commercial-concept__item + .commercial-concept__item {
	margin-top: 2em;
}

.commercial-concept__item-numbers {
	display: inline-block;
	position: relative;
	width: 12.5em;
	height: 5em;
	margin-right: 2em;
	vertical-align: middle;
	text-align: right;
	white-space: nowrap;
}

.commercial-concept__item-number {
	display: inline-block;
	position: relative;
	width: 5em;
	height: 5em;
	vertical-align: middle;
	text-align: center;
}

.commercial-concept__item-number + .commercial-concept__item-number {
	margin-left: 1.5em;
}

.commercial-concept__item-number:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.commercial-concept__item-number:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 2px solid #ffffff;
	-webkit-border-radius: 3em;
	-moz-border-radius: 3em;
	border-radius: 3em;
}

.commercial-concept__item-number > span {
	display: inline-block;
	vertical-align: middle;
	font-size: 1.5em;
	font-weight: normal;
	color: #ffffff;
}

.commercial-concept__item-delimiter {
	display: inline-block;
	width: 0.7em;
	height: 0.4em;
	margin: 0 0.5em;
	vertical-align: middle;
	background-color: #ffffff;
}

.commercial-concept__item-text {
	display: inline-block;
	position: relative;
	max-width: 18em;
	vertical-align: middle;
	font-size: 2.1em;
	line-height: 1.1;
	white-space: normal;
	color: rgba(255, 255, 255, 0.75);
}

.commercial-concept__item-text > span {
	font-weight: normal;
	color: #ffffff;
}

/* --------------------------------------------------------- */


/* ----------------------- Search -------------------------- */

.search_frame .plans__wrapper, .commercial-search_frame .plans__wrapper{
	top: 5.5em;
}

/* --------------------------------------------------------- */


/* ----------------------- Compare ------------------------- */

.compare_frame .page__header-block{
	background-color: transparent;
}

.compare__frame{
	background-color: #f4f4f4;
	bottom: 0;
	left: 7.2em;
	position: absolute;
	right: 7.2em;
	top: 10em;
}

.compare__frame .close_btn {
	width: 7.6em;
	height: 7.6em;
}

.compare__title{
	color: #009fe3;
}

.compare__scroll{
	bottom: 0;
	left: 2.6em;
	position: absolute;
	right: 0;
	top: 8em;
}

.compare__scroll .textScroll__track{
	bottom: 0;
	top: auto;
	z-index: 1;
}

.compare__scroll .textScroll__drag{
	width: 15em;
}

.compare__scroll .textScroll__track_size{
	right: 15em;
}

.compare__list{
	padding: 14em 6.05em 0 28em;
	white-space: nowrap;
	color: red;
}

.compare__cell{
	display: inline-block;
	overflow: hidden;
	vertical-align: top;
	width: 32.5em;
}

.compare__apart{
	background-color: #fff;
	border: 1px solid #e8e8e8;
	cursor: pointer;
	display: block;
	margin: 0 1.25em;
	position: relative;
	text-decoration: none;
	width: 30em;
}

.compare__apart-preview{
	background: url(/assets/i/blank.gif) no-repeat 50% 50%;
	-webkit-background-size: contain;
	background-size: contain;
	display: block;
	margin: 4em auto 1em auto;
	padding-top: 80%;
	position: relative;
	width: 80%;
}

.compare__apart-header{
	position: relative;
	white-space: nowrap;
}

.compare__apart-cell{
	background-color: #009fe3;
	color: #fff;
	display: inline-block;
	height: 10em;
	position: relative;
	text-align: center;
	vertical-align: middle;
	width: 10em;
}

.compare__apart-cell.n1{background-color: #0294d2;}

.compare__apart-cell .label{
	display: inline-block;
	font-size: 1.2em;
	font-weight: 400;
	left: 1.5rem;
	line-height: 1;
	position: absolute;
	text-transform: uppercase;
	top: 1.5rem;
}

.compare__apart-cell .value{
	bottom: 1rem;
	font-size: 4em;
	font-weight: bold;
	left: 1.5rem;
	line-height: 1;
	position: absolute;
}

.compare__apart-row{
	background: #fff;
	color: #335665;
	padding: 0 2em;
	position: relative;
	white-space: nowrap;
}

.compare__apart-row:nth-child(odd){
	background-color: #f7f8f9;
}

.compare__apart-row:before{
	content: "";
	display: inline-block;
	height: 6em;
	vertical-align: middle;
	width: 0;
}

.compare__apart-row .label{
	display: inline-block;
	font-size: 1.2em;
	font-weight: 400;
	line-height: 1.15;
	text-transform: uppercase;
	vertical-align: middle;
	width: 10em;
}

.compare__apart-row .value{
	display: inline-block;
	font-size: 3.9em;
	font-weight: bold;
	line-height: 1;
	vertical-align: middle;
	white-space: normal;
	width: 3.5em;
}

.compare__apart-row .small{
	display: block;
	font-size: 0.45em;
}

.compare__popup .close_btn{
	height: 7.2em;
	left: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 7.2em;
}

.compare__apart-controls{
	position: absolute;
	right: 1.5em;
	top: 100%;
	z-index: 1;
}

.compare__apart-btn{
	cursor: pointer;
	display: inline-block;
	height: 4em;
	position: relative;
	vertical-align: top;
	width: 3.5em;
}

.compare__apart-btn:after{
	background: url(/assets/i/sprite.svg) no-repeat 0 0;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	content: "";
	display: block;
	height: 3.5em;
	left: 50%;
	margin: -1.75em;
	position: absolute;
	top: 50%;
	-webkit-transition: -webkit-transform 300ms ease;
	-moz-transition: -moz-transform 300ms ease;
	-ms-transition: -ms-transform 300ms ease;
	-o-transition: -o-transform 300ms ease;
	transition: transform 300ms ease;
	width: 3.5em;
}

.compare__apart-btn:hover:after{
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
}

.compare__apart-btn.favorite:after{
	background-position: -31.4em -7.9em;
}

.compare__apart-btn.favorite.active:after{
	background-position: -43.4em -7.9em;
}

.compare__apart-btn.remove:after{
	background-position: -47.4em -7.9em;
}

.compare__scroll-controls{
	height: 0;
	left: 100%;
	position: absolute;
	top: 50%;
	-webkit-transition: opacity 300ms linear, visibility 300ms linear;
	-moz-transition: opacity 300ms linear, visibility 300ms linear;
	-ms-transition: opacity 300ms linear, visibility 300ms linear;
	-o-transition: opacity 300ms linear, visibility 300ms linear;
	transition: opacity 300ms linear, visibility 300ms linear;
	width: 0;
}

.compare__scroll-controls.hidden{
	opacity: 0;
	visibility: hidden;
}

.compare__scroll-arrow{
	cursor: pointer;
	display: block;
	height: 7.2em;
	position: absolute;
	top: -5.15em;
	width: 7.2em;
}

.compare__scroll-arrow:before{
	content: "";
	display: block;
	height: 7.2em;
	position: absolute;
	width: 7.2em;
}

.compare__scroll-arrow.left{
	background-color: #27292a;
	left: auto;
	right: 0;
}

.compare__scroll-arrow.right{
	background-color: #009fe3;
	left: 0;
	right: auto;
}

.compare__scroll-arrow:after{
	background: url(/assets/i/sprite.svg) no-repeat;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	content: "";
	height: 2em;
	display: block;
	left: 50%;
	margin: -1em 0 0 -0.6em;
	position: absolute;
	top: 50%;
	width: 1.2em;
}

.compare__scroll-arrow.left:after{
	background-position: -26.3em -7.8em;
}

.compare__scroll-arrow.right:after{
	background-position: -27.8em -7.8em;
}

.compare__scroll-hint{
	bottom: 5em;
	padding-bottom: 2em;
	position: absolute;
	right: 7.25em;
}

.compare__scroll-hint span{
	color: #009fe3;
	display: inline-block;
	font-size: 1.6em;
	font-weight: normal;
	line-height: 1;
}

.compare__scroll-hint:after{
	background: url(/assets/i/sprite.svg) no-repeat -19.7em -3.3em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	bottom: 0;
	content: "";
	display: block;
	height: 1.2em;
	position: absolute;
	right: -0.1em;
	width: 4.2em;
}

/* --------------------------------------------------------- */


.gallery_popup .g_s_area, .gallery_popup .g_btn, .gallery_popup .close_btn, .gallery_popup .gallery_title_frame {
    z-index: 2;
}


/* Media Queries */

@media only screen and (orientation: portrait) {

	.rotate_help_frame {
		display: block;
	}

}

@media only screen and (orientation: landscape) {

	.mobile .home__offer-close {
		top: 50%;
		margin-top: -4.5em;
	}

}

@media only screen and (max-height: 900px) {

    .apart_details_frame {
        top: 0;
    }

	.parking_frame .apart_details_frame,
	.storerooms_frame .apart_details_frame{
		top: 14.5em;
	}


}
.parking_temp_frame .plans__right{
	left: 0;
}

.parking_text{
	position: absolute;
	width: 60%;
	height: 50%;
	left: 20%;
	top: 25%;
	background-color: rgba(0, 159, 227, 0.9);
}
.parking_text_block{
	font-size: 2.5em;
	color: #fff;
	position: relative;
	width: 80%;
	left: 10%;
	top: 15%;
	text-align: center;
	font-weight: bold;
}
.parking_text_block.n1{
	margin-top: 2em;
}
.parking_text_block.n1 .home__tile-link{
	font-size: 0.6em;
	margin: 1.5em auto;
}


/*********PAYMENT********/
.payment_frame .mortgage__frame .text_scroll {
	margin-left: -50em;
	width: 120em;
	bottom: 10em;
}
.payment_table table{
	font-size: 1.8em;
	display: block;
	position: relative;
	width: 100%;
}
.payment_table table td{
	width: 14%;
	display: inline-block;
	box-sizing: border-box;
	padding: 1%;
}
.payment_table thead td {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.8em;
}
.payment_table tbody td {
	display: inline-block;
}
.payment_table tbody, .payment_table thead, .payment_table tr{
	display: block;
	width: 100%;
}
.payment_table thead{
  background-color: rgba(0, 159, 227, 0.2);
}
.payment_table tbody{
	margin-top: 1em;
	background-color: rgba(154, 201, 247, 0.3);
}
/* .payment_table td:nth-child(1) {
	width: 18%;
	padding-left: 2%;
}*/
.payment_table td:nth-child(4) {
	width: 20%;
}
.payment_table td:nth-child(3), .payment_table td:nth-child(5), .payment_table td:nth-child(6) {
	width: 12%;
}
.payment_title{
	font-size: 3.5em;
	color: #009fe3;
	font-weight: 400;
	margin-bottom: 0.8em;
	padding-left: 2.2em;
	position: relative;
}
.payment_title:before{
	content: '';
	position: absolute;
	width: 3em;
	height: 3em;
	left: 0;
	top: 50%;
	margin-top: -1.5em;
	background: url(../i/sprite.svg?v29);
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	background-position: -79.7em -15.5em;
	font-size: 0.5em;
}
.payment_title.car:before{
	content: '';
	width: 3.2em;
	background: url(../i/sprite.svg?v29);
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
	background-position: -83.6em -15.4em;
}
.payment_table + .payment_table{
	margin-top: 6em;
}
.payment__info {
	font-size: 1.5em;
    line-height: 1.2;
}
/*********END PAYMENT********/



.plans__filters-frame .menu__footer-btn {
	background: url(/assets/images/menu/3.jpg) no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	background-position: 0 45%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	color: #fff;
	display: block;
	height: 13em;
	left: 0;
	overflow: hidden;
	padding: 2.7em 3.5em;
	position: relative;
	text-decoration: none;
	top: 100%;
	width: 100%;
	margin-top: -21em;
}
.vtour_frame .plans__back {
	display: none;
	background-color: #27292a;
	left: 31.8em;
	position: absolute;
	-webkit-transition: background-color 300ms easy-in-out;
	-moz-transition: background-color 300ms easy-in-out;
	-ms-transition: background-color 300ms easy-in-out;
	-o-transition: background-color 300ms easy-in-out;
	transition: background-color 300ms easy-in-out;
	top: 0em;
	font-size: 0.82em;
}
.vtour_frame .plans__back:before {
	background: url(/assets/i/sprite.svg) no-repeat -27.8em -7.8em;
	-webkit-background-size: 100em 100em;
	background-size: 100em 100em;
}
.vtour_frame .plans__back:after {
	content: '';
	display: block;
	position: absolute;
	background-color: rgb(255, 255, 255);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	-webkit-transition: opacity 300ms easy-in-out;
	-moz-transition: opacity 300ms easy-in-out;
	-ms-transition: opacity 300ms easy-in-out;
	-o-transition: opacity 300ms easy-in-out;
	transition: opacity 300ms easy-in-out;
}
.vtour_frame .plans__back:hover:after {
	opacity: 0.15;
}


.commercial_scroll_btn {
	display: block;
	position: absolute;
	top: 26.6em;
	margin-top: -2.5em;
	left: 3.6em;
	background-color: #dcdcdc;
	color: #364150;
	text-decoration: none;
	padding: 0.5em 1.63em;
	font-size: 2em;
	font-weight: bold;
	font-variant: small-caps;
	letter-spacing: -0.04em;
	z-index: 10;
	text-decoration: underline;
}
.commercial_scroll_btn:hover {
	text-decoration: none;
}
.commercial_scroll_btn.n1{
	background-color: #567cab;
	color: #fff;
	top: 29.1em;
}

.home__slogans-frame{
	bottom: 9.5em;
}
.buy_design_btn {
	text-transform: none!important;
	display: block;
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #009fe3;
	color: #fff;
	text-decoration: none;
	padding: 0.5em 2em;
	font-weight: bold;
}

/*************RIMER_ALL_PAGE****************/
.timer_all_page{
	position: absolute;
	width: 22.8em;
	height: 14em;
	top: 25.3em;
	left: -29.8em;
	z-index: 22;
	background-color: #009fe3;
	text-decoration: none;
	-webkit-transition: -webkit-transform 1.3s ease-in-out;
	-moz-transition: -moz-transform 1.3s ease-in-out;
	-ms-transition: -ms-transform 1.3s ease-in-out;
	-o-transition: -o-transform 1.3s ease-in-out;
	transition: transform 1.3s ease-in-out;
}
.timer_all_page.active{
	-webkit-transform: translate(37em, 0);
	-moz-transform: translate(37em, 0);
	-ms-transform: translate(37em, 0);
	-o-transform: translate(37em, 0);
	transform: translate(37em, 0);
}
.time_bg{
	position: absolute;
	height: 100%;
	width: 0%;
	background-color: #0076a7;
}
.timer_title{
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
	font-size: 1.8em;
	font-size: 1.4em;
	line-height: 1;
	margin-top: 1.5em;
	position: relative;
	z-index: 2;
}
.timer_wrapper {
	display: block;
	margin: 1.2em 0;
	text-align: center;
}
.timer_number_block{
	display: inline-block;
	color: #fff;
	font-size: 3.4em;
	font-weight: bold;
	position: relative;
	width: 1.1em;
}
.timer_number_block + .timer_number_block{
	margin-left: 0.6em;
}
.timer_number_block:before{
	content: 'Дней';
	position: absolute;
	display: block;
	font-weight: 200;
	bottom: -1.1em;
	font-size: 0.45em;
	left: 50%;
	margin-left: -1.2em;
}
.timer_number_block.hours:before{
	content: 'Часов';
	margin-left: -1.3em;
}
.timer_number_block.minutes:before{
	content: 'Минут';
	margin-left: -1.4em;
}
.timer_number_block.seconds:before{
	content: 'Секунд';
	margin-left: -1.6em;
}
.time_scale{
	position: absolute;
	width: 80%;
	left: 10%;
	bottom: 0;
	height: 1.4em;
	background-image: url(../i/timer_segmenet.svg);
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	display: none;
}
.day_val{
	font-size: 1.5em;
	color: #fff;
	display: inline-block;
	position: relative;
	top: -1.2em;
	line-height: 1;
	left: -0.5em;
	width: 1.3em;
	text-align: center;
	vertical-align: top;
}
.day_val + .day_val{
	margin-left: 14%;
}
.day_val:last-child{
	position: absolute;
	left: 100%;
	margin-left: -0.8em;
}
.time_bg_overlay{
	position: absolute;
	height: 100%;
	width: 0;
	background-color: rgba(255, 255, 255, 0.2);
}


.cookie_popup_frame {
	display: none;
	position: absolute;
	width: 62em;
	top: 10em;
	right: 7.2em;
	z-index: 30;
	padding: 2em 5em 2em 4em;
	background: #009fe3;
}

.cookie_popup_frame p {
	font-size: 1.5em;
	color: #fff;
}

.cookie_popup_frame .open_policy{
	text-decoration: underline;
	cursor: pointer;
}
.cookie_popup_frame .open_policy:hover{
	text-decoration: none;
}
.cookie_popup_frame .close_btn {
	width: 6em;
	height: 6em;
	top: 0;
	right: 0;
	left: auto;
	font-size: 0.8em;
	background-color: transparent !important;
}

.plans__offers-note{
	border-top: 1px solid;
	color: rgba(255,255,255,0.8);
	margin: 1em 5em;
	padding-top: 1em;
}

.plans__offers-note p{
	font-size: 1.7em;
	line-height: 1.2;
}


.similar_apartments_btn{
	position: absolute;
	width: 22.1%;
	right: 7.2em;
	height: 7em;
	bottom: -7em;
	z-index: 2;
	background-color: #d87822;
	cursor: pointer;
	-webkit-transition: visibility .5s ease-in-out, -webkit-transform .5s ease-in-out;
	-moz-transition: visibility .5s ease-in-out, -moz-transform .5s ease-in-out;
	-ms-transition: visibility .5s ease-in-out, -ms-transform .5s ease-in-out;
	-o-transition: visibility .5s ease-in-out, -o-transform .5s ease-in-out;
	transition: visibility .5s ease-in-out, transform .5s ease-in-out;
}
.similar_apartments_btn.visible{
	-webkit-transform: translateY(-5.6em);
	-moz-transform: translateY(-5.6em);
	-ms-transform: translateY(-5.6em);
	-o-transform: translateY(-5.6em);
	transform: translateY(-5.6em);
}

.similar_apartments_btn.visible:hover{
	-webkit-transform: translateY(-7em);
	-moz-transform: translateY(-7em);
	-ms-transform: translateY(-7em);
	-o-transform: translateY(-7em);
	transform: translateY(-7em);
}
.similar_apartments_btn .plans__offers-button{
	background-color: #d87822;
}
.similar_apartments_btn .plans__offers-button:after{
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}

.similar_apartments_btn .plans__offers-name .val{
	display: inline-block;
	font-weight: bold;
	color: #fff;
}

.similar_apartments_frame{
	background-color: #f4f4f4;
	bottom: 9.8em;
	left: 19.5em;
	position: absolute;
	right: 0;
	// top: 10em;
	height: 82vh;
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	-ms-transform: translateX(100%);
	-o-transform: translateX(100%);
	transform: translateX(100%);
	-webkit-transition: visibility .5s ease-in-out, -webkit-transform .5s ease-in-out;
	-moz-transition: visibility .5s ease-in-out, -moz-transform .5s ease-in-out;
	-ms-transition: visibility .5s ease-in-out, -ms-transform .5s ease-in-out;
	-o-transition: visibility .5s ease-in-out, -o-transform .5s ease-in-out;
	transition: visibility .5s ease-in-out, transform .5s ease-in-out;
	visibility: hidden;
	z-index: 2;
}
.similar_apartments_frame.active {
	visibility: visible;
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-o-transform: translateX(0);
	transform: translateX(0);
}
.similiar_close{
	width: 7.2em;
	height: 7.2em;
	top: 0;
	margin-left: -7.2em;
}

.similar_apartments_frame .compare__frame{
	top: 0;
	left: 0;
}

.similar_apartments_frame .page__header-block {
	background-color: transparent;
}

.similar_apartments_frame .page__header {
	position: absolute;
	top: 4.4em;
	left: 17.6em;
}

.similar_apartments_frame .compare__list{
	padding-left: 20em;
}

.similar_apartments_frame .compare__scroll-hint{
	padding-bottom: 0;
	bottom: 2em;
}
.similar_apartments_frame .compare__scroll-hint:after{
	right: -5em;
}

/* AERO */

.aero__frame {
	background-color: rgba(0, 0, 0, 0.85);

}

.aero__frame > iframe {
	background: none;
	border: none;
}

.aero__close {
	width: 7.2em;
	height: 7.2em;
	top: 0;
	right: 0;
	left: auto;
	z-index: 1;
	margin: 0;
}
#iframe_media {
	width: 80%;
	height: 25.2em;
}
.other-prj{
	padding:0.5em 0 0;
}
.other-prj .row{
	display:table;
	width:100%;
	table-layout: fixed;
	margin-bottom:0.5em;
	text-decoration: none;
}
.other-prj .row:hover{
	text-decoration: underline;
}
.other-prj .cell{
	display:table-cell;
	vertical-align: middle;
}
.other-prj .cell:first-child{
	width:7em;
}
.other-prj .cell:last-child{
	padding-left:0.5em;
	font-size:1.2em;
	font-weight: bold;
}
.other-prj .cell span{
	display:block;
	font-size:0.8em;
	font-weight: normal;
}
.other-prj .title{
	font-size:2em;
	padding:0 0 0.2em;
}
.other-prj .img{
	width:11em;
	height:11em;
	background:url('../i/afi-logos.svg') no-repeat;
	background-size:76em;
	font-size:0.5em;
}
.other-prj .img.logo-1{
  background: url(/assets/i/sirenevy_park.svg);
  background-size: cover;
  background-color: #b690f4;
  border: 4px solid #b690f4;
}
.other-prj .img.logo-2{
	background: url(/assets/i/afi-logos.png);
	background-size:56em;
	background-position: -22.5em 0;
	background-color:#49453c;
	border:4px solid #49453c;
}
.other-prj .img.logo-3{background-position: -33.9em 0; background-color:#18A0E1; border:4px solid #18A0E1;}
.other-prj .img.logo-4{
  background: url(/assets/i/odinburg.svg);
  background-size: cover;
  background-color: #bedc1c;
  border: 4px solid #bedc1c;
}
.other-prj .img.logo-5{background-position: -45em 0; background-color:#DCDCDC; border:4px solid #DCDCDC;}
.other-prj .img.logo-6{background-position: -71.4em 0; background-color:#303342; border:4px solid #303342; background-size: 82em;}
.other-prj .img.logo-7{background-position: -54.9em 0; background-color:#000; border:4px solid #000;}

.storerooms_frame .apart_details_frame{
	top: 19em;
}

.webcam_name{
	position: absolute;
	z-index: 1;
	display: inline-block;
	padding: 	1.2em 4em;
	top: 10%;
	left: 50%;
	-webkit-transform: 	translateX(-50%);
	-ms-transform: 	translateX(-50%);
	-o-transform: 	translateX(-50%);
	transform: 	translateX(-50%);
	background: #0294d2;
  color: #fff;
  font-size: 	1.6em;
}

.ovf-hidden {
	overflow: hidden;
}


.level_change{
	display: none;
	position: absolute;
	top: 50%;
	right: 45em;
	width: 18em;
	margin-top: -10em;
	cursor: pointer;
}
.level_change span{
	display: block;
	font-size: 1.2em;
	font-weight: 600;
	text-transform: uppercase;
	text-align: center;
	color: #0091cf;
}

.level_change .img_level_tmb{
	display: block;
	position: relative;
	margin-left: 1em;
	width: 16em;
	height: 16em;
}
.level_change .img_level_tmb > img{
	position: relative;
	width: 100%;
	height: 100%;
}

.footer__disclaimer_text {
	position: absolute;
	bottom: 0.48em;
	left: 7em;
	color: #fff;
	font-size: 1.4em;
	width: 14.2em;
	line-height: 1.1;
	opacity: 1;
	@include anim(.3s, opacity);
	&.hidden {
		opacity: 0;
	}
}

body.developer .footer__disclaimer_text, body.advantages .footer__disclaimer_text, body.design .footer__disclaimer_text, body.placement .footer__disclaimer_text, body.infrastructure .footer__disclaimer_text, body.plans .footer__disclaimer_text, body.commercial .footer__disclaimer_text, body.parking .footer__disclaimer_text, body.storerooms .footer__disclaimer_text, body.search .footer__disclaimer_text, body.mortgage .footer__disclaimer_text, body.payment .footer__disclaimer_text, body.offers .footer__disclaimer_text, body.news .footer__disclaimer_text, body.trade-in .footer__disclaimer_text, body.contacts .footer__disclaimer_text{
	color: #27292a;
}

/* banner schedule christmas */

.banner_popup_schedule_frame {
	display: none;
	background: rgba(0,0,0, .6);
	z-index: 100;
}

.schedule_frame__block-img {
	width: 600px;
	height: 424px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -212px 0 0 -300px;
	background: rgb(142,197,228);
	background: radial-gradient(circle, rgba(142,197,228,1) 0%, rgba(21,120,177,1) 84%, rgba(32,129,184,1) 100%);
	-webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
	box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.schedule_frame__block-img::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 600px;
	height: 176px;
	background: url('/assets/i/wrapper-t.png') center/cover no-repeat;
}

.schedule_frame__block-img::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 600px;
	height: 105px;
	background: url('/assets/i/wrapper-b.png') center/cover no-repeat;
}

.schedule_frame__block-img p {
	position: absolute;
	left: 21%;
	margin: 0;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	line-height: 3rem;
	letter-spacing: .1rem;
	text-shadow: 1px 1px 5px rgba(0,0,0, 0.2);
}

.schedule_frame__block-title {
	font-size: 2.6rem;
	top: 32%;
}

.schedule_frame__block-text {
	font-size: 2.4rem;
}

.schedule_frame__block-text::before {
	content: '';
	position: absolute;
	bottom: 4px;
	left: -64px;
	width: 49px;
	height: 46px;
	background: url('/assets/i/star.png') center/cover no-repeat;
}

.schedule_frame__block-img .n0 {
	top: 50%;
}

.schedule_frame__block-text__thin {
	text-transform: none;
}

.schedule_frame__block-img .n1 {
	top: 66%;
}

.schedule_frame__img {
	max-width: 100%;
	height: auto;
	display: block;
	margin: 0 auto;
	border: none;
}

.schedule_frame__block-balls {
	content: '';
	position: absolute;
	top: 0;
	left: 33%;
	width: 299px;
	height: 112px;
	background: url('/assets/i/balls.png') center/cover no-repeat;
}

.schedule_frame__block-logo {
	content: '';
	position: absolute;
	bottom: 5%;
	left: 10%;
	width: 181px;
	height: 28px;
	background: url('/assets/i/logo1.png') center/cover no-repeat;
}

/*.schedule_frame__close-btn {*/
/*width: 5rem;*/
/*height: 5rem;*/
/*background: black;*/
/*}*/

.apart_details_frame__offer-btn:hover {
	color: #ffc04e;
}

.button {
	padding: 1rem 0;
	width: 32rem;
	color: #fff;
	font-size: 1em;
	background: #0294d2;
	cursor: pointer;
    text-align: center;

	&.button--visit {
		margin-top: 2rem;
	}

	&:hover {
		background: #007fb6;
	}
}

.apart_details_frame__offer-btn.tqs {
	padding: 0.5em 0;
	width: 27.85rem;
	border: 1px solid #007fb6;
	background-color: transparent;
	transition: all .3s ease;
	animation: blink 2.5s ease infinite;

	@keyframes blink {
		0% {
			background-color: #007fb6;
			border-color: #007fb6;
		}
		50% {
			background-color: #a5cde2;
			border-color: #95c4d9;
		}
		100% {
			background-color: #007fb6;
			border-color: #007fb6;
		}
	}
	&:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		background-color: transparent;
		border: 1px solid transparent;
		transition: all .3s ease;
	}
	span {
		position: relative;
		z-index: 10;
	}
}

.apart_details_frame__offer-btn.tqs:hover {
	color: #007fb6;
	background-color: #fff;
	transition: all .3s ease;
	animation-play-state: paused;
	animation-delay: 0.3s;
	border-color: #fff;
	&:before {
		background-color: #fff;
		border-color: #007fb6;
	}
}

.apart_details_frame__offer-btn:hover {
	color: #72af66;
}

.at_apart {
	transition: none !important;
}

.r_tc__line-through {
	text-decoration: line-through;
	text-decoration-color: #d20808;
	opacity: 0.75;
}

.disclaimer {
	position: absolute;
	bottom: 0.48em;
	left: 10rem;
	width: 44vw;
	font-size: 1.4rem;
	line-height: 1.1;
	color: #fff;
	opacity: 0;
	@include anim(.3s, opacity);
	&.active {
		opacity: 1;
	}
	p {
		margin: 0;
	}
}
.plans,
.commercial,
.parking,
.storerooms {
	.disclaimer {
		z-index: 2;
		color: #0C0C0C;
		right: 27%;
		font-size: 1.2rem;
		width: initial;
	}
}