.parking_frame {

  .apart_details_frame {
    top: 13.8rem;

    .popup_details_row {
      &.n5 {
        display: none;
      }
    }

    &.discount {
      .popup_details_row {
        &.n4 {
          .val {
            text-decoration: line-through;
          }
        }

        &.n5 {
          display: block;
        }
      }
    }
  }
}