.plans_frame,
.commercial_frame {

	.apart_details_frame__min_payment-btn {
		min-height: 2.3rem;
		width: 28rem;
		padding: 1em 0;
		margin-top: 3rem;
		border: 1px solid #d20808;
		background-color: #d20808;
		font-size: 1.6rem;
		font-weight: 500;
		text-align: center;
		color: white;
		cursor: pointer;
		@include anim(.3s, background-color);

		&:hover {
			background-color: transparent;
			border: 1px solid #d20808;
			color: #d20808;
		}
	}

	.apart_details_frame__offer-btn {
		position: relative;
		width: 27.85rem;
		min-height: 4.6rem;
		margin-top: 1rem;
		padding: 0.5em 0;
		border: 1px solid #009fe3;
		background-color: transparent;
		text-align: center;
		font-size: 1.6rem;
		font-weight: 500;
		color: #fff;
		cursor: default;
		@include anim(.3s, background-color);
		@include blink();

		&:hover {
			border-color: #fff;
			background-color: #fff;
			color: #009fe3;
			transition: all .3s ease;
			animation-play-state: paused;
			animation-delay: 0.3s;

			&:before {
				border-color: #009fe3;
				background-color: #fff;
				@include anim(.3s, background-color);
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 100%;
			transform: translate(-50%, -50%);
			z-index: 1;
			background-color: transparent;
			border: 1px solid transparent;
		}

		span {
			position: relative;
			z-index: 10;
		}
	}
}