.mortgage_info_popup {
  background-color: rgba(0,0,0,0.7);

  .feedback_bg {
    background-color: #27292a;
    width: 50rem;
    height: 40rem;
    margin: -20rem -25rem;

    .feedback_bg_inner {
      width: 50rem;
      height: 40rem;
    }

    .feedback_bg_wrapper {
      height: 100%;
    }
  }

  .popup__text {
    color: #fff;
    font-size: 1.8rem;
    margin-bottom: 5rem;
  }
}