@mixin blink($time: 2.5s) {
	@include prefix(animation, blink $time infinite ease);
}

@keyframes blink {
	0% {
		background-color: #009fe3;
		border-color: #009fe3;
	}

	50% {
		background-color: #007cad;
		border-color: #007cad;
	}

	100% {
		background-color: #009fe3;
		border-color: #009fe3;
	}
}