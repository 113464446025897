@import 'vars/fonts';
@import 'vars/animations';
@import 'vars/mixins';

@import 'snippets/css_pack';
@import 'snippets/layout';

@import 'pages/parking';
@import 'pages/plans';
@import 'pages/commercial';
@import 'pages/search';

@import 'pages/popup/mortgage_info';
