@mixin anim($time, $params...) {
	$easing: ease;
	$_params: ();
	@each $param_item in $params {
		$_params: append($_params, $param_item $time $easing, comma);
	}
	@include prefix(transition, $_params);
}

@mixin prefix($name, $params...) {
	@each $prefix in '-webkit-', '-moz-', '-ms-', '-o-', '' {
		$_params: ();
		@each $param_item in $params {
			@if (str-index(inspect($param_item), 'transform') == 1) {
				$_params: append($_params, unquote($prefix) + $param_item, comma);
			} @else {
				$_params: append($_params, $param_item, comma);
			}
		}
		#{$prefix+$name}: $_params;
	}
}